import {Component, Input, OnInit} from '@angular/core';
import {RunnersApiService} from '../../../../core/services/runners-api.service';
import {MatDialog} from '@angular/material/dialog';
import {Race, RaceType} from '../../../../core/model/resources/race';
import {MatTableDataSource} from '@angular/material/table';
import moment from 'moment';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';
import {UtilService} from 'src/app/core/services/util.service';
import {AuthService} from '../../../../core/services/auth.service';
import {RaceResult} from '../../../../core/model/resources/race-result';
import {EditRaceResultDialogComponent} from './edit-race-result-dialog/edit-race-result-dialog.component';

export interface EntryRace {
  race: Race;
  entry: {
    userRaceId: number;
    isCheater: boolean;
    raceResults?: RaceResult[];
  };
}

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  @Input() userId?: number;
  @Input() dataSource?: MatTableDataSource<EntryRace>;

  raceDisplayedColumns = ['id', 'race_id', 'title', 'ranking_term', 'open_date', 'is_cheater', 'operation'];
  UtilService = UtilService;

  constructor(
    private runnersApiService: RunnersApiService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (this.userId === undefined) {
      throw new Error('userId is required');
    }
    if (this.dataSource === undefined) {
      throw new Error('dataSource is required');
    }
  }

  async searchUserRaces(): Promise<void> {
    const res = await this.runnersApiService.getUserRaces(this.userId!);
    if (res.success) {
      this.dataSource!.data = res.data;
    } else {
      this.dataSource!.data = [];
    }
  }

  getRaceId(race: Race): string {
    if (race.moshicom) {
      return String(race.moshicom_race_id);
    }
    if (race.race_type === RaceType.VirtualRace || race.is_user_generated) {
      return '';
    }
    return String(race.runnet_race_id);
  }

  getBadgeClass(race: Race): string {
    if (race.race_type === RaceType.VirtualRace) {
      return 'virtual_label_badge';
    }
    return 'label_badge';
  }

  getLabel(race: Race): string | null {
    if (race.race_type === RaceType.VirtualRace) {
      return 'アプリ内イベント';
    } else if (race.is_user_generated) {
      return 'ユーザー手入力';
    } else if (race.is_representation) {
      return '代表';
    } else if (race.moshicom) {
      return 'モシコム';
    } else {
      return null;
    }
  }

  getOpenDateString(race: Race): string {
    if (!race) {
      return '';
    }
    if (race.beginning_open_date === race.end_of_open_date) {
      return moment(race.beginning_open_date).format('yyyy/M/D');
    }
    return `${moment(race.beginning_open_date).format('yyyy/M/D')} 〜 ${moment(race.end_of_open_date).format('yyyy/M/D')}`;
  }

  async showCheatSettingDialog(entryRace: EntryRace): Promise<void> {
    if (!this.authService.getUserAuth().isAllowManageRunner()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return;
    }

    if (!entryRace.entry.isCheater) {
      const isOk = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'チーターとしてランキングより除外します。\n' +
        '既に集計が完了している場合は、必要に応じて即時集計を実行してください。\n' +
        'よろしいですか？');
      if (!isOk) {
        return;
      }
      const res = await this.runnersApiService.patchUserRaces(entryRace.entry.userRaceId, true);
      if (res.success) {
        entryRace.entry.isCheater = true;
      }
    } else {
      const isOk = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'チーター設定を解除します。\n' +
        '既に集計が完了している場合は、必要に応じて即時集計を実行してください。\n' +
        'よろしいですか？');
      if (!isOk) {
        return;
      }
      const res = await this.runnersApiService.patchUserRaces(entryRace.entry.userRaceId, false);
      if (res.success) {
        entryRace.entry.isCheater = false;
      }
    }
  }

  async showEditRaceResultDialog(entryRace: EntryRace): Promise<void> {
    const edited = await EditRaceResultDialogComponent.createDialog(this.dialog, entryRace);
    if (edited) {
      await this.searchUserRaces();
    }
  }

}
