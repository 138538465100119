<mat-dialog-content class="mat-typography">
  <mat-tab-group>
    <mat-tab label="コース詳細">
      <div *ngIf="report" class="detail-container">
        <div class="header">チーム名</div>
        <div class="content">{{report.team.name}} (ID: {{report.team.id}})</div>

        <div class="header">コース名</div>
        <div class="content">{{report.course.name}} (UUID: {{report.course.uuid}})</div>

        <div class="header">コース詳細</div>
        <div class="content">{{report.course.detail}}</div>

        <div class="header">距離</div>
        <div class="content">{{report.course.distance}}km</div>

        <ng-container *ngIf="report.course.climbUp !== undefined">
          <div class="header">標高（up）</div>
          <div class="content">{{report.course.climbUp}}m</div>
        </ng-container>
        <ng-container *ngIf="report.course.climbDown !== undefined">
          <div class="header">標高（down）</div>
          <div class="content">{{report.course.climbDown}}m</div>
        </ng-container>
      </div>
    </mat-tab>

    <mat-tab label="地図">
      <div *ngIf="!!coordinates">
        <mat-checkbox class="checkbox" [(ngModel)]="isSatellite" (ngModelChange)="toggleStyle()">衛星</mat-checkbox>
      </div>
      <div *ngIf="!!coordinates" [id]="mapId"></div>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="isUnanswered()" class="operation-container">
    <button class="close-button" [mat-dialog-close]="undefined">閉じる</button>
    <button class="ignore-button" [mat-dialog-close]="ViolationReportStatus.ignored">無視する</button>
    <button class="approve-button" [mat-dialog-close]="ViolationReportStatus.approved">違反認定する</button>
  </div>

  <div *ngIf="!isUnanswered()" class="close-operation-container">
    <button class="close-button" [mat-dialog-close]="undefined">閉じる</button>
  </div>

</mat-dialog-content>


