import {Injectable} from '@angular/core';
import {ApiHelperService, ApiResult} from './api-helper.service';
import {API} from 'aws-amplify';
import {OverlayService} from './overlay.service';
import {MessageDialogComponent} from '../../views/partials/message-dialog/message-dialog.component';
import {AuthService} from './auth.service';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ActivityApiService {

  constructor(
    private apiHelperService: ApiHelperService,
    private authService: AuthService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
  ) {
  }

  async getActivities(input: GetActivitiesInput): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowViewRunner()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      queryStringParameters: input
    };

    try {
      const result = await this.overlayService.call(API.get(this.apiHelperService.apiName, `activities`, init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }
}

export interface GetActivitiesInput {
  user_id: number;
  from: number;
  to: number;
}

