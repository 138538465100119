<div class="container">
  <mat-tab-group (selectedIndexChange)="selectedTabChanged($event)" [selectedIndex]="selectedIndex">
    <mat-tab label="チーム管理">
      <app-team-manage *ngIf="selectedIndex === TeamPage.teamManage"></app-team-manage>
    </mat-tab>
    <mat-tab label="オフィシャルチーム管理">
      <app-official-team-manage *ngIf="selectedIndex === TeamPage.officialTeamManage"></app-official-team-manage>
    </mat-tab>
    <mat-tab label="チーム違反報告">
      <app-team-report *ngIf="selectedIndex === TeamPage.teamReport"></app-team-report>
    </mat-tab>
    <mat-tab label="掲示板違反報告">
      <app-feed-report *ngIf="selectedIndex === TeamPage.feedReport"></app-feed-report>
    </mat-tab>
    <mat-tab label="ホームコース違反報告">
      <app-course-report *ngIf="selectedIndex === TeamPage.courseReport"></app-course-report>
    </mat-tab>
  </mat-tab-group>
</div>
