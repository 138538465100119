<div class="common-dialog">
  <div fxLayout="column" fxLayoutGap="16px">
    <div class="title">ライセンス追加</div>

    <mat-form-field>
      <mat-label>定員数</mat-label>
      <input matInput type="number" min="100" max="999999" [formControl]="capacity">
      <mat-error *ngIf="capacity.hasError('required')">
        定員数を入力してください。
      </mat-error>
      <mat-error *ngIf="capacity.hasError('min')">
        {{ minCapacity }}以上の数値を入れてください。
      </mat-error>
      <mat-error *ngIf="capacity.hasError('max')">
        999999以下の数値を入れてください。
      </mat-error>
    </mat-form-field>

    <section>
      <label class="subheader">利用可能なチーム内イベント種別</label>
      <div *ngIf="additionalEvents" class="checkbox-container">
        <mat-checkbox *ngFor="let additionalEvent of additionalEvents" [(ngModel)]="additionalEvent.completed"
                      [disabled]="additionalEvent.immutable">
          {{ additionalEvent.name }}
        </mat-checkbox>
      </div>
    </section>

    <mat-form-field appearance="fill" class="contract-term">
      <mat-label>契約期間</mat-label>
      <mat-date-range-input [formGroup]="contractRange" [rangePicker]="contractRangePicker">
        <input matStartDate formControlName="startedAt" placeholder="Start date">
        <input matEndDate formControlName="expiredAt" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="contractRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #contractRangePicker></mat-date-range-picker>
      <mat-error *ngIf="contractRange.controls.startedAt.hasError('matStartDateInvalid')">Invalid start date
      </mat-error>
      <mat-error *ngIf="contractRange.controls.expiredAt.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>契約金額（税別）</mat-label>
      <input matInput type="number" min="0" max="999999999" [formControl]="price">
      <mat-error *ngIf="price.hasError('required')">
        契約金額を入力してください。
      </mat-error>
      <mat-error *ngIf="price.hasError('min')">
        100以上の数値を入れてください。
      </mat-error>
      <mat-error *ngIf="price.hasError('max')">
        999999999以下の数値を入れてください。
      </mat-error>
    </mat-form-field>

    <div class="operation-container">
      <div class="operation-button-container">
        <button class="left-button" [mat-dialog-close]="null">キャンセル</button>
        <button class="right-button-positive" (click)="addLicense()">追加</button>
      </div>
    </div>

  </div>

</div>
