<div class="header-action-container">
  <div class="search-container">
    <mat-form-field class="search-item">
      <mat-label>Team Idで検索</mat-label>
      <input matInput type="number" [(ngModel)]="teamId" (change)="searchByTeamId()">
    </mat-form-field>
  </div>
</div>

<div *ngIf="!!team" class="team-container">
  <div class="header">チーム名</div>
  <div class="content">{{ team.name }} (ID: {{ team.id }})</div>

  <div class="header">チーム紹介文</div>
  <div class="content">{{ team.description }}</div>

  <div class="header">種目</div>
  <div class="content">{{ getActivityTypeName(team.activityType) }}</div>

  <div class="header">タイプ</div>
  <div class="content">{{ getTeamTypeName(team.type) }}</div>

  <div class="header">種別</div>
  <div class="content">{{ getTeamCategoryName(team.category) }}</div>

  <div class="header">ステータス</div>
  <div class="content">{{ getTeamStatusName(team.status) }}</div>

  <div class="header">メンバー数<span *ngIf="team.status === TeamStatus.disbanded">(解散時点のメンバー数)</span></div>
  <div class="content">{{ team.membership }} / {{ team.capacity }}</div>

  <ng-container *ngIf="team.area">
    <div class="header">活動エリア</div>
    <div class="content">{{ team.area }}</div>
  </ng-container>

  <ng-container *ngIf="team.since !== undefined">
    <div class="header">発足年</div>
    <div class="content">{{ team.since }}</div>
  </ng-container>

  <ng-container *ngIf="team.webLink">
    <div class="header">web</div>
    <div class="content">{{ team.webLink }}</div>
  </ng-container>
  <ng-container *ngIf="team.twitterLink">
    <div class="header">twitter</div>
    <div class="content">{{ team.twitterLink }}</div>
  </ng-container>
  <ng-container *ngIf="team.facebookLink">
    <div class="header">facebook</div>
    <div class="content">{{ team.facebookLink }}</div>
  </ng-container>
  <ng-container *ngIf="team.instagramLink">
    <div class="header">instagram</div>
    <div class="content">{{ team.instagramLink }}</div>
  </ng-container>
  <ng-container *ngIf="team.youtubeLink">
    <div class="header">youtube</div>
    <div class="content">{{ team.youtubeLink }}</div>
  </ng-container>

  <div class="header">イメージ画像</div>
  <a href="{{team.imageUrl}}" target="_blank">
    <img class="photo" alt="イメージ画像" src="{{team.imageUrl}}"/>
  </a>

  <div class="header">アイコン画像</div>
  <a href="{{team.iconUrl}}" target="_blank">
    <img class="photo" alt="アイコン画像" src="{{team.iconUrl}}"/>
  </a>

  <div class="operation-container">
    <button mat-raised-button color="primary"
            [disabled]="team.status !== TeamStatus.suspended && team.status !== TeamStatus.draft" (click)="activate()">
      アクティブ化する
    </button>
    <button mat-raised-button color="accent" [disabled]="team.status !== TeamStatus.active" (click)="suspend()">
      休止する
    </button>
    <button mat-raised-button color="warn" [disabled]="team.status === TeamStatus.disbanded" (click)="disband()">
      解散する
    </button>
    <button mat-raised-button color="warn" [disabled]="team.status !== TeamStatus.disbanded" (click)="delete()">
      完全に削除する
    </button>
  </div>

</div>

