<h2 mat-dialog-title>アクティビティ詳細</h2>

<mat-dialog-content class="mat-typography">
  <mat-tab-group>
    <mat-tab label="ラップ">

      <div *ngIf="!!this.activity?.lap_info_net" class="table-container">
        <h3>ネットタイム</h3>
        <table mat-table [dataSource]="netDataSource" class="mat-elevation-z1">
          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef>区間</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.section, 2)}} {{lap.unit}}</td>
          </ng-container>
          <ng-container matColumnDef="lapTime">
            <th mat-header-cell *matHeaderCellDef>ラップタイム</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.getHMS(lap.lapTime)}} </td>
          </ng-container>
          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>ラップ開始日時</th>
            <td mat-cell *matCellDef="let lap">{{lap.timestamp * 1000 | date:'yyyy/M/d HH:mm:ss'}} </td>
          </ng-container>
          <ng-container matColumnDef="ascent">
            <th mat-header-cell *matHeaderCellDef>D+(m)</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.ascent, 2)}} </td>
          </ng-container>
          <ng-container matColumnDef="descent">
            <th mat-header-cell *matHeaderCellDef>D-(m)</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.descent, 2)}} </td>
          </ng-container>
          <ng-container matColumnDef="steps">
            <th mat-header-cell *matHeaderCellDef>歩数</th>
            <td mat-cell *matCellDef="let lap">{{lap.steps}} </td>
          </ng-container>
          <ng-container matColumnDef="averageHeartRate">
            <th mat-header-cell *matHeaderCellDef>平均心拍数</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.averageHeartRate, 0)}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let race; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div *ngIf="!this.activity?.lap_info_net" class="table-container">ネットタイムでのラップ情報はありません。</div>

      <div *ngIf="!!this.activity?.lap_info_gross" class="table-container">
        <h3>グロスタイム</h3>
        <table mat-table [dataSource]="grossDataSource" matSort class="mat-elevation-z1">
          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef>区間</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.section, 2)}} {{lap.unit}}</td>
          </ng-container>
          <ng-container matColumnDef="lapTime">
            <th mat-header-cell *matHeaderCellDef>ラップタイム</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.getHMS(lap.lapTime)}} </td>
          </ng-container>
          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>ラップ開始日時</th>
            <td mat-cell *matCellDef="let lap">{{lap.timestamp * 1000 | date:'yyyy/M/d HH:mm:ss'}} </td>
          </ng-container>
          <ng-container matColumnDef="ascent">
            <th mat-header-cell *matHeaderCellDef>D+(m)</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.ascent, 2)}} </td>
          </ng-container>
          <ng-container matColumnDef="descent">
            <th mat-header-cell *matHeaderCellDef>D-(m)</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.descent, 2)}} </td>
          </ng-container>
          <ng-container matColumnDef="steps">
            <th mat-header-cell *matHeaderCellDef>歩数</th>
            <td mat-cell *matCellDef="let lap">{{lap.steps}} </td>
          </ng-container>
          <ng-container matColumnDef="averageHeartRate">
            <th mat-header-cell *matHeaderCellDef>平均心拍数</th>
            <td mat-cell *matCellDef="let lap">{{UtilService.round(lap.averageHeartRate, 0)}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let race; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div *ngIf="!this.activity?.lap_info_gross" class="table-container">グロスタイムでのラップ情報はありません。</div>

    </mat-tab>
    <mat-tab label="データ">
      <div *ngIf="activity" class="data-container">
        <dl *ngIf="authService.getUserAuth().isAllowViewActivityDetail()">
          <dt>id</dt>
          <dd>{{activity.id}}</dd>
        </dl>
        <dl *ngIf="authService.getUserAuth().isAllowViewActivityDetail()">
          <dt>uuid</dt>
          <dd>{{activity.uuid}}</dd>
        </dl>
        <dl>
          <dt>アクティビティ種別</dt>
          <dd>{{getActivityTypeName(activity.activity_type)}}</dd>
        </dl>
        <dl>
          <dt>ネットタイム</dt>
          <dd>{{UtilService.getHMS(activity.time)}}</dd>
        </dl>
        <dl>
          <dt>グロスタイム</dt>
          <dd>{{UtilService.getHMS(activity.gross_time)}}</dd>
        </dl>
        <dl>
          <dt>距離（km）</dt>
          <dd>{{UtilService.round(activity.distance, 2)}}</dd>
        </dl>
        <dl>
          <dt>平均ペース(hms/km)</dt>
          <dd>{{UtilService.getHMS(activity.pace)}}</dd>
        </dl>
        <dl>
          <dt>D+(m)</dt>
          <dd>{{activity.climb_up}}</dd>
        </dl>
        <dl>
          <dt>D-(m)</dt>
          <dd>{{activity.climb_down}}</dd>
        </dl>
        <dl>
          <dt>最高標高(m)</dt>
          <dd>{{activity.highest_alt !== undefined ? UtilService.round(activity.highest_alt, 0) : '-'}}</dd>
        </dl>
        <dl>
          <dt>最低標高(m)</dt>
          <dd>{{activity.lowest_alt !== undefined ? UtilService.round(activity.lowest_alt, 0) : '-'}}</dd>
        </dl>
        <dl>
          <dt>消費カロリー(kcal)</dt>
          <dd>{{activity.calorie}}</dd>
        </dl>
        <dl>
          <dt>平均心拍数(bpm)</dt>
          <dd>{{activity.heart_rate !== undefined ? UtilService.round(activity.heart_rate, 0) : '-'}}</dd>
        </dl>
        <dl>
          <dt>歩数</dt>
          <dd>{{activity.steps}}</dd>
        </dl>
        <dl>
          <dt>計測方法</dt>
          <dd>{{getActivitySourceName(activity.activity_source)}}</dd>
        </dl>
        <dl>
          <dt>手入力フラグ</dt>
          <dd>{{UtilService.getCheckString(activity.registered_manual)}}</dd>
        </dl>
        <dl>
          <dt>開始日時</dt>
          <dd>{{activity.beginning_at | date:'yyyy/M/d HH:mm:ss'}}</dd>
        </dl>
        <dl>
          <dt>終了日時</dt>
          <dd>{{activity.end_of_at | date:'yyyy/M/d HH:mm:ss'}}</dd>
        </dl>
        <dl>
          <dt>登録日時</dt>
          <dd>{{activity.created_at | date:'yyyy/M/d HH:mm:ss'}}</dd>
        </dl>
        <dl>
          <dt>編集日時</dt>
          <dd>{{activity.updated_at | date:'yyyy/M/d HH:mm:ss'}}</dd>
        </dl>
        <dl>
          <dt>削除日時</dt>
          <dd>{{activity.deleted_at | date:'yyyy/M/d HH:mm:ss'}}</dd>
        </dl>
        <dl>
          <dt>チーター</dt>
          <dd>{{UtilService.getCheckString(activity.is_cheater)}}</dd>
        </dl>
        <dl>
          <dt>不正種別</dt>
          <dd>{{getCheatTypeName(activity.cheat_type)}}</dd>
        </dl>
        <ng-container *ngIf="!!activity.motion_rate">
          <dl>
            <dt>モーション(running)</dt>
            <dd>{{getMotionRateStr(activity.motion_rate.running)}}</dd>
          </dl>
          <dl>
            <dt>モーション(walking)</dt>
            <dd>{{getMotionRateStr(activity.motion_rate.walking)}}</dd>
          </dl>
          <dl>
            <dt>モーション(cycling)</dt>
            <dd>{{getMotionRateStr(activity.motion_rate.cycling)}}</dd>
          </dl>
          <dl>
            <dt>モーション(automotive)</dt>
            <dd>{{getMotionRateStr(activity.motion_rate.automotive)}}</dd>
          </dl>
          <dl>
            <dt>モーション(unknown)</dt>
            <dd>{{getMotionRateStr(activity.motion_rate.unknown)}}</dd>
          </dl>
        </ng-container>
        <ng-container *ngIf="!activity.motion_rate">
          <dl>
            <dt>モーション</dt>
            <dd></dd>
          </dl>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab label="地図">
      <div *ngIf="!!coordinates">
        <mat-checkbox class="checkbox" [(ngModel)]="isSatellite" (ngModelChange)="toggleStyle()">衛星</mat-checkbox>
      </div>
      <div *ngIf="!!coordinates" [id]="mapId"></div>
      <div *ngIf="!coordinates" class="table-container">
        緯度経度情報はありません。
      </div>
      <div *ngIf="!!activity?.motion_rate && !!coordinates && !!activityTypeCoordinates">
        <ul class="activity-color-list">
          <li class="running-color">running</li>
          <li class="walking-color">walking</li>
          <li class="cycling-color">cycling</li>
          <li class="automotive-color">automotive</li>
          <li class="unknown-color">unknown</li>
        </ul>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

