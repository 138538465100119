<div class="container">
  <div class="header-action-container">
    <button mat-raised-button color="primary" (click)="callAddDialog()">新規登録</button>
    <button mat-raised-button (click)="loadUsers()">更新</button>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>e-mail</th>
        <td mat-cell *matCellDef="let user">{{user.email}}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>氏名</th>
        <td mat-cell *matCellDef="let user">{{user.name}}</td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>権限</th>
        <td mat-cell *matCellDef="let user">{{getViewRole(user.role)}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="selectable" *matRowDef="let user; columns: displayedColumns;" (click)="callEditDialog(user)">
      </tr>
    </table>
  </div>
</div>
