import {AfterViewChecked, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {
  Activity,
  getActivitySourceName,
  getActivityTypeName,
  getCheatTypeName,
  LapInfo
} from '../../../../../core/model/resources/activity';
import {UtilService} from 'src/app/core/services/util.service';
import {S3Service} from '../../../../../core/services/s3.service';
import {environment} from '../../../../../../environments/environment';

import {FullscreenControl, LngLatBounds, LngLatLike, Map} from 'mapbox-gl';
import {Position} from 'geojson';
import {AuthService} from '../../../../../core/services/auth.service';

@Component({
  selector: 'app-activity-detail-dialog',
  templateUrl: './activity-detail-dialog.component.html',
  styleUrls: ['./activity-detail-dialog.component.scss']
})
export class ActivityDetailDialogComponent implements OnInit, AfterViewChecked, OnDestroy {
  UtilService = UtilService;
  getCheatTypeName = getCheatTypeName;
  getActivityTypeName = getActivityTypeName;
  getActivitySourceName = getActivitySourceName;
  activity?: Activity;
  netDataSource: MatTableDataSource<LapInfo> = new MatTableDataSource();
  grossDataSource: MatTableDataSource<LapInfo> = new MatTableDataSource();

  mapId = 'map';
  map: Map | null = null;
  coordinates?: [number, number][];  // [lon, lat][]
  activityTypeCoordinates?: { activityType: string, coordinates: LngLatLike[] }[];
  private isMapInitialized = false;
  isSatellite = false;

  displayedColumns = ['section', 'lapTime', 'timestamp', 'ascent', 'descent', 'steps', 'averageHeartRate'];

  static open(dialog: MatDialog, activity: Activity): void {
    dialog.open(ActivityDetailDialogComponent, {
      width: '80vw',
      height: '80vh',
      data: {
        activity
      }
    });
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { activity: Activity },
    private s3Service: S3Service,
    public authService: AuthService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.activity = this.data.activity;
    if (!!this.data.activity.lap_info_net) {
      this.netDataSource.data = this.data.activity.lap_info_net.filter(lap => lap.unit === 'km');
    }
    if (!!this.data.activity.lap_info_gross) {
      this.grossDataSource.data = this.data.activity.lap_info_gross.filter(lap => lap.unit === 'km');
    }

    if (!this.activity.user_id || !this.activity.uuid) {
      return;
    }

    try {
      const v2Gpx = await this.s3Service.getV2Gpx(this.activity.user_id, this.activity.uuid);
      const trackingPoints = v2Gpx.trackingPoints.filter((trackingPoint: any) =>
        trackingPoint.location.longitude !== 0 && trackingPoint.location.latitude !== 0);
      this.coordinates = trackingPoints.map((trackingPoint: any) => [trackingPoint.location.longitude, trackingPoint.location.latitude]);
      if (trackingPoints.length !== 0 && !!trackingPoints[0].assumedActivityType) {
        this.activityTypeCoordinates = [{activityType: '', coordinates: []}];
        trackingPoints.forEach((trackingPoint: any) => {
          const val = this.activityTypeCoordinates![this.activityTypeCoordinates!.length - 1];
          if (val.activityType === '') {
            val.activityType = trackingPoint.assumedActivityType;
          }
          if (val.activityType === trackingPoint.assumedActivityType) {
            val.coordinates.push([trackingPoint.location.longitude, trackingPoint.location.latitude]);
          } else {
            this.activityTypeCoordinates!.push({
              activityType: trackingPoint.assumedActivityType,
              coordinates: [[trackingPoint.location.longitude, trackingPoint.location.latitude]]
            });
          }
        });
      }
    } catch (e: any) {
      const v1Gpx = await this.s3Service.getV1Gpx(this.activity.user_id, this.activity.uuid);
      this.coordinates = v1Gpx.map((point: any) => [Number(point.lon), Number(point.lat)])
        .filter((coordinate: [number, number]) => coordinate[0] !== 0 && coordinate[1] !== 0);
    }
  }

  ngAfterViewChecked(): void {
    if (!!this.coordinates && document.getElementById(this.mapId) && !this.isMapInitialized) {
      this.isMapInitialized = true;
      this.drawMap('mapbox://styles/rbs-netadmin/cj458yrji09xg2smykk0x8z3z');
    }
  }

  toggleStyle(): void {
    if (this.map !== null) {
      if (this.map.getStyle().name === 'Mapbox Satellite') {
        this.map.remove();
        this.drawMap('mapbox://styles/rbs-netadmin/cj458yrji09xg2smykk0x8z3z');
      } else {
        this.map.remove();
        this.drawMap('mapbox://styles/mapbox/satellite-v9');
      }
    }
  }

  private drawMap(style: string): void {
    this.map = new Map({
      accessToken: environment.mapboxAccessToken,
      container: this.mapId,
      style,
    });

    this.map.addControl(new FullscreenControl());
    this.map.on('load', () => {
      if (this.activityTypeCoordinates !== undefined) {
        UtilService.addLine(this.map!, 'route', this.coordinates as Position[], '#ccc');
        this.activityTypeCoordinates.forEach((val, index) => {
          UtilService.addLine(this.map!, `segment${index}`, val.coordinates as Position[], this.getColor(val.activityType));
        });
      } else {
        UtilService.addLine(this.map!, 'route', this.coordinates as Position[], '#333');
      }

      const bounds = this.coordinates!.reduce((newBounds, coord) => {
        return newBounds.extend(coord);
      }, new LngLatBounds(this.coordinates![0], this.coordinates![0]));
      this.map!.fitBounds(bounds, {
        padding: 20,
        maxDuration: 2
      });
    });
    UtilService.addDistanceMarkers(this.map, this.coordinates!);
  }

  private getColor(activityType: string): string {
    switch (activityType) {
      case 'running':
        return '#00f';
      case 'walking':
        return '#0f0';
      case 'cycling':
        return '#f00';
      case 'automotive':
        return '#ff0';
      default:
        return '#333';
    }
  }

  getMotionRateStr(rate: number): string {
    return `${rate * 100}%`;
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }

    this.map = null;
  }

}
