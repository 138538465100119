import {Component, Inject, OnInit, Optional} from '@angular/core';
import {TeamReport, ViolationReportStatus} from '../../../../../../core/model/resources/violation-report';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {getActivityTypeName} from '../../../../../../core/model/resources/activity';
import {
  getTeamCategoryName,
  getTeamStatusName,
  getTeamTypeName,
  TeamStatus
} from '../../../../../../core/model/resources/team';

@Component({
  selector: 'app-team-report-dialog',
  templateUrl: './team-report-dialog.component.html',
  styleUrls: ['./team-report-dialog.component.scss']
})
export class TeamReportDialogComponent implements OnInit {

  getActivityTypeName = getActivityTypeName;
  getTeamTypeName = getTeamTypeName;
  getTeamCategoryName = getTeamCategoryName;
  getTeamStatusName = getTeamStatusName;
  TeamStatus = TeamStatus;
  ViolationReportStatus = ViolationReportStatus;
  report?: TeamReport;

  static async createDialog(dialog: MatDialog, report: TeamReport): Promise<ViolationReportStatus | undefined> {
    return await new Promise((resolve, reject) => {
      dialog.open(TeamReportDialogComponent, {
        data: {
          report
        },
        width: '600px',
        maxWidth: '600px',
        maxHeight: '600px'
      }).afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { report: TeamReport },
  ) {
  }

  ngOnInit(): void {
    this.report = this.data.report;
  }

  isUnanswered(): boolean {
    return this.report!.reportStatus === ViolationReportStatus.unanswered;
  }
}
