<div class="container">
  <div>
    <mat-form-field class="search-item">
      <mat-label>TATTA IDで検索</mat-label>
      <input matInput type="number" [(ngModel)]="searchUserId" (keyup.enter)="searchByTattaId()">
    </mat-form-field>
    <mat-form-field class="search-item">
      <mat-label>RUNNET顧客番号で検索</mat-label>
      <input matInput type="number" [(ngModel)]="searchRunnetId" (keyup.enter)="searchByRunnetId()">
    </mat-form-field>
  </div>

  <mat-tab-group *ngIf="!!user">
    <mat-tab label="プロフィール">
      <mat-card appearance="outlined" class="container user-card">
        <mat-card-header>
          <img [src]="user.picture_small_url" mat-card-avatar class="header-image">
          <mat-card-title class="title">{{ user.nickname }}
            <button mat-raised-button *ngIf="authService.getUserAuth().isAllowSendPushForDebug()"
                    (click)="showNotificationDialog()">PUSH通知
            </button>
            <button mat-raised-button *ngIf="authService.getUserAuth().isAllowInvokeBatch()"
                    (click)="invokeReCalcStatistics()">統計再計算
            </button>
            <ng-container *ngIf="authService.getUserAuth().isAllowManageRunner()">
              <button mat-raised-button (click)="invokeRunnetSyncForEachUser()">RUNNET同期</button>
              <button mat-raised-button color="accent" (click)="invokeCheckCheatStrictly()">チートチェック起動</button>
              <button mat-raised-button color="warn" (click)="banAccount()">アカウント停止</button>
            </ng-container>
          </mat-card-title>
          <mat-card-subtitle>TATTA ID: {{ user.user_id }}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="user.is_runnet_user">RUNNET 顧客番号: {{ user.runnet_user_index }}
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="user.user_status === UserStatus.ForceVeryStrict">ステータス:
            ForceVeryStrict（不正判定を常時厳しくしています）
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="user.user_status === UserStatus.Ban">ステータス:
            アカウント停止
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="user-table">
          <section>
            <h4>プロフィール</h4>
            <dl>
              <dt>性別</dt>
              <dd>{{ getGenderStr(user.gender) }}<span>（{{ getPublicStrFromBool(user.show_gender) }}）</span></dd>
            </dl>
            <dl>
              <dt>年齢</dt>
              <dd>{{ calcAge(user.birthdate) }}<span>（{{ getPublicStrFromBool(user.show_birthdate) }}）</span></dd>
            </dl>
            <dl>
              <dt>都道府県</dt>
              <dd>{{ user.pref_name }}<span>（{{ getPublicStrFromBool(user.show_pref) }}）</span></dd>
            </dl>
            <dl>
              <dt>身長</dt>
              <dd>{{ user.height }}</dd>
            </dl>
            <dl>
              <dt>体重</dt>
              <dd>{{ user.weight }}</dd>
            </dl>
            <dl>
              <dt>自己紹介</dt>
              <dd>
                <pre>{{ user.profile }}</pre>
              </dd>
            </dl>
          </section>

          <section>
            <h4>公開設定</h4>
            <dl>
              <dt>アクティビティ</dt>
              <dd>{{ getPublicStrFromPrivacyStatus(user.activity_privacy_status) }}</dd>
            </dl>
            <dl>
              <dt>アクティビティの地図</dt>
              <dd>{{ getPublicStrFromPrivacyStatus(user.activity_map_privacy_status) }}</dd>
            </dl>
            <dl>
              <dt>大会・イベント</dt>
              <dd>{{ getPublicStrFromPrivacyStatus(user.entries_privacy_status) }}</dd>
            </dl>
            <dl>
              <dt>大会・イベントのランキング</dt>
              <dd>{{ getPublicStrFromBool(user.show_ranking) }}</dd>
            </dl>
          </section>

          <section>
            <h4>Garmin Connect</h4>
            <dl>
              <dt>連携状態</dt>
              <dd>{{ user.linked_to_garmin }}</dd>
            </dl>
            <dl>
              <dt>連携日時</dt>
              <dd>{{ user.garmin_last_authenticated_at | date:'yyyy/M/d HH:mm:ss' }}</dd>
            </dl>
          </section>

          <section>
            <h4>Polar Flow</h4>
            <dl>
              <dt>連携状態</dt>
              <dd>{{ user.linked_to_polar }}</dd>
            </dl>
            <dl>
              <dt>連携日時</dt>
              <dd>{{ user.polar_last_authenticated_at | date:'yyyy/M/d HH:mm:ss' }}</dd>
            </dl>
          </section>

          <section>
            <h4>NeoRun / Epson View</h4>
            <dl>
              <dt>連携状態</dt>
              <dd>{{ user.linked_to_neorun }}</dd>
            </dl>
            <dl>
              <dt>連携日時</dt>
              <dd>{{ user.neorun_last_authenticated_at | date:'yyyy/M/d HH:mm:ss' }}</dd>
            </dl>
          </section>

          <section>
            <h4>その他</h4>
            <dl>
              <dt>利用開始日時</dt>
              <dd>{{ user.oldest_data | date:'yyyy/M/d HH:mm:ss' }}</dd>
            </dl>
            <dl>
              <dt>最新トークン更新日時</dt>
              <dd>{{ user.newest_data | date:'yyyy/M/d HH:mm:ss' }}</dd>
            </dl>

            <div class="table-container">
              <h4>トークン一覧</h4>
              <div class="token-table-wrapper">
                <table mat-table [dataSource]="deviceDataSource" class="mat-elevation-z1 token-table">
                  <ng-container matColumnDef="uuid">
                    <th mat-header-cell *matHeaderCellDef>UUID</th>
                    <td mat-cell *matCellDef="let device">{{ device.device_uuid }}</td>
                  </ng-container>
                  <ng-container matColumnDef="deviceType">
                    <th mat-header-cell *matHeaderCellDef>デバイス種別</th>
                    <td mat-cell *matCellDef="let device">{{ getDeviceType(device.device_type) }}</td>
                  </ng-container>
                  <ng-container matColumnDef="updatedAt">
                    <th mat-header-cell *matHeaderCellDef>更新日時</th>
                    <td mat-cell *matCellDef="let device">{{ device.updated_at | date:'yyyy/M/d HH:mm:ss' }}</td>
                  </ng-container>
                  <ng-container matColumnDef="expiresAt">
                    <th mat-header-cell *matHeaderCellDef>トークン有効期限</th>
                    <td mat-cell
                        *matCellDef="let device">{{ device.security_token_expires_at | date:'yyyy/M/d HH:mm:ss' }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>作成日時</th>
                    <td mat-cell *matCellDef="let device">{{ device.created_at | date:'yyyy/M/d HH:mm:ss' }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let race; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>

          </section>
        </mat-card-content>
      </mat-card>
    </mat-tab>

    <mat-tab label="アクティビティ">
      <app-activity [userId]="user.user_id" [dataSource]="activityDataSource"></app-activity>
    </mat-tab>

    <mat-tab label="エントリー履歴">
      <app-entry [userId]="user.user_id" [dataSource]="raceDataSource"></app-entry>
    </mat-tab>
  </mat-tab-group>

</div>
