import {Component, OnInit, ViewChild} from '@angular/core';
import {QUERY_PARAM_TEAM_PAGE, TeamPage} from '../../team.component';
import {Router} from '@angular/router';
import {ContextService} from '../../../../../core/services/context.service';
import {
  getTeamAclName,
  getTeamMemberRoleName,
  getTeamTypeName,
  OfficialTeam,
  TeamAcl,
  TeamPaidAccount,
  TeamPaidPlan,
  TeamRelatedAccount,
  TeamStatus,
  TeamType
} from '../../../../../core/model/resources/team';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {getRaceRankingMethodName, RaceRankingMethod} from '../../../../../core/model/resources/race';
import {TeamApiService} from '../../../../../core/services/team-api.service';
import {MessageDialogComponent} from '../../../../partials/message-dialog/message-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AddLicenseDialogComponent} from './add-license-dialog/add-license-dialog.component';
import {MatTableDataSource} from '@angular/material/table';
import {RaceConfirmDialogComponent} from '../race-confirm-dialog/race-confirm-dialog.component';
import moment, {Moment} from 'moment';
import {RuntesRace} from '../../../../../core/model/resources/runtes';
import {AddRelatedAccountDialogComponent} from './add-related-account-dialog/add-related-account-dialog.component';
import {MatSort} from '@angular/material/sort';
import {
  getSelectableRequiredServices,
  LinkageService,
  SelectableRequiredServices
} from '../../../../../core/model/resources/tatta-user';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss']
})
export class EditLicenseComponent implements OnInit {

  protected readonly SelectableRequiredServices = SelectableRequiredServices;
  TeamType = TeamType;
  TeamAcl = TeamAcl;
  getRaceRankingMethodName = getRaceRankingMethodName;
  getTeamTypeName = getTeamTypeName;
  getTeamAclName = getTeamAclName;
  getTeamMemberRoleName = getTeamMemberRoleName;
  team?: OfficialTeam;

  isEditBasicInfo = false;
  isEditLicense = false;
  isExpired = false;

  paidAccountName: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.minLength(1), Validators.maxLength(254)],
    nonNullable: true,
  });
  paidAccountEmail: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.email, Validators.minLength(1), Validators.maxLength(254)],
    nonNullable: true,
  });
  teamType?: TeamType.official | TeamType.officialPrivate | TeamType.officialRaceCommunity;
  requiredServices: SelectableRequiredServices = SelectableRequiredServices.RUNNET;
  eventRequiredServices: { name: string, value: LinkageService, completed: boolean, immutable: boolean }[] = [];

  queryRuntesNum: string = '';
  runtesNums: string[] = [];
  queryRunnetRaceId: string = '';
  runnetRaceIds: number[] = [];
  queryMoshicomId: string = '';
  moshicomIds: number[] = [];

  @ViewChild(MatSort, {static: true}) relatedAccountSort?: MatSort;
  relatedAccountDataSource: MatTableDataSource<TeamRelatedAccount> = new MatTableDataSource();
  relatedAccountDisplayedColumns = ['name', 'email', 'role', 'delete'];

  capacity: FormControl<number | null> = new FormControl<number | null>(100, {
    validators: [Validators.required, Validators.min(100), Validators.max(999999)],
    nonNullable: false,
  });
  additionalEvents?: { name: string, value: RaceRankingMethod, completed: boolean, immutable: boolean }[];
  contractRange = new FormGroup<{
    startedAt: FormControl<Moment | null>,
    expiredAt: FormControl<Moment | null>
  }>({
    startedAt: new FormControl<Moment | null>(null),
    expiredAt: new FormControl<Moment | null>(null)
  });
  price: FormControl<number | null> = new FormControl<number | null>(0, {
    validators: [Validators.required, Validators.min(0), Validators.max(999999999)],
    nonNullable: false,
  });

  pastPlanDataSource: MatTableDataSource<TeamPaidPlan> = new MatTableDataSource();
  planDisplayedColumns = ['capacity', 'additionalEvents', 'startedAt', 'expiredAt', 'price'];

  constructor(
    private contextService: ContextService,
    private teamApiService: TeamApiService,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.team = this.contextService.selectedTeamForLicenceManagement;
    if (!this.team) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'チーム情報の取得に失敗しました。再度やり直してください。');
      return;
    }

    this.setBasicInfo();
    this.setLicense();
    this.setPastLicense();
    this.relatedAccountDataSource.sort = this.relatedAccountSort!;
    await this.setRelatedAccounts(this.team.id);
  }

  private setBasicInfo(): void {
    this.paidAccountName.setValue(this.team!.paidAccount.name);
    this.paidAccountEmail.setValue(this.team!.paidAccount.email);
    if (this.isEditBasicInfo) {
      this.paidAccountName.enable();
      this.paidAccountEmail.enable();
    } else {
      this.paidAccountName.disable();
      this.paidAccountEmail.disable();
    }
    this.teamType = this.team!.type as TeamType.official | TeamType.officialPrivate | TeamType.officialRaceCommunity;
    this.requiredServices = getSelectableRequiredServices(this.team!.requiredServices);
    this.eventRequiredServices = [
      {
        name: 'ソーシャルログイン',
        value: LinkageService.Social,
        completed: !!this.team!.eventRequiredServices.find(service => service === LinkageService.Social),
        immutable: !!this.team!.requiredServices.find(service => service === LinkageService.Social),
      },
      {
        name: 'RUNNET連携',
        value: LinkageService.RUNNET,
        completed: !!this.team!.eventRequiredServices.find(service => service === LinkageService.RUNNET),
        immutable: !!this.team!.requiredServices.find(service => service === LinkageService.RUNNET),
      },
      {
        name: 'OneASICS連携',
        value: LinkageService.OneASICS,
        completed: !!this.team!.eventRequiredServices.find(service => service === LinkageService.OneASICS),
        immutable: !!this.team!.requiredServices.find(service => service === LinkageService.OneASICS),
      },
    ];
    this.runtesNums = JSON.parse(JSON.stringify(this.team!.connectedRuntesNums));
    this.runnetRaceIds = JSON.parse(JSON.stringify(this.team!.connectedRunnetRaceIds));
    this.moshicomIds = JSON.parse(JSON.stringify(this.team!.connectedMoshicomIds));
  }

  private setLicense(): void {
    this.capacity.setValue(this.team!.paidPlan.additionalCapacity + 100);
    this.additionalEvents = [
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByDistance),
        value: RaceRankingMethod.RankingByDistance,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByTime),
        value: RaceRankingMethod.RankingByTime,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByArea),
        value: RaceRankingMethod.RankingByArea,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByDistanceChallenge),
        value: RaceRankingMethod.RankingByDistanceChallenge,
        completed: !!this.team!.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingByDistanceChallenge),
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByCalorieChallenge),
        value: RaceRankingMethod.RankingByCalorieChallenge,
        completed: !!this.team!.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingByCalorieChallenge),
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByGroupDistance),
        value: RaceRankingMethod.RankingByGroupDistance,
        completed: !!this.team!.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingByGroupDistance),
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingBySeriesEvent),
        value: RaceRankingMethod.RankingBySeriesEvent,
        completed: !!this.team!.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingBySeriesEvent),
        immutable: false
      },
    ];
    this.contractRange.controls.startedAt.setValue(moment(this.team!.paidPlan.startedAt));
    this.contractRange.controls.expiredAt.setValue(moment(this.team!.paidPlan.expiredAt));
    this.price.setValue(this.team!.paidPlan.price);
    this.isExpired = this.team!.paidPlan.expiredAt < new Date().getTime();

    if (this.isEditLicense) {
      this.capacity.enable();
      this.price.enable();
    } else {
      this.capacity.disable();
      this.price.disable();
    }
  }

  setPastLicense(): void {
    this.pastPlanDataSource.data = this.team!.paidPlanHistories;
  }

  private async setRelatedAccounts(teamId: number): Promise<void> {
    const relatedAccounts = await this.teamApiService.getRelatedAccounts(teamId);
    if (relatedAccounts) {
      this.relatedAccountDataSource.data = relatedAccounts;
    }
  }

  startEditBasicInfo(): void {
    this.isEditBasicInfo = true;
    this.setBasicInfo();
  }

  stopEditBasicInfo(): void {
    this.isEditBasicInfo = false;
    this.setBasicInfo();
  }

  startEditLicense(): void {
    this.isEditLicense = true;
    this.setLicense();
  }

  stopEditLicense(): void {
    this.isEditLicense = false;
    this.setLicense();
  }

  async changeTeamType(): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'チーム種別を変更します。基本的にこの作業は行うべきではありません。\nどのような影響が発生するか、十分理解した上で行なってください。\n\n本当によろしいですか？');
    if (!isOK) {
      // 元に戻す
      this.teamType = this.team!.type as TeamType.official | TeamType.officialPrivate | TeamType.officialRaceCommunity;
      return;
    }
  }

  async searchByRuntesNum(): Promise<void> {
    const races: RuntesRace[] | null = await this.teamApiService.getRuntesRaces(this.queryRuntesNum);
    if (races === null || races.length === 0) {
      MessageDialogComponent.createErrorDialog(this.dialog, '指定したランテス番号に属する大会は見つかりませんでした。');
      return;
    }
    const isOK = await RaceConfirmDialogComponent.createRaceConfirmDialog(this.dialog, races.sort((a, b) => b.startDate - a.startDate));
    if (isOK) {
      if (this.runtesNums.find(runtesNum => runtesNum === this.queryRuntesNum)) {
        MessageDialogComponent.createErrorDialog(this.dialog, '既に追加済みです。');
        return;
      }
      this.runtesNums.push(this.queryRuntesNum);
      this.queryRuntesNum = '';
    }
  }

  async removeRuntesNum(runtesNum: string): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '削除します。基本的にこの作業は行うべきではありません。\n本当によろしいですか？');
    if (!isOK) {
      return;
    }
    this.runtesNums = this.runtesNums.filter(val => val !== runtesNum);
  }

  async searchByRunnetRaceId(): Promise<void> {
    const runnetRaceId = Number(this.queryRunnetRaceId);
    if (isNaN(runnetRaceId)) {
      MessageDialogComponent.createErrorDialog(this.dialog, '半角数字を入力してください。');
      this.queryRunnetRaceId = '';
      return;
    }
    const race = await this.teamApiService.getRuntesRace(runnetRaceId);
    if (!race) {
      MessageDialogComponent.createErrorDialog(this.dialog, '指定したIDの大会は見つかりませんでした。');
      return;
    }
    const isOK = await RaceConfirmDialogComponent.createRaceConfirmDialog(this.dialog, [race]);
    if (isOK) {
      if (this.runnetRaceIds.find(addedId => addedId === runnetRaceId)) {
        MessageDialogComponent.createErrorDialog(this.dialog, '既に追加済みです。');
        return;
      }
      this.runnetRaceIds.push(runnetRaceId);
      this.queryRunnetRaceId = '';
    }
  }

  async removeRunnetRaceId(runnetRaceId: number): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '削除します。基本的にこの作業は行うべきではありません。本当によろしいですか？');
    if (!isOK) {
      return;
    }
    this.runnetRaceIds = this.runnetRaceIds.filter(val => val !== runnetRaceId);
  }

  async searchByMoshicomId(): Promise<void> {
    const moshicomId = Number(this.queryMoshicomId);
    if (isNaN(moshicomId)) {
      MessageDialogComponent.createErrorDialog(this.dialog, '半角数字を入力してください。');
      this.queryMoshicomId = '';
      return;
    }
    const event = await this.teamApiService.getMoshicomEvent(moshicomId);
    if (!event) {
      MessageDialogComponent.createErrorDialog(this.dialog, '指定したIDのイベントは見つかりませんでした。');
      return;
    }
    const isOK = await RaceConfirmDialogComponent.createRaceConfirmDialog(this.dialog, undefined, event);
    if (isOK) {
      if (this.moshicomIds.find(addedMoshicomId => addedMoshicomId === moshicomId)) {
        MessageDialogComponent.createErrorDialog(this.dialog, '既に追加済みです。');
        return;
      }
      this.moshicomIds.push(moshicomId);
      this.queryMoshicomId = '';
    }
  }

  async removeMoshicomId(moshicomId: number): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '削除します。基本的にこの作業は行うべきではありません。本当によろしいですか？');
    if (!isOK) {
      return;
    }
    this.moshicomIds = this.moshicomIds.filter(val => val !== moshicomId);
  }

  async saveAccount(): Promise<void> {
    if (!this.validateAccount()) {
      return;
    }
    const isChangeEmail = this.team!.paidAccount.email !== this.paidAccountEmail.value;
    const isChangeTeamType = this.team!.type !== this.teamType;
    const isChangeConnectedEntries = JSON.stringify(this.team!.connectedRuntesNums) !== JSON.stringify(this.runtesNums)
      || JSON.stringify(this.team!.connectedRunnetRaceIds) !== JSON.stringify(this.runnetRaceIds)
      || JSON.stringify(this.team!.connectedMoshicomIds) !== JSON.stringify(this.moshicomIds);
    let msg = 'アカウント情報を変更します。\n';
    if (isChangeEmail) {
      msg = `${msg}\n契約者メールアドレスを変更する場合、他チームに利用されていないメールアドレスの場合はアカウント新規発行と同じ扱いとなり、宛先に新しいパスワードが届きます。\n入力ミスがないか十分に確認してください。\n`;
    }
    if (isChangeTeamType) {
      msg = `${msg}\nチームタイプの設定を変更します。大きな影響を及ぼす設定です。入力ミスがないか十分に確認してください。\n`;
    }
    if (isChangeConnectedEntries) {
      msg = `${msg}\nエントリー自動連携の設定を変更します。大きな影響を及ぼす設定です。入力ミスがないか十分に確認してください。\n`;
    }
    msg = `${msg}\nよろしいですか？`;
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, msg);
    if (!isOK) {
      return;
    }

    const paidAccount: TeamPaidAccount = {
      name: this.paidAccountName.value,
      email: this.paidAccountEmail.value
    };
    await this.teamApiService.patchBasicInfo(this.team!.id, {
      paidAccount,
      teamType: this.teamType!,
      eventRequiredServices: this.eventRequiredServices.filter(service => service.completed)
        .map(service => service.value),
      connectedRuntesNums: this.runtesNums,
      connectedRunnetRaceIds: this.runnetRaceIds,
      connectedMoshicomIds: this.moshicomIds,
    });
    this.isEditBasicInfo = false;
    this.team!.paidAccount = paidAccount;
    this.team!.type = this.teamType!;
    this.team!.connectedRuntesNums = JSON.parse(JSON.stringify(this.runtesNums));
    this.team!.connectedRunnetRaceIds = JSON.parse(JSON.stringify(this.runnetRaceIds));
    this.team!.connectedMoshicomIds = JSON.parse(JSON.stringify(this.moshicomIds));
  }

  async addRelatedAccount(): Promise<void> {
    const teamId = this.team!.id;
    await this.dialog.open(AddRelatedAccountDialogComponent, {
      width: '400px',
      data: {
        teamId,
        relatedAccounts: this.relatedAccountDataSource.data,
        paidAccountEmail: this.team!.paidAccount.email,
        teamType: this.team!.type
      }
    }).afterClosed().subscribe(async result => {
      if (result) {
        await this.setRelatedAccounts(teamId);
      }
    });
  }

  async deleteRelatedAccount(account: TeamRelatedAccount): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, `指定したアカウントより、このチームへのアクセス権限を削除します。\n他チームへのアクセス権限が無い場合、アカウント自体が削除されます。\n\n本当によろしいですか？`);
    if (!isOK) {
      return;
    }
    const teamId = this.team!.id;
    const res = await this.teamApiService.deleteRelatedAccount(teamId, account.email);
    if (res) {
      await this.setRelatedAccounts(teamId);
    }
  }

  async saveLicense(): Promise<void> {
    if (!this.validateLicense()) {
      return;
    }
    const paidPlan: TeamPaidPlan = {
      additionalCapacity: this.capacity.value! - 100,
      additionalEvents: this.additionalEvents!.filter(additionalEvent => !additionalEvent.immutable && additionalEvent.completed)
        .map(additionalEvent => additionalEvent.value),
      startedAt: this.contractRange.value.startedAt!.unix() * 1000,
      expiredAt: this.contractRange.value.expiredAt!.unix() * 1000 + 1000 * 60 * 60 * 24 - 1,
      price: this.price.value!,
    };
    await this.teamApiService.patchLicense(this.team!.id, {paidPlan});
    this.isEditLicense = false;
    this.team!.paidPlan = paidPlan;
    this.setLicense();
  }

  async addLicense(): Promise<void> {
    if (!this.isExpired) {
      MessageDialogComponent.createErrorDialog(this.dialog, '有効なライセンスが存在しているため追加できません。');
      return;
    }
    const paidPlan = await AddLicenseDialogComponent.open(this.dialog, this.team!);
    if (paidPlan !== null) {
      this.team!.paidPlanHistories.push(JSON.parse(JSON.stringify(this.team!.paidPlan)));
      this.team!.paidPlan = paidPlan;
      this.setLicense();
      this.setPastLicense();
    }
  }

  validateAccount(): boolean {
    if (this.paidAccountName.value === this.team!.paidAccount.name &&
      this.paidAccountEmail.value === this.team!.paidAccount.email &&
      this.team!.type === this.teamType &&
      JSON.stringify(this.team!.eventRequiredServices) === JSON.stringify(this.eventRequiredServices.filter(service => service.completed)
        .map(service => service.value)) &&
      JSON.stringify(this.team!.connectedRuntesNums) === JSON.stringify(this.runtesNums) &&
      JSON.stringify(this.team!.connectedRunnetRaceIds) === JSON.stringify(this.runnetRaceIds) &&
      JSON.stringify(this.team!.connectedMoshicomIds) === JSON.stringify(this.moshicomIds)
    ) {
      MessageDialogComponent.createErrorDialog(this.dialog, '変更がありません。');
      return false;
    }
    if (this.paidAccountName.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約者名を入力してください。');
      return false;
    }
    if (this.paidAccountEmail.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約者メールアドレスを入力してください。');
      return false;
    }
    if (this.paidAccountEmail.hasError('email')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約者メールアドレスの形式が不正です。');
      return false;
    }
    return true;
  }

  validateLicense(): boolean {
    if (this.capacity.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '定員数を入力してください。');
      return false;
    }
    if (this.capacity.hasError('min') || this.capacity.hasError('max')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '定員数が不正です。');
      return false;
    }
    if (!this.contractRange.controls.startedAt.value ||
      this.contractRange.controls.startedAt.hasError('matStartDateInvalid')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約期間が不正です。');
      return false;
    }
    if (!this.contractRange.controls.expiredAt.value ||
      this.contractRange.controls.expiredAt.hasError('matEndDateInvalid')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約期間が不正です。');
      return false;
    }
    if (this.contractRange.controls.startedAt.value.unix() * 1000 > new Date().getTime()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約開始日に未来の日付は指定できません。');
      return false;
    }
    if (this.price.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約金額を入力してください。');
      return false;
    }
    if (this.price.hasError('min') || this.price.hasError('max')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約金額が不正です。');
      return false;
    }
    return true;
  }

  async sendInvitationsToAll(): Promise<void> {
    if (this.team?.status !== TeamStatus.active) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'この操作はアクティブなチームにしか実行できません。');
      return;
    }
    const isOK = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, `全てのTATTAユーザーに対し、このチームへの招待を送ります。実行時には必ず上長の許可を得てから行なってください。\n\nこの操作は取り消しできません。本当によろしいですか？`);
    if (!isOK) {
      return;
    }
    const teamId = this.team!.id;
    const res = await this.teamApiService.sendInvitationsToAll(teamId);
    if (res) {
      MessageDialogComponent.createSuccessDialog(this.dialog, '実行しました。完了までしばらくお待ちください。');
    }
  }

  async back(): Promise<void> {
    const queryParams: { [key: string]: TeamPage } = {};
    queryParams[QUERY_PARAM_TEAM_PAGE] = TeamPage.officialTeamManage;
    await this.router.navigate(['/team'], {queryParams});
  }
}
