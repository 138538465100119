<div class="ad-container">
  <p class="ad-explain">バナーはサイドメニュー上に、設定順にローテーションして表示されます。最大10枚まで設定可能です。</p>
  <app-each-ad-setting *ngFor="let order of getAdOrderArray()" [ad]="getAd(order)"
                       (deletedOrder)="onDeleted($event)"></app-each-ad-setting>
  <div class="operation-container">
    <button *ngIf="ads.length < 10" mat-raised-button class="btn" (click)="add()"><i
      class="material-icons mat-dark">add</i>追加
    </button>
  </div>

  <div class="operation-container">
    <button mat-raised-button (click)="save()">保存<i
      class="material-icons mat-dark">save</i></button>
  </div>
</div>
