<div class="container" fxLayout="column" fxLayoutGap="16px">
  <a class="back" (click)="back()">戻る</a>
  <h2 *ngIf="team">ライセンス管理({{ team.name }} ID: {{ team.id }})</h2>

  <div class="subcontainer">
    <div class="action-header">
      <h4 class="subtitle">基本情報</h4>
      <div>
        <button mat-button *ngIf="!isEditBasicInfo" color="primary" (click)="startEditBasicInfo()">編集</button>
        <button mat-button *ngIf="isEditBasicInfo" color="warn" (click)="stopEditBasicInfo()">編集取り消し</button>
        <button mat-button *ngIf="isEditBasicInfo" color="primary" (click)="saveAccount()">保存</button>
      </div>
    </div>

    <mat-form-field>
      <mat-label>契約者名</mat-label>
      <input matInput maxlength="254" [formControl]="paidAccountName"
             [attr.disabled]="isEditBasicInfo ? null : true">
      <mat-hint align="end">{{ paidAccountName.value?.length || 0 }}/254</mat-hint>
      <mat-error *ngIf="paidAccountName.hasError('required')">
        契約者名を入力してください。
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>契約者メールアドレス</mat-label>
      <input matInput type="email" maxlength="254" [formControl]="paidAccountEmail"
             [attr.disabled]="isEditBasicInfo ? null : true">
      <mat-hint align="end">{{ paidAccountEmail.value?.length || 0 }}/254</mat-hint>
      <mat-error *ngIf="paidAccountEmail.hasError('required')">
        契約者メールアドレスを入力してください。
      </mat-error>
      <mat-error *ngIf="paidAccountEmail.hasError('email')">
        契約者メールアドレスの形式が不正です。
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>チームタイプ</mat-label>
      <mat-select [(ngModel)]="teamType" [disabled]="!isEditBasicInfo" (selectionChange)="changeTeamType()">
        <mat-option [value]="TeamType.official">{{ getTeamTypeName(TeamType.official) }}</mat-option>
        <mat-option [value]="TeamType.officialPrivate">{{ getTeamTypeName(TeamType.officialPrivate) }}</mat-option>
        <mat-option
          [value]="TeamType.officialRaceCommunity">{{ getTeamTypeName(TeamType.officialRaceCommunity) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="isEditBasicInfo" class="team-type-info">
      <p *ngIf="teamType === TeamType.official">参加方法は <span
        class="notice">{{ getTeamAclName(TeamAcl.everyone) }}</span> になります。</p>
      <p *ngIf="teamType === TeamType.officialPrivate">参加方法は <span
        class="notice">{{ getTeamAclName(TeamAcl.invitedOnly) }}</span> になります。</p>
      <p *ngIf="teamType === TeamType.officialRaceCommunity">参加方法は <span
        class="notice">{{ getTeamAclName(TeamAcl.everyone) }}</span> になります。</p>
    </div>

    <mat-form-field>
      <mat-label>参加資格</mat-label>
      <mat-select [(ngModel)]="requiredServices" disabled>
        <mat-option [value]="SelectableRequiredServices.Social">ソーシャルログイン</mat-option>
        <mat-option [value]="SelectableRequiredServices.RUNNET">RUNNET連携</mat-option>
        <mat-option [value]="SelectableRequiredServices.OneASICS">OneASICS連携</mat-option>
        <mat-option [value]="SelectableRequiredServices.SocialOrRUNNET">ソーシャルログイン or RUNNET連携</mat-option>
        <mat-option [value]="SelectableRequiredServices.SocialOrOneASICS">ソーシャルログイン or OneASICS連携
        </mat-option>
      </mat-select>
    </mat-form-field>

    <section>
      <label class="subheader">利用可能なチーム内イベント参加資格</label>
      <div *ngIf="additionalEvents" class="checkbox-container">
        <mat-checkbox *ngFor="let service of eventRequiredServices" [(ngModel)]="service.completed"
                      [disabled]="!isEditBasicInfo || service.immutable">
          {{ service.name }}
        </mat-checkbox>
      </div>
    </section>

    <h4 class="subtitle">エントリー自動連携</h4>
    <pre *ngIf="teamType !== TeamType.officialRaceCommunity">
      [指定したランテス番号に属する全ての大会/指定した大会/指定したモシコムイベント]にエントリーしたTATTAユーザーについて、自動的にこのチームへ招待する機能です。
      過去にエントリーした分も遡及されます。
      設定直後に過去分の遡及が行われるため、ダブルチェックを行い、ミスの無いよう十分に注意してください。
    </pre>
    <pre *ngIf="teamType === TeamType.officialRaceCommunity">
      [指定したランテス番号に属する全ての大会/指定した大会/指定したモシコムイベント]にエントリーしたTATTAユーザーについて、自動的にこのチームへフォローさせる機能です。
      過去にエントリーした分も遡及されます。
      設定直後に過去分の遡及が行われるため、ダブルチェックを行い、ミスの無いよう十分に注意してください。
    </pre>

    <mat-form-field *ngIf="isEditBasicInfo">
      <mat-label>ランテス番号で検索</mat-label>
      <input matInput type="search" maxlength="30" [(ngModel)]="queryRuntesNum"
             (change)="searchByRuntesNum()">
    </mat-form-field>

    <div *ngIf="runtesNums.length !== 0">
      <label class="subheader">ランテス番号</label>
      <ul>
        <li *ngFor="let runtesNum of runtesNums;" class="connected-nums">{{ runtesNum }}
          <button *ngIf="isEditBasicInfo" mat-button color="accent" (click)="removeRuntesNum(runtesNum)">×
          </button>
        </li>
      </ul>
    </div>

    <mat-form-field *ngIf="isEditBasicInfo">
      <mat-label>RUNNET大会IDで検索</mat-label>
      <input matInput type="search" maxlength="10" [(ngModel)]="queryRunnetRaceId"
             (change)="searchByRunnetRaceId()">
    </mat-form-field>

    <div *ngIf="runnetRaceIds.length !== 0">
      <label class="subheader">RUNNET大会ID</label>
      <ul>
        <li *ngFor="let runnetRaceId of runnetRaceIds;" class="connected-nums">{{ runnetRaceId }}
          <button *ngIf="isEditBasicInfo" mat-button color="accent" (click)="removeRunnetRaceId(runnetRaceId)">×
          </button>
        </li>
      </ul>
    </div>

    <mat-form-field *ngIf="isEditBasicInfo">
      <mat-label>e-moshicom イベントIDで検索</mat-label>
      <input matInput type="search" maxlength="30"
             [(ngModel)]="queryMoshicomId"
             (change)="searchByMoshicomId()">
    </mat-form-field>

    <div *ngIf="moshicomIds.length !== 0">
      <label class="subheader">e-moshicom イベントID</label>
      <ul>
        <li *ngFor="let moshicomId of moshicomIds;" class="connected-nums">{{ moshicomId }}
          <button *ngIf="isEditBasicInfo" mat-button color="accent" (click)="removeMoshicomId(moshicomId)">×
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div class="subcontainer">
    <div class="action-header">
      <h4 class="subtitle">関連アカウント</h4>
      <div>
        <button mat-button color="primary" (click)="addRelatedAccount()">追加</button>
      </div>
    </div>

    <table mat-table matSort [dataSource]="relatedAccountDataSource"
           class="common-table mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>アカウント名</th>
        <td mat-cell *matCellDef="let account">{{ account.name }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let account">{{ account.email }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>権限</th>
        <td mat-cell *matCellDef="let account">{{ getTeamMemberRoleName(account.role) }}</td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>削除</th>
        <td mat-cell *matCellDef="let account">
          <button mat-button (click)="deleteRelatedAccount(account)">
            <i class="material-icons">delete</i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="relatedAccountDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let account; columns: relatedAccountDisplayedColumns;"></tr>
    </table>
  </div>

  <div class="subcontainer">
    <div class="action-header">
      <h4 class="subtitle">現在のプラン<span class="expired" *ngIf="isExpired">（期限切れ）</span></h4>
      <div>
        <button mat-button *ngIf="!isEditLicense" color="primary" (click)="startEditLicense()">編集</button>
        <button mat-button *ngIf="isEditLicense" color="warn" (click)="stopEditLicense()">編集取り消し</button>
        <button mat-button *ngIf="isEditLicense" color="primary" (click)="saveLicense()">保存</button>
        <button mat-button *ngIf="!isEditLicense" color="primary" (click)="addLicense()">追加</button>
      </div>

    </div>
    <mat-form-field>
      <mat-label>定員数</mat-label>
      <input matInput type="number" min="100" max="999999" [formControl]="capacity"
             [attr.disabled]="isEditLicense ? null : true">
      <mat-error *ngIf="capacity.hasError('required')">
        定員数を入力してください。
      </mat-error>
      <mat-error *ngIf="capacity.hasError('min')">
        100以上の数値を入れてください。
      </mat-error>
      <mat-error *ngIf="capacity.hasError('max')">
        999999以下の数値を入れてください。
      </mat-error>
    </mat-form-field>

    <section>
      <label class="subheader">利用可能なチーム内イベント種別</label>
      <div *ngIf="additionalEvents" class="checkbox-container">
        <mat-checkbox *ngFor="let additionalEvent of additionalEvents" [(ngModel)]="additionalEvent.completed"
                      [disabled]="!isEditLicense || additionalEvent.immutable">
          {{ additionalEvent.name }}
        </mat-checkbox>
      </div>
    </section>

    <mat-form-field appearance="fill" class="contract-term">
      <mat-label>契約期間</mat-label>
      <mat-date-range-input [formGroup]="contractRange" [rangePicker]="contractRangePicker" [disabled]="!isEditLicense">
        <input matStartDate formControlName="startedAt" placeholder="Start date">
        <input matEndDate formControlName="expiredAt" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="contractRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #contractRangePicker></mat-date-range-picker>
      <mat-error *ngIf="contractRange.controls.startedAt.hasError('matStartDateInvalid')">Invalid start date
      </mat-error>
      <mat-error *ngIf="contractRange.controls.expiredAt.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>契約金額（税別）</mat-label>
      <input matInput type="number" min="0" max="999999999" [formControl]="price"
             [attr.disabled]="isEditLicense ? null : true">
      <mat-error *ngIf="price.hasError('required')">
        契約金額を入力してください。
      </mat-error>
      <mat-error *ngIf="price.hasError('min')">
        100以上の数値を入れてください。
      </mat-error>
      <mat-error *ngIf="price.hasError('max')">
        999999999以下の数値を入れてください。
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="pastPlanDataSource.data.length !== 0" class="subcontainer">
    <h4 class="subtitle">過去の契約</h4>

    <table mat-table [dataSource]="pastPlanDataSource"
           class="mat-elevation-z8">
      <ng-container matColumnDef="capacity">
        <th mat-header-cell *matHeaderCellDef>定員</th>
        <td mat-cell *matCellDef="let paidPlan">{{ paidPlan.additionalCapacity + 100 }}</td>
      </ng-container>

      <ng-container matColumnDef="additionalEvents">
        <th mat-header-cell *matHeaderCellDef>追加チーム内イベント種別</th>
        <td mat-cell *matCellDef="let paidPlan">
          <div *ngFor="let additionalEvent of paidPlan.additionalEvents">
            {{ getRaceRankingMethodName(additionalEvent) }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startedAt">
        <th mat-header-cell *matHeaderCellDef>契約開始日</th>
        <td mat-cell *matCellDef="let paidPlan">{{ paidPlan.startedAt | date:'yyyy/M/d HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="expiredAt">
        <th mat-header-cell *matHeaderCellDef>契約終了日</th>
        <td mat-cell *matCellDef="let paidPlan">{{ paidPlan.expiredAt | date:'yyyy/M/d HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>契約金額</th>
        <td mat-cell *matCellDef="let paidPlan">{{ paidPlan.price }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="planDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let paidPlan; columns: planDisplayedColumns;"></tr>
    </table>
  </div>

  <div class="subcontainer">
    <h4 class="subtitle">特殊な操作</h4>
    <button mat-raised-button color="warn" class="invitation-button" (click)="sendInvitationsToAll()">全ユーザーへ招待を送る</button>
  </div>

</div>
