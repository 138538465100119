import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-client',
  templateUrl: './client.component.html',
  styleUrls: ['../help.component.scss']
})
export class ClientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
