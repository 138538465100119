<table mat-table [dataSource]="reports" class="common-table mat-elevation-z8">
  <ng-container matColumnDef="teamId">
    <th mat-header-cell *matHeaderCellDef>チームID</th>
    <td mat-cell *matCellDef="let report">{{report.teamId}}</td>
  </ng-container>
  <ng-container matColumnDef="reporter">
    <th mat-header-cell *matHeaderCellDef>報告者</th>
    <td mat-cell *matCellDef="let report">{{report.userNickname}}
      <ng-container *ngIf="report.userId !== TeamAdminUserId">(ID: {{report.userId}})</ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="reportType">
    <th mat-header-cell *matHeaderCellDef>違反種別</th>
    <td mat-cell *matCellDef="let report">{{getViolationReportTypeName(report.reportType)}}</td>
  </ng-container>
  <ng-container matColumnDef="reason">
    <th mat-header-cell *matHeaderCellDef>理由</th>
    <td mat-cell *matCellDef="let report">{{report.reason}}</td>
  </ng-container>
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef>報告日時</th>
    <td mat-cell *matCellDef="let report">{{report.createdAt | date:'yyyy/M/d HH:mm:ss'}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let report; columns: displayedColumns;" (click)="showDialog(report)"></tr>
</table>
