<div class="common-dialog">
  <div class="title">チーム詳細</div>

  <ng-container *ngIf="report">
    <div class="header">チーム名</div>
    <div class="content">{{ report.team.name }} (ID: {{ report.team.id }})</div>

    <div class="header">チーム紹介文</div>
    <div class="content">{{ report.team.description }}</div>

    <div class="header">種目</div>
    <div class="content">{{ getActivityTypeName(report.team.activityType) }}</div>

    <div class="header">タイプ</div>
    <div class="content">{{ getTeamTypeName(report.team.type) }}</div>

    <div class="header">種別</div>
    <div class="content">{{ getTeamCategoryName(report.team.category) }}</div>

    <div class="header">ステータス</div>
    <div class="content">{{ getTeamStatusName(report.team.status) }}</div>

    <div class="header">メンバー数<span
      *ngIf="report.team.status === TeamStatus.disbanded">(解散時点のメンバー数)</span></div>
    <div class="content">{{ report.team.membership }} / {{ report.team.capacity }}</div>

    <ng-container *ngIf="report.team.area">
      <div class="header">活動エリア</div>
      <div class="content">{{ report.team.area }}</div>
    </ng-container>

    <ng-container *ngIf="report.team.since !== undefined">
      <div class="header">発足年</div>
      <div class="content">{{ report.team.since }}</div>
    </ng-container>

    <ng-container *ngIf="report.team.webLink">
      <div class="header">web</div>
      <div class="content">{{ report.team.webLink }}</div>
    </ng-container>
    <ng-container *ngIf="report.team.twitterLink">
      <div class="header">twitter</div>
      <div class="content">{{ report.team.twitterLink }}</div>
    </ng-container>
    <ng-container *ngIf="report.team.facebookLink">
      <div class="header">facebook</div>
      <div class="content">{{ report.team.facebookLink }}</div>
    </ng-container>
    <ng-container *ngIf="report.team.instagramLink">
      <div class="header">instagram</div>
      <div class="content">{{ report.team.instagramLink }}</div>
    </ng-container>
    <ng-container *ngIf="report.team.youtubeLink">
      <div class="header">instagram</div>
      <div class="content">{{ report.team.youtubeLink }}</div>
    </ng-container>

    <div class="header">イメージ画像</div>
    <a href="{{report.team.imageUrl}}" target="_blank">
      <img class="photo" alt="イメージ画像" src="{{report.team.imageUrl}}"/>
    </a>

    <div class="header">アイコン画像</div>
    <a href="{{report.team.iconUrl}}" target="_blank">
      <img class="photo" alt="アイコン画像" src="{{report.team.iconUrl}}"/>
    </a>
  </ng-container>

  <div *ngIf="isUnanswered()" class="operation-container">
    <button class="close-button" [mat-dialog-close]="undefined">閉じる</button>
    <button class="ignore-button" [mat-dialog-close]="ViolationReportStatus.ignored">無視する</button>
    <button class="approve-button" [mat-dialog-close]="ViolationReportStatus.approved">休止する</button>
  </div>

  <div *ngIf="!isUnanswered()" class="close-operation-container">
    <button class="close-button" [mat-dialog-close]="undefined">閉じる</button>
  </div>
</div>
