import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {API} from 'aws-amplify';
import {OverlayService} from './overlay.service';
import {MessageDialogComponent} from '../../views/partials/message-dialog/message-dialog.component';
import {AuthService} from './auth.service';
import {MatDialog} from '@angular/material/dialog';
import {FeedItemReport, ViolationReportStatus} from '../model/resources/violation-report';

@Injectable({
  providedIn: 'root'
})
export class FeedApiService {

  constructor(
    private apiHelperService: ApiHelperService,
    private authService: AuthService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
  ) {
  }

  async getFeedReports(status: ViolationReportStatus): Promise<FeedItemReport[]> {
    if (!this.authService.userAuth?.isAllowManageTeam()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return [];
    }
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      queryStringParameters: {status}
    };

    try {
      return await this.overlayService.call(API.get(this.apiHelperService.apiName, 'feed/reports', init));
    } catch (e: any) {
      MessageDialogComponent.createErrorDialog(this.dialog, e.response.data.error);
      return [];
    }
  }

  async patchFeedReport(uuid: string, status: ViolationReportStatus): Promise<boolean> {
    if (!this.authService.userAuth?.isAllowManageTeam()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return false;
    }
    if (status === ViolationReportStatus.unanswered) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'Illegal request');
      return false;
    }

    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      body: {status}
    };

    try {
      await this.overlayService.call(API.patch(this.apiHelperService.apiName, `feed/reports/${uuid}`, init));
      return true;
    } catch (e: any) {
      MessageDialogComponent.createErrorDialog(this.dialog, e.response.data.error);
      return false;
    }
  }

}
