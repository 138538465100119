<h2 mat-dialog-title>検索結果</h2>

<mat-dialog-content class="mat-typography">
  <div class="table-container">
    <table *ngIf="!this.data.moshicomEvent" mat-table [dataSource]="data.runtesRaces!" class="common-table mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>大会ID</th>
        <td mat-cell *matCellDef="let race">{{race.runnetRaceId}} </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>名称</th>
        <td mat-cell *matCellDef="let race">{{race.title}}</td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>開始日</th>
        <td mat-cell *matCellDef="let race">{{race.startDate}}</td>
      </ng-container>

      <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef>終了日</th>
        <td mat-cell *matCellDef="let race">{{race.endDate}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="runtesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let race; columns: runtesDisplayedColumns;"></tr>
    </table>

    <table *ngIf="!!this.data.moshicomEvent" mat-table [dataSource]="[data.moshicomEvent!]" class="mat-elevation-z8">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>名称</th>
        <td mat-cell *matCellDef="let race">{{race.title}}</td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>開始日時</th>
        <td mat-cell *matCellDef="let race">{{race.startTime}}</td>
      </ng-container>

      <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef>終了日時</th>
        <td mat-cell *matCellDef="let race">{{race.endTime}}</td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef>URL</th>
        <td mat-cell *matCellDef="let race"><a [href]="race.url" target="_blank">確認</a></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="moshicomDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let race; columns: moshicomDisplayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="false">キャンセル</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">検索結果を追加する</button>
</mat-dialog-actions>

