import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {FeedItemReport, ViolationReportStatus} from '../../../../../../core/model/resources/violation-report';

@Component({
  selector: 'app-feed-report-dialog',
  templateUrl: './feed-report-dialog.component.html',
  styleUrls: ['./feed-report-dialog.component.scss']
})
export class FeedReportDialogComponent implements OnInit {
  ViolationReportStatus = ViolationReportStatus;
  report?: FeedItemReport;

  static async createDialog(dialog: MatDialog, report: FeedItemReport): Promise<ViolationReportStatus | undefined> {
    return await new Promise((resolve, reject) => {
      dialog.open(FeedReportDialogComponent, {
        data: {
          report
        },
        width: '600px',
        maxWidth: '600px',
        maxHeight: '500px'
      }).afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { report: FeedItemReport },
  ) {
  }

  ngOnInit(): void {
    this.report = this.data.report;
  }

  isUnanswered(): boolean {
    return this.report!.reportStatus === ViolationReportStatus.unanswered;
  }

}
