import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  getViolationReportTypeName,
  TeamCoursesReport,
  ViolationReportType
} from '../../../../../core/model/resources/violation-report';
import {TeamApiService} from '../../../../../core/services/team-api.service';
import {MatDialog} from '@angular/material/dialog';
import {CourseReportDialogComponent} from './course-report-dialog/course-report-dialog.component';

@Component({
  selector: 'app-course-report-table',
  templateUrl: './course-report-table.component.html',
  styleUrls: ['./course-report-table.component.scss']
})
export class CourseReportTableComponent implements OnInit {

  displayedColumns = ['teamId', 'reporter', 'reportType', 'reason', 'createdAt'];

  @Input() reports?: TeamCoursesReport[];
  @Output() changedReport = new EventEmitter<TeamCoursesReport>();

  constructor(
    private teamApiService: TeamApiService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (this.reports === undefined) {
      throw new Error('reports is required');
    }
  }

  async showDialog(report: TeamCoursesReport): Promise<void> {
    const status = await CourseReportDialogComponent.createDialog(this.dialog, report);
    if (!status) {
      return;
    }
    await this.teamApiService.patchTeamCourseReport(report.course.uuid, status);
    this.changedReport.emit(report);
  }

  getViolationReportTypeName(type: ViolationReportType): string {
    return getViolationReportTypeName(type);
  }

}
