import {Injectable} from '@angular/core';
import {ApiHelperService, ApiResult} from './api-helper.service';
import {API} from 'aws-amplify';
import {OverlayService} from './overlay.service';
import {MessageDialogComponent} from '../../views/partials/message-dialog/message-dialog.component';
import {AuthService} from './auth.service';
import {MatDialog} from '@angular/material/dialog';
import {User} from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

  constructor(
    private apiHelperService: ApiHelperService,
    private authService: AuthService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
  ) {
  }

  async get(): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowManageUser()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers
    };

    try {
      const result = await this.overlayService.call(API.get(this.apiHelperService.apiName, 'users', init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async post(user: User): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowManageUser()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      body: user
    };

    try {
      const result = await this.overlayService.call(API.post(this.apiHelperService.apiName, 'users', init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async put(user: User): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowManageUser()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      body: user
    };

    try {
      const result = await this.overlayService.call(API.put(this.apiHelperService.apiName, 'users', init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async delete(deleteUserInput: DeleteUserInput): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowManageUser()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      body: deleteUserInput
    };

    try {
      const result = await this.overlayService.call(API.del(this.apiHelperService.apiName, 'users', init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

}

export interface DeleteUserInput {
  email: string;
}

