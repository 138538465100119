<mat-toolbar color="{{baseColor}}">
  <button mat-button (click)="toggleSideNav()">
    <mat-icon class="material-icons">menu</mat-icon>
  </button>
  <button mat-button (click)="returnHome()"><h3>{{title}}</h3></button>
  <span class="spacer"></span>
  <button mat-button (click)="openHelp();"><i class="material-icons">help</i>ヘルプ</button>
  <button mat-button [matMenuTriggerFor]="menu">
    <span style="font-size: 14px;">ようこそ、{{this.authService.user?.name}}さん</span>
    <i class="material-icons">arrow_drop_down</i>
  </button>
  <mat-menu #menu="matMenu">
    <div mat-menu-item>権限: {{this.authService.userAuth?.getViewRole()}}</div>
    <button mat-menu-item (click)="onLogOutClicked()">ログアウト</button>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav mode="side" [(opened)]="isOpenSideNav" opened>
    <mat-nav-list>
      <a mat-list-item routerLink="/dashboard">ダッシュボード</a>
      <a *ngIf="this.authService.userAuth?.isAllowViewRaces()" mat-list-item routerLink="/race">大会/イベント</a>
      <a *ngIf="this.authService.userAuth?.isAllowViewRunner()" mat-list-item routerLink="/runner">ランナー検索</a>
      <a *ngIf="this.authService.userAuth?.isAllowViewInformation()" mat-list-item routerLink="/information">お知らせ</a>
      <a *ngIf="this.authService.userAuth?.isAllowViewInformation()" mat-list-item routerLink="/service-status-information">システム稼働情報管理</a>
      <a *ngIf="this.authService.userAuth?.isAllowViewAllUserCoupon()" mat-list-item routerLink="/allUserCoupon">TATTAクーポン</a>
      <a *ngIf="this.authService.userAuth?.isAllowManageTeam()" mat-list-item routerLink="/team">チーム管理</a>
      <a *ngIf="this.authService.userAuth?.isAllowManageWidget()" mat-list-item routerLink="/widget">ウィジェット管理</a>
      <a *ngIf="this.authService.userAuth?.isAllowManageUser()" mat-list-item routerLink="/user">ユーザー管理</a>
      <a *ngIf="this.authService.userAuth?.isAllowManagerAds()" mat-list-item routerLink="/ads">広告設定</a>
      <a *ngIf="this.authService.userAuth?.isAllowViewUsageReports" mat-list-item routerLink="/report">利用状況</a>
      <a *ngIf="this.authService.userAuth?.isAllowInvokeBatch()" mat-list-item routerLink="/batch">バッチ実行</a>
      <a *ngIf="this.authService.userAuth?.isAllowViewApi()" mat-list-item routerLink="/api-specification">API仕様書</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="container">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<!--<mat-sidenav-container class="example-container" *ngIf="shouldRun">-->
<!--  <mat-sidenav mode="side" opened>Sidenav content</mat-sidenav>-->
<!--  <mat-sidenav-content>Main content</mat-sidenav-content>-->
<!--</mat-sidenav-container>-->
