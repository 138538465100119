import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getViolationReportTypeName, TeamReport, ViolationReportType} from '../../../../../core/model/resources/violation-report';
import {MatDialog} from '@angular/material/dialog';
import {TeamApiService} from '../../../../../core/services/team-api.service';
import {TeamReportDialogComponent} from './team-report-dialog/team-report-dialog.component';

@Component({
  selector: 'app-team-report-table',
  templateUrl: './team-report-table.component.html',
  styleUrls: ['./team-report-table.component.scss']
})
export class TeamReportTableComponent implements OnInit {

  displayedColumns = ['teamId', 'reporter', 'reportType', 'reason', 'createdAt'];

  @Input() reports?: TeamReport[];
  @Output() changedReport = new EventEmitter<TeamReport>();

  constructor(
    private teamApiService: TeamApiService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  async showDialog(report: TeamReport): Promise<void> {
    const status = await TeamReportDialogComponent.createDialog(this.dialog, report);
    if (!status) {
      return;
    }
    await this.teamApiService.patchTeamReport(report.teamId, status);
    this.changedReport.emit(report);
  }

  getViolationReportTypeName(type: ViolationReportType): string {
    return getViolationReportTypeName(type);
  }
}
