<div *ngIf="links?.length" class="docs-toc-container">
  <div class="docs-toc-heading">{{title}}</div>
  <nav>
    <a (click)="navigate(link.id)" *ngFor="let link of links" class="docs-level-{{link.type}} docs-link"
       [class.docs-active]="link.active">
      {{link.name}}
    </a>
  </nav>
</div>

