import {Component, OnInit} from '@angular/core';
import {getTeamTypeName, TeamType} from '../../../../core/model/resources/team';

@Component({
  selector: 'app-official-team-manage',
  templateUrl: './official-team-manage.component.html',
  styleUrls: ['./official-team-manage.component.scss']
})
export class OfficialTeamManageComponent implements OnInit {

  TeamType = TeamType;
  getTeamTypeName = getTeamTypeName;

  constructor() {
  }

  ngOnInit(): void {
  }
}
