import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/auth';
import {BaseComponent} from './views/theme/base/base.component';
import {RunnerComponent} from './views/pages/runner/runner.component';
import {UserComponent} from './views/pages/user/user.component';
import {HelpComponent} from './views/pages/help/help.component';
import {CouponComponent} from './views/pages/coupon/coupon.component';
import {CouponCreateComponent} from './views/pages/coupon/create/coupon-create.component';
import {CouponEditComponent} from './views/pages/coupon/edit/coupon-edit.component';
import {ALL_USER_COUPON_URL} from './core/model/resources/coupon';
import {TeamComponent} from './views/pages/team/team.component';
import {
  CreateOfficialTeamComponent
} from './views/pages/team/official-team-manage/create-official-team/create-official-team.component';
import {EditLicenseComponent} from './views/pages/team/official-team-manage/edit-license/edit-license.component';
import {AdsComponent} from './views/pages/ads/ads.component';

const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'information',
        loadChildren: () => import('./views/pages/information/information.module').then(m => m.InformationModule)
      },
      {
        path: 'service-status-information',
        loadChildren: () => import('./views/pages/service-status-information/service-status-information.module')
          .then(m => m.ServiceStatusInformationModule)
      },
      {path: `${ALL_USER_COUPON_URL}`, component: CouponComponent},
      {path: `${ALL_USER_COUPON_URL}/coupon/create`, component: CouponCreateComponent},
      {path: `${ALL_USER_COUPON_URL}/coupon/edit/:id`, component: CouponEditComponent},
      {path: 'race', loadChildren: () => import('./views/pages/race/race.module').then(m => m.RaceModule)},
      {path: 'batch', loadChildren: () => import('./views/pages/batch/batch.module').then(m => m.BatchModule)},
      {
        path: 'api-specification',
        loadChildren: () => import('./views/pages/api-specification/api-specification.module').then(m => m.ApiSpecificationModule)
      },
      {path: 'runner', component: RunnerComponent},
      {path: 'user', component: UserComponent},
      {path: 'ads', component: AdsComponent},
      {
        path: 'report',
        loadChildren: () => import('./views/pages/report/report.module').then(m => m.ReportModule)
      },
      {path: 'help', component: HelpComponent},
      {
        path: 'team', children: [
          {path: '', component: TeamComponent},
          {path: 'create', component: CreateOfficialTeamComponent, data: {isPromote: false}},
          {path: 'promote', component: CreateOfficialTeamComponent, data: {isPromote: true}},
          {path: ':id/license', component: EditLicenseComponent},
        ]
      },
      {path: 'widget', loadChildren: () => import('./views/pages/widget/widget.module').then(m => m.WidgetModule)},
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
