import {AfterViewChecked, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {CourseGeoJson, TeamCoursesReport, ViolationReportStatus} from '../../../../../../core/model/resources/violation-report';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {FullscreenControl, LngLatBounds, Map} from 'mapbox-gl';
import {environment} from '../../../../../../../environments/environment';
import {Position} from 'geojson';
import {UtilService} from '../../../../../../core/services/util.service';
import {TeamApiService} from '../../../../../../core/services/team-api.service';
import {MessageDialogComponent} from '../../../../../partials/message-dialog/message-dialog.component';


@Component({
  selector: 'app-course-report-dialog',
  templateUrl: './course-report-dialog.component.html',
  styleUrls: ['./course-report-dialog.component.scss']
})
export class CourseReportDialogComponent implements OnInit, AfterViewChecked, OnDestroy {

  ViolationReportStatus = ViolationReportStatus;
  report?: TeamCoursesReport;

  mapId = 'courseMap';
  map: Map | null = null;
  coordinates?: [number, number][];  // [lon, lat][]
  private isMapInitialized = false;
  isSatellite = false;

  static async createDialog(dialog: MatDialog, report: TeamCoursesReport): Promise<ViolationReportStatus | undefined> {
    return await new Promise((resolve, reject) => {
      dialog.open(CourseReportDialogComponent, {
        data: {
          report
        },
        width: '600px',
        maxWidth: '600px',
        maxHeight: '600px'
      }).afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }

  constructor(
    private teamApiService: TeamApiService,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { report: TeamCoursesReport }
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.report = this.data.report;
    const geoJson: CourseGeoJson | null = await this.teamApiService.getTeamCourseGeoJson(this.report.course.courseUrl);
    if (!geoJson) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'コース情報が存在しません。');
      return;
    }

    this.coordinates = geoJson.features[0].geometry.coordinates.map(coordinate => {
      return [coordinate[0], coordinate[1]];
    });
  }

  ngAfterViewChecked(): void {
    if (!!this.coordinates && document.getElementById(this.mapId) && !this.isMapInitialized) {
      this.isMapInitialized = true;
      this.drawMap('mapbox://styles/rbs-netadmin/cj458yrji09xg2smykk0x8z3z');
    }
  }

  isUnanswered(): boolean {
    return this.report!.reportStatus === ViolationReportStatus.unanswered;
  }

  toggleStyle(): void {
    if (this.map !== null) {
      if (this.map.getStyle().name === 'Mapbox Satellite') {
        this.map.remove();
        this.drawMap('mapbox://styles/rbs-netadmin/cj458yrji09xg2smykk0x8z3z');
      } else {
        this.map.remove();
        this.drawMap('mapbox://styles/mapbox/satellite-v9');
      }
    }
  }

  private drawMap(style: string): void {
    this.map = new Map({
      accessToken: environment.mapboxAccessToken,
      container: this.mapId,
      style,
    });
    this.map.addControl(new FullscreenControl());
    this.map.on('load', () => {
      UtilService.addLine(this.map!, 'route', this.coordinates as Position[], 'red');
      const bounds = this.coordinates!.reduce((newBounds, coord) => {
        return newBounds.extend(coord);
      }, new LngLatBounds(this.coordinates![0], this.coordinates![0]));
      this.map!.fitBounds(bounds, {
        padding: 20,
        maxDuration: 2
      });
    });
    UtilService.addDistanceMarkers(this.map, this.coordinates!);
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }

    this.map = null;
  }
}
