<div class="client-container">

  <p>アプリに関するヘルプです。</p>
  <br>

  <app-help-item [id]="'sort-order'" [title]="'各種ソート順について'"></app-help-item>
  <pre class="contents">
    各種項目の表示順序に関して記載しています。
  </pre>
  <dl>
    <dt>大会（エントリー中、参加履歴）</dt>
    <dd>大会開催日の降順にて表示されます。</dd>

    <dt>イベント（受付中）</dt>
    <dd>集計終了日の昇順にて表示されます。</dd>

    <dt>イベント（エントリー中、参加履歴、友だち画面）</dt>
    <dd>集計終了日の降順にて表示されます。</dd>

    <dt>お知らせ</dt>
    <dd>配信日時の降順にて表示されます。</dd>

    <dt>クーポン</dt>
    <dd>配布日時の降順にて表示されます。</dd>
  </dl>
</div>

<div class="toc">
  <app-toc [title]="'TATTA'" [headerSelectors]="'.client-container h3, .client-container h4'"
           [helpIndex]="1"></app-toc>
</div>
