import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {NotificationType, RunnersApiService} from '../../../../core/services/runners-api.service';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  NotificationType = NotificationType;
  notificationType = NotificationType.NOTIFY_COUPON;

  textControl: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required],
    nonNullable: true,
  });
  idControl: FormControl<number | null> = new FormControl<number | null>(null, {
    validators: null,
    nonNullable: false,
  });

  static async open(dialog: MatDialog, userId: number): Promise<void> {
    return await dialog.open(NotificationDialogComponent, {
      width: '60vw',
      data: {
        userId
      }
    }).afterClosed().toPromise();
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { userId: number },
    private runnerApiService: RunnersApiService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  getIdText(): string {
    if (this.notificationType === NotificationType.NOTIFY_COUPON) {
      return 'couponId';
    } else if (this.notificationType === NotificationType.NOTIFY_INFORMATION) {
      return 'informationId';
    } else if (this.notificationType === NotificationType.NOTIFY_RANKING ||
      this.notificationType === NotificationType.NOTIFY_RACE_IMPORT ||
      this.notificationType === NotificationType.NOTIFY_EVENT_IMPORT) {
      return 'raceId';
    } else {
      MessageDialogComponent.createErrorDialog(this.dialog, '問題が発生しました。再度やり直してください。');
      throw new Error('問題が発生しました。再度やり直してください。');
    }
  }

  async send(): Promise<void> {
    if (this.textControl.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '本文を入力してください。');
      return;
    }

    let targetId: number | null = null;

    if (this.notificationType === NotificationType.NOTIFY_COUPON || this.notificationType === NotificationType.NOTIFY_INFORMATION
      || this.notificationType === NotificationType.NOTIFY_RANKING) {
      targetId = this.idControl.value;
      if (targetId === undefined || targetId === null) {
        MessageDialogComponent.createErrorDialog(this.dialog, 'IDを入力してください。');
        return;
      }
    }

    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'PUSH通知を送信します。よろしいですか？');
    if (!isOK) {
      return;
    }

    const res = await this.runnerApiService.postNotification(
      this.notificationType, this.data.userId, this.textControl.value, targetId !== null ? targetId : undefined
    );
    if (res.success) {
      MessageDialogComponent.createSuccessDialog(this.dialog, `送信しました。\n${res.data.message}`);
    } else {
      MessageDialogComponent.createErrorDialog(this.dialog, res.error!);
    }
  }
}
