<div class="container">
  <button mat-raised-button color="primary" (click)="searchUserRaces()">検索
  </button>
</div>

<table mat-table [dataSource]="dataSource" class="common-table mat-elevation-z1">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>id</th>
    <td mat-cell *matCellDef="let entryRace">{{entryRace.race.id}}</td>
  </ng-container>
  <ng-container matColumnDef="race_id">
    <th mat-header-cell *matHeaderCellDef>RUNTES/e-moshicom Id</th>
    <td mat-cell *matCellDef="let entryRace">{{getRaceId(entryRace.race)}}</td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>大会名</th>
    <td mat-cell *matCellDef="let entryRace">{{entryRace.race.title}}
      <span *ngIf="getLabel(entryRace.race)"
            [className]="getBadgeClass(entryRace.race)">{{getLabel(entryRace.race)}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="ranking_term">
    <th mat-header-cell *matHeaderCellDef>集計期間</th>
    <td mat-cell *matCellDef="let entryRace">
      <ng-container *ngIf="!entryRace.race.is_user_generated">
        {{entryRace.race.beginning_ranking_term| date:'yyyy/M/d HH:mm:ss'}}
        〜 {{entryRace.race.end_of_ranking_term| date:'yyyy/M/d HH:mm:ss'}}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="open_date">
    <th mat-header-cell *matHeaderCellDef>開催日</th>
    <td mat-cell *matCellDef="let entryRace">{{getOpenDateString(entryRace.race)}}</td>
  </ng-container>
  <ng-container matColumnDef="is_cheater">
    <th mat-header-cell *matHeaderCellDef>チーター</th>
    <td mat-cell *matCellDef="let entryRace">{{UtilService.getCheckString(entryRace.entry.isCheater)}}</td>
  </ng-container>
  <ng-container matColumnDef="operation">
    <th mat-header-cell *matHeaderCellDef>操作</th>
    <td mat-cell *matCellDef="let entryRace">
      <button mat-button (click)="showCheatSettingDialog(entryRace)">チーター設定</button>
      <button *ngIf="!entryRace.race.moshicom && entryRace.entry.raceResults" mat-button
              (click)="showEditRaceResultDialog(entryRace)">大会結果
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="raceDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let entryRace; columns: raceDisplayedColumns;"></tr>
</table>

