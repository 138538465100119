import {Component, OnDestroy, OnInit} from '@angular/core';
import {Ad} from '../../../core/model/ad';
import {S3Service} from '../../../core/services/s3.service';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialogComponent} from '../../partials/message-dialog/message-dialog.component';
import {UtilService} from '../../../core/services/util.service';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit, OnDestroy {

  ads: Ad[] = [];
  adsNum = 0;

  added: Ad[] = [];

  constructor(private s3Service: S3Service,
              private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    this.ads = await this.s3Service.getAdSettings();
    this.adsNum = this.ads.length;
  }

  getAd(order: number): Ad {
    return this.ads.find(ad => ad.order === order) || {
      order,
      image_url: '',
      redirect_url: '',
      analytics_label: ''
    };
  }

  async onDeleted(order: number): Promise<void> {
    const filteredAds = this.ads.filter(ad => ad.order !== order);
    for (let i = 0; i < filteredAds.length; i++) {
      const ad = filteredAds[i];
      ad.order = i + 1;
    }
    this.ads = filteredAds;

    // 一度子コンポーネントを初期化して再生成する
    this.adsNum = 0;
    setTimeout(() => {
      this.adsNum = filteredAds.length;
    }, 0);
  }

  getAdOrderArray(): number[] {
    const res: number[] = [];
    for (let i = 0; i < this.adsNum; i++) {
      res.push(i + 1);
    }
    return res;
  }

  add(): void {
    if (this.adsNum >= 10) {
      return;
    }
    const ad = {
      order: this.ads.length + 1,
      image_url: '',
      redirect_url: '',
      analytics_label: ''
    };
    this.ads.push(ad);
    this.added.push(ad);
    this.adsNum++;
  }

  async save(): Promise<void> {
    for (const ad of this.ads) {
      if (!ad.image_url || !ad.redirect_url || !ad.analytics_label) {
        MessageDialogComponent.createErrorDialog(this.dialog, `${ad.order}枚目の設定項目を入力してください。`);
        return;
      }
      if (!ad.redirect_url.startsWith('http')) {
        MessageDialogComponent.createErrorDialog(this.dialog, `${ad.order}枚目の遷移先URLの形式が正しくありません。`);
        return;
      }
      if (!UtilService.isHalfChar(ad.analytics_label)) {
        MessageDialogComponent.createErrorDialog(this.dialog, `${ad.order}枚目のAnalyticsラベルには半角英数を入力してください。`);
        return;
      }
    }

    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '設定を保存します。よろしいですか？');
    if (!isOK) {
      return;
    }

    await this.s3Service.putAdSettings(this.ads);
    this.added = [];
    MessageDialogComponent.createSuccessDialog(this.dialog, '保存しました。');
  }

  async ngOnDestroy() {
    await Promise.all(this.added.map(async ad => {
      if (ad.image_url) {
        await this.s3Service.deleteStoreImageFromAdsUrl(ad.image_url);
      }
    }));
  }

}
