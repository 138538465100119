import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MessageDialogComponent} from '../../../../partials/message-dialog/message-dialog.component';
import {
  PostOfficialTeamInput,
  PromoteOfficialTeamInput,
  TeamApiService
} from '../../../../../core/services/team-api.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivityType} from '../../../../../core/model/resources/activity';
import {
  getTeamAclName,
  getTeamCategoryName,
  getTeamPostAclName,
  getTeamTypeName,
  OfficialTeam,
  Team,
  TeamAcl,
  TeamCategory,
  TeamPaidPlan,
  TeamPostAcl,
  TeamStatus,
  TeamType
} from '../../../../../core/model/resources/team';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {QUERY_PARAM_TEAM_PAGE, TeamPage} from '../../team.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {S3Service} from '../../../../../core/services/s3.service';
import {getRaceRankingMethodName, RaceRankingMethod} from '../../../../../core/model/resources/race';
import {RaceConfirmDialogComponent} from '../race-confirm-dialog/race-confirm-dialog.component';
import {Moment} from 'moment';
import {
  getRequiredServices,
  LinkageService,
  SelectableRequiredServices
} from '../../../../../core/model/resources/tatta-user';

@Component({
  selector: 'app-create-official-team',
  templateUrl: './create-official-team.component.html',
  styleUrls: ['./create-official-team.component.scss']
})
export class CreateOfficialTeamComponent implements OnInit {

  protected readonly SelectableRequiredServices = SelectableRequiredServices;
  protected readonly ActivityType = ActivityType;

  TeamType = TeamType;
  TeamCategory = TeamCategory;
  TeamAcl = TeamAcl;
  TeamPostAcl = TeamPostAcl;
  getTeamTypeName = getTeamTypeName;
  getTeamCategoryName = getTeamCategoryName;
  getTeamAclName = getTeamAclName;
  getTeamPostAclName = getTeamPostAclName;

  isPromote = false;
  teamId?: number;
  isSearchedTeam = false;

  imageFile?: File;
  imageUrl?: string;
  @ViewChild('imageFileInput') public imageFileInput?: ElementRef;
  iconFile?: File;
  iconUrl?: string;
  @ViewChild('iconFileInput') public iconFileInput?: ElementRef;

  paidAccountName: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.minLength(1), Validators.maxLength(254)],
    nonNullable: true,
  });
  paidAccountEmail: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.email, Validators.minLength(1), Validators.maxLength(254)],
    nonNullable: true,
  });
  teamType: TeamType.official | TeamType.officialPrivate | TeamType.officialRaceCommunity = TeamType.official;
  requiredServices: SelectableRequiredServices = SelectableRequiredServices.RUNNET;
  eventRequiredServices: { name: string, value: LinkageService, completed: boolean, immutable: boolean }[] = [];

  queryRuntesNum: string = '';
  runtesNums: string[] = [];
  queryRunnetRaceId: string = '';
  runnetRaceIds: number[] = [];
  queryMoshicomId: string = '';
  moshicomIds: number[] = [];

  capacity: FormControl<number | null> = new FormControl<number | null>(100, {
    validators: [Validators.required, Validators.min(100), Validators.max(999999)],
    nonNullable: false,
  });
  additionalEvents?: { name: string, value: RaceRankingMethod, completed: boolean, immutable: boolean }[];
  contractRange = new FormGroup<{
    startedAt: FormControl<Moment | null>,
    expiredAt: FormControl<Moment | null>
  }>({
    startedAt: new FormControl<Moment | null>(null),
    expiredAt: new FormControl<Moment | null>(null),
  });
  price: FormControl<number | null> = new FormControl<number | null>(0, {
    validators: [Validators.required, Validators.min(0), Validators.max(999999999)],
    nonNullable: false,
  });

  name: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.minLength(1), Validators.maxLength(30)],
    nonNullable: true,
  });
  activityType = ActivityType.RUNNING;
  category = TeamCategory.company;
  aclPostFeed = TeamPostAcl.adminOnly;
  aclPostTeamCourse = TeamPostAcl.adminOnly;
  aclPostTeamHistory = TeamPostAcl.adminOnly;
  aclCreateIntraEvent = TeamPostAcl.adminOnly;
  aclInviteMember = TeamPostAcl.adminOnly;
  area: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.maxLength(30)],
    nonNullable: true,
  });
  description: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.minLength(1), Validators.maxLength(1000)],
    nonNullable: true,
  });
  webLink: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.maxLength(2048)],
    nonNullable: true,
  });
  twitterLink: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.maxLength(16)],
    nonNullable: true,
  });
  facebookLink: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.maxLength(2048)],
    nonNullable: true,
  });
  instagramLink: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.maxLength(30)],
    nonNullable: true,
  });
  youtubeLink: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.maxLength(2048)],
    nonNullable: true,
  });

  constructor(
    private teamApiService: TeamApiService,
    private s3Service: S3Service,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isPromote = this.route.snapshot.data.isPromote;
    this.additionalEvents = [
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByDistance),
        value: RaceRankingMethod.RankingByDistance,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByTime),
        value: RaceRankingMethod.RankingByTime,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByArea),
        value: RaceRankingMethod.RankingByArea,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByDistanceChallenge),
        value: RaceRankingMethod.RankingByDistanceChallenge,
        completed: false,
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByCalorieChallenge),
        value: RaceRankingMethod.RankingByCalorieChallenge,
        completed: false,
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByGroupDistance),
        value: RaceRankingMethod.RankingByGroupDistance,
        completed: false,
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingBySeriesEvent),
        value: RaceRankingMethod.RankingBySeriesEvent,
        completed: false,
        immutable: false
      }
    ];
    this.eventRequiredServices = [
      {
        name: 'ソーシャルログイン',
        value: LinkageService.Social,
        completed: false,
        immutable: false
      },
      {
        name: 'RUNNET連携',
        value: LinkageService.RUNNET,
        completed: false,
        immutable: false
      },
      {
        name: 'OneASICS連携',
        value: LinkageService.OneASICS,
        completed: false,
        immutable: false
      },
    ];
    this.setRequiredServices();
    if (this.isPromote) {
      this.name.disable();
      this.area.disable();
      this.description.disable();
      this.webLink.disable();
      this.twitterLink.disable();
      this.facebookLink.disable();
      this.instagramLink.disable();
      this.youtubeLink.disable();
    }
  }

  async searchByTeamId(): Promise<void> {
    if (this.teamId === undefined) {
      return;
    }

    const team: Team | OfficialTeam | null = await this.teamApiService.getTeam(this.teamId);
    if (!team) {
      return;
    }
    if (team.type === TeamType.official || team.type === TeamType.officialPrivate || team.type === TeamType.officialRaceCommunity) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'オフィシャルチームのIDは指定できません。');
      return;
    }
    if (team.status !== TeamStatus.active) {
      MessageDialogComponent.createErrorDialog(this.dialog, '活動中のチームではありません。');
      return;
    }
    this.imageUrl = this.s3Service.getTeamImageUrl(this.teamId);
    this.iconUrl = this.s3Service.getTeamIconUrl(this.teamId);
    this.name.setValue(team.name);
    this.description.setValue(team.description);
    this.area.setValue(team.area ?? '');
    this.twitterLink.setValue(team.twitterLink ?? '');
    this.instagramLink.setValue(team.instagramLink ?? '');
    this.youtubeLink.setValue(team.youtubeLink ?? '');
    this.facebookLink.setValue(team.facebookLink ?? '');
    this.webLink.setValue(team.webLink ?? '');
    this.activityType = team.activityType;
    this.category = team.category;
    this.aclPostFeed = team.aclPostFeed;
    this.aclPostTeamCourse = team.aclPostTeamCourse;
    this.aclPostTeamHistory = team.aclPostTeamHistory;
    this.aclCreateIntraEvent = team.aclCreateIntraEvent;
    this.aclInviteMember = team.aclInviteMember;

    this.isSearchedTeam = true;
  }

  getTitle(): string {
    return this.isPromote ? 'オフィシャルチーム[昇格]' : 'オフィシャルチーム[新規登録]';
  }

  setRequiredServices() {
    const requiredServices = getRequiredServices(this.requiredServices);
    this.eventRequiredServices.forEach(eventRequiredService => {
      eventRequiredService.completed = !!requiredServices.find(service => service === eventRequiredService.value);
      eventRequiredService.immutable = !!requiredServices.find(service => service === eventRequiredService.value);
    });
  }

  changedRequiredServices() {
    this.setRequiredServices();
  }

  async searchByRuntesNum(): Promise<void> {
    const races = await this.teamApiService.getRuntesRaces(this.queryRuntesNum);
    if (!races || races.length === 0) {
      MessageDialogComponent.createErrorDialog(this.dialog, '指定したランテス番号に属する大会は見つかりませんでした。');
      return;
    }
    const isOK = await RaceConfirmDialogComponent.createRaceConfirmDialog(this.dialog, races.sort((a, b) => b.startDate - a.startDate));
    if (isOK) {
      if (this.runtesNums.find(runtesNum => runtesNum === this.queryRuntesNum)) {
        MessageDialogComponent.createErrorDialog(this.dialog, '既に追加済みです。');
        return;
      }
      this.runtesNums.push(this.queryRuntesNum);
      this.queryRuntesNum = '';
    }
  }

  removeRuntesNum(runtesNum: string): void {
    this.runtesNums = this.runtesNums.filter(val => val !== runtesNum);
  }

  async searchByRunnetRaceId(): Promise<void> {
    const runnetRaceId = Number(this.queryRunnetRaceId);
    if (isNaN(runnetRaceId)) {
      MessageDialogComponent.createErrorDialog(this.dialog, '半角数字を入力してください。');
      this.queryRunnetRaceId = '';
      return;
    }
    const race = await this.teamApiService.getRuntesRace(runnetRaceId);
    if (!race) {
      MessageDialogComponent.createErrorDialog(this.dialog, '指定したIDの大会は見つかりませんでした。');
      return;
    }
    const isOK = await RaceConfirmDialogComponent.createRaceConfirmDialog(this.dialog, [race]);
    if (isOK) {
      if (this.runnetRaceIds.find(addedId => addedId === runnetRaceId)) {
        MessageDialogComponent.createErrorDialog(this.dialog, '既に追加済みです。');
        return;
      }
      this.runnetRaceIds.push(runnetRaceId);
      this.queryRunnetRaceId = '';
    }
  }

  removeRunnetRaceId(runnetRaceId: number): void {
    this.runnetRaceIds = this.runnetRaceIds.filter(val => val !== runnetRaceId);
  }

  async searchByMoshicomId(): Promise<void> {
    const moshicomId = Number(this.queryMoshicomId);
    if (isNaN(moshicomId)) {
      MessageDialogComponent.createErrorDialog(this.dialog, '半角数字を入力してください。');
      this.queryMoshicomId = '';
      return;
    }
    const event = await this.teamApiService.getMoshicomEvent(moshicomId);
    if (!event) {
      MessageDialogComponent.createErrorDialog(this.dialog, '指定したIDのイベントは見つかりませんでした。');
      return;
    }
    const isOK = await RaceConfirmDialogComponent.createRaceConfirmDialog(this.dialog, undefined, event);
    if (isOK) {
      if (this.moshicomIds.find(addedMoshicomId => addedMoshicomId === moshicomId)) {
        MessageDialogComponent.createErrorDialog(this.dialog, '既に追加済みです。');
        return;
      }
      this.moshicomIds.push(moshicomId);
      this.queryMoshicomId = '';
    }
  }

  removeMoshicomId(moshicomId: number): void {
    this.moshicomIds = this.moshicomIds.filter(val => val !== moshicomId);
  }

  async onImageChanged(): Promise<void> {
    const file: File = this.imageFileInput!.nativeElement.files[0];
    if (1024 * 1024 * 3 < file.size) {
      MessageDialogComponent.createErrorDialog(this.dialog, '画像は3MB以下にしてください。');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e1) => {
      this.imageUrl = reader.result!.toString();
    };
    reader.readAsDataURL(file);
    this.imageFile = file;
  }

  async onIconChanged(): Promise<void> {
    const file: File = this.iconFileInput!.nativeElement.files[0];
    if (1024 * 512 < file.size) {
      MessageDialogComponent.createErrorDialog(this.dialog, '画像は512KB以下にしてください。');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e1) => {
      this.iconUrl = reader.result!.toString();
    };
    reader.readAsDataURL(file);
    this.iconFile = file;
  }

  async onDeleteImageClicked(): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '背景画像を削除します。よろしいですか？');
    if (isOK) {
      this.imageFile = undefined;
      this.imageUrl = undefined;
    }
  }

  async onDeleteIconClicked(): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'アイコン画像を削除します。よろしいですか？');
    if (isOK) {
      this.iconFile = undefined;
      this.iconUrl = undefined;
    }
  }

  async create(): Promise<void> {
    if (!this.validateContract()) {
      return;
    }
    if (!this.name.value) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'チーム名を入力してください。');
      return;
    }
    if (!this.description.value) {
      MessageDialogComponent.createErrorDialog(this.dialog, '詳細を入力してください。');
      return;
    }
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog,
      'オフィシャルチームを新規登録します。\n\nこの操作では契約者メールアドレスに新しいパスワードが届きます。\n入力ミスがないか十分に確認してください。\nよろしいですか？');
    if (!isOK) {
      return;
    }

    const input: PostOfficialTeamInput = {
      name: this.name.value,
      activityType: this.activityType,
      category: this.category,
      aclPostFeed: this.aclPostFeed,
      aclPostTeamCourse: this.aclPostTeamCourse,
      aclPostTeamHistory: this.aclPostTeamHistory,
      aclCreateIntraEvent: this.aclCreateIntraEvent,
      aclInviteMember: this.aclInviteMember,
      description: this.description.value,
      paidAccount: {name: this.paidAccountName.value.trim(), email: this.paidAccountEmail.value.trim()},
      paidPlan: this.getTeamPaidPlan(),
      teamType: this.teamType,
      requiredServices: getRequiredServices(this.requiredServices),
      eventRequiredServices: this.eventRequiredServices.filter(service => service.completed)
        .map(service => service.value)
    };
    if (this.area.value) {
      input.area = this.area.value;
    }
    if (this.webLink.value) {
      input.webLink = this.webLink.value;
    }
    if (this.twitterLink.value) {
      input.twitterLink = this.twitterLink.value;
    }
    if (this.facebookLink.value) {
      input.facebookLink = this.facebookLink.value;
    }
    if (this.instagramLink.value) {
      input.instagramLink = this.instagramLink.value;
    }
    if (this.youtubeLink.value) {
      input.youtubeLink = this.youtubeLink.value;
    }
    if (this.runtesNums.length !== 0) {
      input.connectedRuntesNums = this.runtesNums;
    }
    if (this.runnetRaceIds.length !== 0) {
      input.connectedRunnetRaceIds = this.runnetRaceIds;
    }
    if (this.moshicomIds.length !== 0) {
      input.connectedMoshicomIds = this.moshicomIds;
    }
    if (input.connectedMoshicomIds || input.connectedRunnetRaceIds || input.connectedRuntesNums) {
      if (!(await MessageDialogComponent.createPositiveConfirmDialog(this.dialog,
        'エントリー自動連携機能が利用されています。この処理はやり直しが効きません。\n入力ミスがないか十分に確認してください。\nよろしいですか？'))) {
        return;
      }
    }

    const result = await this.teamApiService.postOfficialTeam(input);
    if (result === null) {
      return;
    }

    if (!!this.iconFile && !!this.imageFile) {
      await Promise.all([
        this.s3Service.putTeamIcon(result.id, this.iconFile),
        this.s3Service.putTeamImage(result.id, this.imageFile)
      ]);
    } else if (!!this.iconFile) {
      await this.s3Service.putTeamIcon(result.id, this.iconFile);
    } else if (!!this.imageFile) {
      await this.s3Service.putTeamImage(result.id, this.imageFile);
    }
    this.snackBar.open('登録しました', undefined, {
      duration: 5000
    });
    await this.back();
  }

  async promote(): Promise<void> {
    if (!this.validateContract()) {
      return;
    }
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog,
      '該当のチームをオフィシャルチームに昇格します。\n\nこの操作では契約者メールアドレスに新しいパスワードが届きます。\n入力ミスがないか十分に確認してください。\nよろしいですか？');
    if (!isOK) {
      return;
    }
    const input: PromoteOfficialTeamInput = {
      paidAccount: {name: this.paidAccountName.value.trim(), email: this.paidAccountEmail.value.trim()},
      paidPlan: this.getTeamPaidPlan(),
      teamType: this.teamType
    };
    if (this.runtesNums.length !== 0) {
      input.connectedRuntesNums = this.runtesNums;
    }
    if (this.runnetRaceIds.length !== 0) {
      input.connectedRunnetRaceIds = this.runnetRaceIds;
    }
    if (this.moshicomIds.length !== 0) {
      input.connectedMoshicomIds = this.moshicomIds;
    }
    if (input.connectedMoshicomIds || input.connectedRunnetRaceIds || input.connectedRuntesNums) {
      if (!(await MessageDialogComponent.createPositiveConfirmDialog(this.dialog,
        'エントリー自動連携機能が利用されています。この処理はやり直しが効きません。\n入力ミスがないか十分に確認してください。\nよろしいですか？'))) {
        return;
      }
    }

    const result = await this.teamApiService.promoteOfficialTeam(this.teamId!, input);

    if (result) {
      this.snackBar.open(`${this.name.value}をオフィシャルチームに昇格しました。`, undefined, {
        duration: 5000
      });
      await this.back();
    }
  }

  private getTeamPaidPlan(): TeamPaidPlan {
    return {
      additionalCapacity: this.capacity.value! - 100,
      additionalEvents: this.additionalEvents!
        .filter((additionalEvent: any) => !additionalEvent.immutable && additionalEvent.completed)
        .map((additionalEvent: any) => additionalEvent.value),
      startedAt: this.contractRange.value.startedAt!.unix() * 1000,
      expiredAt: this.contractRange.value.expiredAt!.unix() * 1000 + 1000 * 60 * 60 * 24 - 1,
      price: this.price.value!
    };
  }

  validateContract(): boolean {
    if (this.paidAccountName.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約者名を入力してください。');
      return false;
    }
    if (this.paidAccountEmail.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約者メールアドレスを入力してください。');
      return false;
    }
    if (this.paidAccountEmail.hasError('email')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約者メールアドレスの形式が不正です。');
      return false;
    }
    if (this.capacity.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '定員数を入力してください。');
      return false;
    }
    if (this.capacity.hasError('min') || this.capacity.hasError('max')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '定員数が不正です。');
      return false;
    }
    if (!this.contractRange.controls.startedAt.value ||
      this.contractRange.controls.startedAt.hasError('matStartDateInvalid')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約期間が不正です。');
      return false;
    }
    if (!this.contractRange.controls.expiredAt.value ||
      this.contractRange.controls.expiredAt.hasError('matEndDateInvalid')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約期間が不正です。');
      return false;
    }
    if (this.contractRange.controls.startedAt.value.unix() * 1000 > new Date().getTime()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約開始日に未来の日付は指定できません。');
      return false;
    }
    if (this.price.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約金額を入力してください。');
      return false;
    }
    if (this.price.hasError('min') || this.price.hasError('max')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約金額が不正です。');
      return false;
    }
    return true;
  }

  async back(): Promise<void> {
    const queryParams: any = {};
    queryParams[QUERY_PARAM_TEAM_PAGE] = TeamPage.officialTeamManage;
    await this.router.navigate(['/team'], {queryParams});
  }

}
