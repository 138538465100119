import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseComponent} from './views/theme/base/base.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import localeJa from '@angular/common/locales/ja';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MessageDialogComponent} from './views/partials/message-dialog/message-dialog.component';
import {RaceDialogComponent} from './views/partials/race-dialog/race-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {
  FinishPointDialogComponent
} from './views/partials/finish-point-table/finish-point-dialog/finish-point-dialog.component';
import {RunnerComponent} from './views/pages/runner/runner.component';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatSortModule} from '@angular/material/sort';
import {UserComponent} from './views/pages/user/user.component';
import {UserDialogComponent} from './views/pages/user/user-dialog/user-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {RaceSelectDialogComponent} from './views/partials/race-select-dialog/race-select-dialog.component';
import {ActivityComponent} from './views/pages/runner/activity/activity.component';
import {EntryComponent} from './views/pages/runner/entry/entry.component';
import {PrizeDialogComponent} from './views/partials/prizes-table/prize-dialog/prize-dialog.component';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {TocComponent} from './views/pages/help/toc/toc.component';
import {HelpComponent} from './views/pages/help/help.component';
import {ItemComponent} from './views/pages/help/item/item.component';
import {
  ActivityDetailDialogComponent
} from './views/pages/runner/activity/activity-detail-dialog/activity-detail-dialog.component';
import {NotificationDialogComponent} from './views/pages/runner/notification-dialog/notification-dialog.component';
import {AdminComponent} from './views/pages/help/admin/admin.component';
import {ClientComponent} from './views/pages/help/client/client.component';
import {SharedModule} from './shared/shared.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FeedReportComponent} from './views/pages/team/feed-report/feed-report.component';
import {FeedReportTableComponent} from './views/pages/team/feed-report/feed-report-table/feed-report-table.component';
import {
  FeedReportDialogComponent
} from './views/pages/team/feed-report/feed-report-table/feed-report-dialog/feed-report-dialog.component';
import {TeamComponent} from './views/pages/team/team.component';
import {TeamReportTableComponent} from './views/pages/team/team-report/team-report-table/team-report-table.component';
import {
  TeamReportDialogComponent
} from './views/pages/team/team-report/team-report-table/team-report-dialog/team-report-dialog.component';
import {TeamReportComponent} from './views/pages/team/team-report/team-report.component';
import {TeamManageComponent} from './views/pages/team/team-manage/team-manage.component';
import {OfficialTeamManageComponent} from './views/pages/team/official-team-manage/official-team-manage.component';
import {
  CreateOfficialTeamComponent
} from './views/pages/team/official-team-manage/create-official-team/create-official-team.component';
import {MatRadioModule} from '@angular/material/radio';
import {EditLicenseComponent} from './views/pages/team/official-team-manage/edit-license/edit-license.component';
import {
  AddLicenseDialogComponent
} from './views/pages/team/official-team-manage/edit-license/add-license-dialog/add-license-dialog.component';
import {TeamTableComponent} from './views/pages/team/official-team-manage/team-table/team-table.component';
import {
  RaceConfirmDialogComponent
} from './views/pages/team/official-team-manage/race-confirm-dialog/race-confirm-dialog.component';
import {CourseReportComponent} from './views/pages/team/course-report/course-report.component';
import {
  CourseReportTableComponent
} from './views/pages/team/course-report/course-report-table/course-report-table.component';
import {
  CourseReportDialogComponent
} from './views/pages/team/course-report/course-report-table/course-report-dialog/course-report-dialog.component';
import {ProgressSpinnerComponent} from './views/partials/progress-spinner/progress-spinner.component';
import {
  EditRaceResultDialogComponent
} from './views/pages/runner/entry/edit-race-result-dialog/edit-race-result-dialog.component';
import {
  AddRelatedAccountDialogComponent
} from './views/pages/team/official-team-manage/edit-license/add-related-account-dialog/add-related-account-dialog.component';
import {WidgetComponent} from './views/pages/widget/widget.component';
import {AdsComponent} from './views/pages/ads/ads.component';
import {EachAdSettingComponent} from './views/pages/ads/each-ad-setting/each-ad-setting.component';
import {WidgetModule} from './views/pages/widget/widget.module';

registerLocaleData(localeJa, 'ja');

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    MessageDialogComponent,
    FinishPointDialogComponent,
    RaceDialogComponent,
    RunnerComponent,
    UserComponent,
    UserDialogComponent,
    RaceSelectDialogComponent,
    ActivityComponent,
    EntryComponent,
    PrizeDialogComponent,
    TocComponent,
    HelpComponent,
    ItemComponent,
    ActivityDetailDialogComponent,
    NotificationDialogComponent,
    AdminComponent,
    ClientComponent,
    FeedReportComponent,
    FeedReportTableComponent,
    FeedReportDialogComponent,
    TeamComponent,
    TeamReportTableComponent,
    TeamReportDialogComponent,
    TeamReportComponent,
    TeamManageComponent,
    OfficialTeamManageComponent,
    CreateOfficialTeamComponent,
    EditLicenseComponent,
    AddLicenseDialogComponent,
    TeamTableComponent,
    RaceConfirmDialogComponent,
    CourseReportComponent,
    CourseReportTableComponent,
    CourseReportDialogComponent,
    ProgressSpinnerComponent,
    EditRaceResultDialogComponent,
    AddRelatedAccountDialogComponent,
    WidgetComponent,
    AdsComponent,
    EachAdSettingComponent,
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatSnackBarModule,
    NgxMaterialTimepickerModule,
    MatMomentDateModule,
    FlexLayoutModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    SharedModule, WidgetModule
  ],
  providers: [
    DatePipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
