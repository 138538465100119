import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Ad} from '../../../../core/model/ad';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {S3Service} from '../../../../core/services/s3.service';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';
import {environment} from '../../../../../environments/environment';
import {v4} from 'uuid';

@Component({
  selector: 'app-each-ad-setting',
  templateUrl: './each-ad-setting.component.html',
  styleUrls: ['./each-ad-setting.component.scss']
})
export class EachAdSettingComponent implements OnInit {

  @Input() ad: Ad | undefined;

  @Output() deletedOrder = new EventEmitter<number>();

  redirectUrl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(2048)]
  });
  analyticsLabel = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(20)]
  });

  constructor(private s3Service: S3Service,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (!this.ad) {
      return;
    }
    this.redirectUrl.setValue(this.ad.redirect_url);
    this.analyticsLabel.setValue(this.ad.analytics_label);
  }

  async uploadBanner(event: any): Promise<void> {
    if (!this.ad) {
      return;
    }
    if (event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    if (file.size >= 500 * 1024) {
      MessageDialogComponent.createErrorDialog(this.dialog, '画像サイズが大きすぎます。500KB以内に設定してください。');
      return;
    }
    if (this.ad.image_url) {
      const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '画像を入れ替えます。この操作は即時反映されます。よろしいですか？');
      if (!isOK) {
        return;
      }
    }

    const s3Key = `ads/${v4()}.png`;
    await this.s3Service.putStoreImage(s3Key, file);
    if (this.ad.image_url) {
      await this.s3Service.deleteStoreImageFromAdsUrl(this.ad.image_url);
    }
    this.ad.image_url = `${environment.publicBucketUrl}store/${s3Key}`;
  }

  onRedirectUrlChanged(): void {
    if (!this.ad) {
      return;
    }
    this.ad.redirect_url = this.redirectUrl.value;
  }

  onAnalyticsLabelChanged(): void {
    if (!this.ad) {
      return;
    }
    this.ad.analytics_label = this.analyticsLabel.value;
  }

  async delete(): Promise<void> {
    if (!this.ad) {
      return;
    }
    const isOK = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, '削除します。画像に関しては即時に削除されます。よろしいですか？');
    if (!isOK) {
      return;
    }
    // TODO 保存済みの枠を削除した後、保存せずに別ページに遷移すると画像のない枠が生まれてしまう。注意喚起はしているので許容する
    if (this.ad.image_url) {
      await this.s3Service.deleteStoreImageFromAdsUrl(this.ad.image_url);
    }
    this.deletedOrder.emit(this.ad.order);
  }


}
