<div class="container">
  <div class="header-action-container">
    <button mat-raised-button color="primary" [routerLink]="['create']">新規登録
    </button>
    <button mat-raised-button color="primary" [routerLink]="['promote']">既存チームの昇格
    </button>
  </div>

  <mat-tab-group>
    <mat-tab label="{{getTeamTypeName(TeamType.official)}}">
      <app-team-table [teamType]="TeamType.official"></app-team-table>
    </mat-tab>
    <mat-tab label="{{getTeamTypeName(TeamType.officialPrivate)}}">
      <app-team-table [teamType]="TeamType.officialPrivate"></app-team-table>
    </mat-tab>
    <mat-tab label="{{getTeamTypeName(TeamType.officialRaceCommunity)}}">
      <app-team-table [teamType]="TeamType.officialRaceCommunity"></app-team-table>
    </mat-tab>
  </mat-tab-group>


</div>
