import {Component, Inject, OnInit, Optional} from '@angular/core';
import {Authorization} from '../../../../core/model/auth/authorization';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UsersApiService} from '../../../../core/services/users-api.service';
import {AuthService} from '../../../../core/services/auth.service';
import {AuthFactory, Role} from '../../../../core/model/auth/auth-factory';
import {User} from '../../../../core/model/user';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  authorities?: Authorization[];
  selectedRole: Role | null = null;
  isAdd?: boolean;

  emailFormControl?: FormControl<string>;
  nameFormControl?: FormControl<string>;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private usersService: UsersApiService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<UserDialogComponent>,
    private dialog: MatDialog,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.authorities = AuthFactory.getAuthorizations(this.authService.getUserAuth().getRole() === Role.Developer);
    this.isAdd = this.data.isAdd;
    if (this.isAdd) {
      this.emailFormControl = new FormControl<string>('', {
        validators: [Validators.required, Validators.maxLength(100), Validators.email],
        nonNullable: true,
      });
      this.nameFormControl = new FormControl<string>('', {
        validators: [Validators.required, Validators.maxLength(100)],
        nonNullable: true,
      });
      this.selectedRole = null;
    } else {
      this.emailFormControl = new FormControl<string>({value: this.data.user.email, disabled: true}, {
        nonNullable: true,
      });
      this.nameFormControl = new FormControl<string>(this.data.user.name, {
        validators: [Validators.required, Validators.maxLength(100)],
        nonNullable: true,
      });
      this.selectedRole = this.data.user.role;
    }
  }

  getTitle(): string {
    return this.isAdd ? '追加' : '編集';
  }

  async deleteUser(): Promise<void> {
    if (this.authService.user?.email === this.emailFormControl!.value) {
      MessageDialogComponent.createErrorDialog(this.dialog, '自分自身は削除できません。');
      return;
    }
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '削除します。本当によろしいですか？');
    if (!isOK) {
      return;
    }

    await this.usersService.delete({email: this.emailFormControl!.value});
    this.dialogRef.close(true);
  }

  async saveUser(): Promise<void> {
    if (this.emailFormControl!.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'emailが入力されていません。');
      return;
    }
    if (this.emailFormControl!.hasError('maxLength')) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'emailは100文字以内で入力してください。');
      return;
    }
    if (this.emailFormControl!.hasError('email')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '正しい形式でemailを入力してください。');
      return;
    }
    if (this.nameFormControl!.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '氏名が入力されていません。');
      return;
    }
    if (this.nameFormControl!.hasError('maxLength')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '氏名は100文字以内で入力してください。');
      return;
    }
    if (!this.selectedRole) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限を選択してください。');
      return;
    }
    if (!this.isAdd && this.emailFormControl!.value === this.authService.user?.email) {
      if (this.authService.getUserAuth().getRole() === Role.Admin &&
        this.selectedRole !== Role.Admin) {
        MessageDialogComponent.createErrorDialog(this.dialog, '自分自身の管理者権限を変更することはできません。');
        return;
      }
      if (this.authService.getUserAuth().getRole() === Role.Developer &&
        this.selectedRole !== Role.Developer) {
        MessageDialogComponent.createErrorDialog(this.dialog, '自分自身の開発者権限を変更することはできません。');
        return;
      }
    }
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '保存します。よろしいですか？');
    if (!isOK) {
      return;
    }

    const user = new User(
      this.emailFormControl!.value,
      this.nameFormControl!.value,
      this.selectedRole
    );

    if (this.isAdd) {
      const res = await this.usersService.post(user);
      if (!res.success) {
        MessageDialogComponent.createErrorDialog(this.dialog, res.error || '予期せぬエラーが発生しました。');
      }
    } else {
      await this.usersService.put(user);
      if (this.authService.user?.email === this.emailFormControl!.value) {
        this.authService.user = user;
        this.authService.userAuth = AuthFactory.getAuthorization(user.role);
      }
    }
    this.dialogRef.close(true);
  }

}
