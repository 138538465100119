<div class="common-dialog">
  <div class="title">投稿内容</div>

  <ng-container *ngIf="report">
    <div class="header">投稿者</div>
    <div class="content">{{ report.feed.userNickname }}
      <ng-container *ngIf="report.feed.userId !== undefined"> (ID: {{ report.feed.userId }})</ng-container>
      <ng-container *ngIf="report.feed.userId === undefined"> (Team Adminより投稿)</ng-container>
    </div>

    <div class="header">UUID</div>
    <div class="content">{{ report.uuid }}</div>

    <ng-container *ngIf="report.feed.parentUuid">
      <div class="header">親投稿UUID</div>
      <div class="content">{{ report.feed.parentUuid }}</div>
    </ng-container>

    <ng-container *ngIf="report.feed.title">
      <div class="header">タイトル</div>
      <div class="content">{{ report.feed.title }}</div>
    </ng-container>

    <div class="header">本文</div>
    <div class="content">{{ report.feed.content }}</div>

    <div *ngIf="report.feed.photoUrls.length !== 0" class="photo-container">
      <a *ngFor="let url of report.feed.photoUrls" href="{{url}}" target="_blank">
        <img class="photo" alt="{{url}}" src="{{url}}"/>
      </a>
    </div>
  </ng-container>

  <div *ngIf="isUnanswered()" class="operation-container">
    <button class="close-button" [mat-dialog-close]="undefined">閉じる</button>
    <button class="ignore-button" [mat-dialog-close]="ViolationReportStatus.ignored">無視する</button>
    <button class="approve-button" [mat-dialog-close]="ViolationReportStatus.approved">非表示にする</button>
  </div>

  <div *ngIf="!isUnanswered()" class="close-operation-container">
    <button class="close-button" [mat-dialog-close]="undefined">閉じる</button>
  </div>
</div>
