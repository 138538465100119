import {Component, OnInit} from '@angular/core';
import {FeedApiService} from '../../../../core/services/feed-api.service';
import {FeedItemReport, ViolationReportStatus} from '../../../../core/model/resources/violation-report';

@Component({
  selector: 'app-feed-report',
  templateUrl: './feed-report.component.html',
  styleUrls: ['./feed-report.component.scss']
})
export class FeedReportComponent implements OnInit {

  unansweredReports: FeedItemReport[] = [];
  approvedReports: FeedItemReport[] = [];
  ignoredReports: FeedItemReport[] = [];

  constructor(
    private feedApiService: FeedApiService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.refreshUnansweredReports();
  }

  async selectedTabChanged(index: number): Promise<void> {
    if (index === 1) {
      await this.refreshIgnoredReports();
    } else if (index === 2) {
      await this.refreshApprovedReports();
    }
  }

  async refreshUnansweredReports(): Promise<void> {
    this.unansweredReports = await this.feedApiService.getFeedReports(ViolationReportStatus.unanswered);
  }

  async refreshApprovedReports(): Promise<void> {
    this.approvedReports = await this.feedApiService.getFeedReports(ViolationReportStatus.approved);
  }

  async refreshIgnoredReports(): Promise<void> {
    this.ignoredReports = await this.feedApiService.getFeedReports(ViolationReportStatus.ignored);
  }

  async changedReport(changedReport: FeedItemReport): Promise<void> {
    this.unansweredReports = this.unansweredReports.filter(report => report.uuid !== changedReport.uuid);
  }
}
