<div class="header-action-container">
  <mat-form-field class="search-item">
    <mat-label>Team Idで検索</mat-label>
    <input matInput type="number" [(ngModel)]="searchTeamId" (change)="searchById()">
  </mat-form-field>
  <mat-form-field class="search-item">
    <mat-label>チーム名で検索（部分一致）</mat-label>
    <input matInput type="search" [(ngModel)]="searchName" (change)="searchByName()">
  </mat-form-field>
  <mat-form-field class="search-item">
    <mat-label>契約者名で検索（部分一致）</mat-label>
    <input matInput type="search" [(ngModel)]="searchAccount" (change)="searchByAccount()">
  </mat-form-field>
  <mat-form-field class="search-item">
    <mat-label>契約者Emailで検索（部分一致）</mat-label>
    <input matInput type="search" [(ngModel)]="searchEmail" (change)="searchByEmail()">
  </mat-form-field>

  <ng-container *ngIf="!searchMode">
    <button mat-button color="primary" (click)="fetchNext()">チーム名順で{{ OFFICIAL_TEAM_LOAD_LIMIT }}件ずつを取得
    </button>
    <button mat-button color="primary" (click)="fetchAll()">全て取得
    </button>
  </ng-container>
</div>

<table mat-table matSort [dataSource]="dataSource" class="common-table mat-elevation-z8">
  <ng-container matColumnDef="teamId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="teamId">Team Id</th>
    <td mat-cell *matCellDef="let officialTeam">{{ officialTeam.id }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>チーム名</th>
    <td mat-cell *matCellDef="let officialTeam">{{ officialTeam.name }}</td>
  </ng-container>

  <ng-container matColumnDef="accountName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>契約者名</th>
    <td mat-cell *matCellDef="let officialTeam">{{ officialTeam.paidAccount.name }}</td>
  </ng-container>

  <ng-container matColumnDef="accountEmail">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>契約者Email</th>
    <td mat-cell *matCellDef="let officialTeam">{{ officialTeam.paidAccount.email }}</td>
  </ng-container>

  <ng-container matColumnDef="startedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>契約開始日</th>
    <td mat-cell class="{{getRowClassName(officialTeam)}}"
        *matCellDef="let officialTeam">{{ officialTeam.paidPlan.startedAt | date:'yyyy/M/d HH:mm' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="expiredAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>契約終了日</th>
    <td mat-cell class="{{getRowClassName(officialTeam)}}"
        *matCellDef="let officialTeam">{{ officialTeam.paidPlan.expiredAt | date:'yyyy/M/d HH:mm' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="membership">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>メンバー数</th>
    <td mat-cell *matCellDef="let officialTeam">{{ officialTeam.membership }}</td>
  </ng-container>

  <ng-container matColumnDef="capacity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>定員</th>
    <td mat-cell *matCellDef="let officialTeam">{{ officialTeam.capacity }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>ステータス</th>
    <td mat-cell *matCellDef="let officialTeam">{{ getTeamStatusName(officialTeam.status) }}</td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef>Team Admin</th>
    <td mat-cell *matCellDef="let officialTeam">
      <button mat-button (click)="loginToTeamAdmin(officialTeam)">
        <i class="material-icons">open_in_new</i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="license">
    <th mat-header-cell *matHeaderCellDef>ライセンス管理</th>
    <td mat-cell *matCellDef="let officialTeam">
      <button mat-button (click)="manageLicense(officialTeam)">
        <i class="material-icons">library_books</i>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let officialTeam; columns: displayedColumns;"></tr>
</table>

<div class="footer-action-container">
  <button mat-raised-button color="primary" (click)="exportToCSV()">表示中データをエクスポート
  </button>
</div>
