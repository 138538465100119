import {Component, OnInit, ViewChild} from '@angular/core';
import {UserDialogComponent} from './user-dialog/user-dialog.component';
import {User} from '../../../core/model/user';
import {UsersApiService} from '../../../core/services/users-api.service';
import {AuthService} from '../../../core/services/auth.service';
import {AuthFactory, Role} from '../../../core/model/auth/auth-factory';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  dataSource: MatTableDataSource<User> = new MatTableDataSource();
  displayedColumns: string[] = ['email', 'name', 'role'];
  @ViewChild(MatSort, {static: false}) sort: MatSort | null = null;

  constructor(
    private usersService: UsersApiService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadUsers();
    this.dataSource.sort = this.sort;
  }

  async loadUsers(): Promise<void> {
    let users = (await this.usersService.get()).data;
    if (this.authService.getUserAuth().getRole() !== Role.Developer) {
      users = users.filter((user: User) => user.role !== Role.Developer);
    }
    this.dataSource.data = users;
  }

  getViewRole(role: Role): string {
    return AuthFactory.getViewRole(role);
  }

  async callAddDialog(): Promise<void> {
    this.dialog.open(UserDialogComponent, {
      width: '400px',
      data: {
        isAdd: true
      }
    }).afterClosed().subscribe(async result => {
      if (result) {
        await this.loadUsers();
      }
    });
  }

  async callEditDialog(editUser: User): Promise<void> {
    this.dialog.open(UserDialogComponent, {
      width: '400px',
      data: {
        isAdd: false,
        user: editUser
      }
    }).afterClosed().subscribe(async result => {
      if (result) {
        await this.loadUsers();
      }
    });
  }
}
