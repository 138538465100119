import {Component, OnInit} from '@angular/core';
import {TeamApiService} from '../../../../core/services/team-api.service';
import {
  getTeamCategoryName,
  getTeamStatusName,
  getTeamTypeName,
  Team,
  TeamStatus
} from '../../../../core/model/resources/team';
import {getActivityTypeName} from 'src/app/core/model/resources/activity';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-team-manage',
  templateUrl: './team-manage.component.html',
  styleUrls: ['./team-manage.component.scss']
})
export class TeamManageComponent implements OnInit {

  getActivityTypeName = getActivityTypeName;
  getTeamTypeName = getTeamTypeName;
  getTeamCategoryName = getTeamCategoryName;
  getTeamStatusName = getTeamStatusName;
  TeamStatus = TeamStatus;
  teamId?: number;
  team: Team | null = null;

  constructor(
    private teamApiService: TeamApiService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  async searchByTeamId(): Promise<void> {
    if (this.teamId !== undefined) {
      this.team = await this.teamApiService.getTeam(this.teamId);
    }
  }

  async activate(): Promise<void> {
    const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog,
      'チームを強制的にアクティブ化します。\n\n' +
      '本当によろしいですか？');
    if (!isOk) {
      return;
    }
    await this.teamApiService.patchTeamStatus(this.teamId!, TeamStatus.active);
    this.team!.status = TeamStatus.active;
    MessageDialogComponent.createSuccessDialog(this.dialog, '変更しました。');
  }

  async suspend(): Promise<void> {
    const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog,
      'チームを強制的に休止します。この処理は規約違反に該当する場合や、有料プランの有効期限が切れ、支払いの確認が取れなくなった場合にのみ実行してください。\n\n' +
      '本当によろしいですか？');
    if (!isOk) {
      return;
    }
    await this.teamApiService.patchTeamStatus(this.teamId!, TeamStatus.suspended);
    this.team!.status = TeamStatus.suspended;
    MessageDialogComponent.createSuccessDialog(this.dialog, '変更しました。');
  }

  async disband(): Promise<void> {
    const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog,
      'チームを強制的に解散します。この処理は規約違反が行われた際に警告メールを送り、それでも改善が為されない場合にのみ実行してください。\n\n' +
      'この処理を取り消すことはできません。本当によろしいですか？');
    if (!isOk) {
      return;
    }
    await this.teamApiService.patchTeamStatus(this.teamId!, TeamStatus.disbanded);
    this.team!.status = TeamStatus.disbanded;
    MessageDialogComponent.createSuccessDialog(this.dialog, '変更しました。');
  }

  async delete(): Promise<void> {
    const isOk = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog,
      'チームに関連する全てのリソースを物理削除します。過去のイベント・ランキング情報等も削除されるため、誤って登録してしまったデータに対してのみ実行してください。\n\n' +
      'この処理を取り消すことはできません。本当によろしいですか？');
    if (!isOk) {
      return;
    }
    await this.teamApiService.deleteTeam(this.teamId!);
    this.team = null;
    MessageDialogComponent.createSuccessDialog(this.dialog, '削除しました。');
  }

}
