import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RuntesRace} from '../../../../../core/model/resources/runtes';
import {MoshicomEvent} from '../../../../../core/model/resources/moshicom';

@Component({
  selector: 'app-race-confirm-dialog',
  templateUrl: './race-confirm-dialog.component.html',
  styleUrls: ['./race-confirm-dialog.component.scss']
})
export class RaceConfirmDialogComponent implements OnInit {

  runtesDisplayedColumns = ['id', 'title', 'start', 'end'];
  moshicomDisplayedColumns = ['title', 'start', 'end', 'url'];

  static async createRaceConfirmDialog(dialog: MatDialog, runtesRaces?: RuntesRace[], moshicomEvent?: MoshicomEvent): Promise<boolean> {
    return await dialog.open(RaceConfirmDialogComponent, {
      width: '600px',
      maxWidth: '600px',
      data: {
        runtesRaces,
        moshicomEvent,
      }
    }).afterClosed().toPromise();
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { runtesRaces?: RuntesRace[], moshicomEvent?: MoshicomEvent },
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RaceConfirmDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

}
