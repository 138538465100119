<div class="common-dialog">
  <h1 mat-dialog-title>追加</h1>
  <div class="content">
    <div>
      <mat-form-field *ngIf="nameFormControl" class="item">
        <mat-label>アカウント名</mat-label>
        <input matInput maxlength="100" [formControl]="nameFormControl">
        <mat-hint align="end">{{ nameFormControl.value?.length || 0 }}/254</mat-hint>
        <mat-error *ngIf="nameFormControl.hasError('required')">
          アカウント名を入力してください。
        </mat-error>
        <mat-error *ngIf="nameFormControl.hasError('maxLength')">
          アカウント名が長すぎます。
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field *ngIf="emailFormControl" class="item">
        <mat-label>e-mail</mat-label>
        <input matInput type="email" maxlength="100" [formControl]="emailFormControl">
        <mat-hint align="end">{{ emailFormControl.value?.length || 0 }}/254</mat-hint>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          e-mailを入力してください。
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('maxLength')">
          e-mailが長すぎます。
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('email')">
          e-mailの形式が不正です。
        </mat-error>
      </mat-form-field>
    </div>
    <div class="item">
      <mat-label>権限</mat-label>
      <mat-select [(value)]="selectedRole">
        <mat-option [value]="TeamMemberRole.admin">管理者</mat-option>
        <mat-option *ngIf="allowAmbassador()" [value]="TeamMemberRole.ambassador">アンバサダー</mat-option>
      </mat-select>
    </div>
  </div>

  <div mat-dialog-actions class="operation-container">
    <button mat-raised-button [mat-dialog-close]="false">閉じる</button>
    <button mat-raised-button color="primary" (click)="addUser()">追加</button>
  </div>
</div>
