<div class="container">
  <mat-form-field appearance="fill" class="search-activity-term">
    <mat-label>検索期間（開始日時）</mat-label>
    <mat-date-range-input [formGroup]="beginningAtRange" [rangePicker]="beginningAtPicker">
      <input matStartDate formControlName="from" placeholder="Start date">
      <input matEndDate formControlName="to" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="beginningAtPicker"></mat-datepicker-toggle>
    <mat-date-range-picker #beginningAtPicker></mat-date-range-picker>
    <mat-error *ngIf="beginningAtRange.controls.from.hasError('matStartDateInvalid')">Invalid start date
    </mat-error>
    <mat-error *ngIf="beginningAtRange.controls.to.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
  <button mat-raised-button color="primary" class="search-button"
          (click)="searchActivity()">検索
  </button>
</div>

<table mat-table [dataSource]="dataSource" matSort class="common-table mat-elevation-z1">
  <ng-container matColumnDef="activity_type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>アクティビティ種別</th>
    <td mat-cell *matCellDef="let activity">{{getActivityTypeName(activity.activity_type)}}</td>
    <td mat-footer-cell *matFooterCellDef>Total</td>
  </ng-container>
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>ネットタイム</th>
    <td mat-cell *matCellDef="let activity">{{UtilService.getHMS(activity.time)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotalNetTime()}}</td>
  </ng-container>
  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>距離（km）</th>
    <td mat-cell *matCellDef="let activity">{{UtilService.round(activity.distance, 2)}} </td>
    <td mat-footer-cell *matFooterCellDef>{{getTotalDistance()}}</td>
  </ng-container>
  <ng-container matColumnDef="pace">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>平均ペース(hms/km)</th>
    <td mat-cell *matCellDef="let activity">{{UtilService.getHMS(activity.pace)}} </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="climb_up">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>標高(Up)</th>
    <td mat-cell *matCellDef="let activity">{{activity.climb_up}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotalClimbUp()}}</td>
  </ng-container>
  <ng-container matColumnDef="climb_down">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>標高(Down)</th>
    <td mat-cell *matCellDef="let activity">{{activity.climb_down}} </td>
    <td mat-footer-cell *matFooterCellDef>{{getTotalClimbDown()}}</td>
  </ng-container>
  <ng-container matColumnDef="activity_source">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>計測方法</th>
    <td mat-cell *matCellDef="let activity">{{getActivitySourceName(activity.activity_source)}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="registered_manual">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>手入力フラグ</th>
    <td mat-cell *matCellDef="let activity">{{UtilService.getCheckString(activity.registered_manual)}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="beginning_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>開始日時</th>
    <td mat-cell *matCellDef="let activity">{{activity.beginning_at | date:'yyyy/M/d HH:mm:ss'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="end_of_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>終了日時</th>
    <td mat-cell *matCellDef="let activity">{{activity.end_of_at | date:'yyyy/M/d HH:mm:ss'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>登録日時</th>
    <td mat-cell *matCellDef="let activity">{{activity.created_at | date:'yyyy/M/d HH:mm:ss'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>編集日時</th>
    <td mat-cell *matCellDef="let activity">{{activity.updated_at | date:'yyyy/M/d HH:mm:ss'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="deleted_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>削除日時</th>
    <td mat-cell *matCellDef="let activity">{{activity.deleted_at | date:'yyyy/M/d HH:mm:ss'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="is_cheat">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>チーター</th>
    <td mat-cell *matCellDef="let activity">{{UtilService.getCheckString(activity.is_cheater)}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="cheat_type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>不正種別</th>
    <td mat-cell *matCellDef="let activity">{{getCheatTypeName(activity.cheat_type)}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="operation">
    <th mat-header-cell *matHeaderCellDef>操作</th>
    <td mat-cell *matCellDef="let activity">
      <button mat-button (click)="showDetailDialog(activity)">詳細
      </button>
      <button mat-button (click)="showCheatSettingDialog(activity)">チーター設定</button>
    </td>
    <td mat-footer-cell *matFooterCellDef>{{dataSource?.data?.length || 0}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="activityDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let activity; columns: activityDisplayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="activityDisplayedColumns" class="table-footer"></tr>
</table>
