import {Injectable} from '@angular/core';
import {ApiHelperService, ApiResult} from './api-helper.service';
import {API} from 'aws-amplify';
import {OverlayService} from './overlay.service';
import {MessageDialogComponent} from '../../views/partials/message-dialog/message-dialog.component';
import {AuthService} from './auth.service';
import {MatDialog} from '@angular/material/dialog';
import {RunnetRaceResult} from "../model/resources/race-result";

@Injectable({
  providedIn: 'root'
})
export class RunnersApiService {

  constructor(
    private apiHelperService: ApiHelperService,
    private authService: AuthService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
  ) {
  }

  async getFromUserId(userId: number): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowViewRunner()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }
    return await this._get({user_id: String(userId)});
  }

  async getFromRunnetId(runnetId: number): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowViewRunner()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }
    return await this._get({runnet_id: String(runnetId)});
  }

  private async _get(getProfileInput: GetProfileInput): Promise<ApiResult> {
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      queryStringParameters: getProfileInput
    };

    try {
      const result = await this.overlayService.call(API.get(this.apiHelperService.apiName, 'runners', init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async getUserRaces(userId: number): Promise<ApiResult> {
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      queryStringParameters: {userId}
    };

    try {
      const result = await this.overlayService.call(API.get(this.apiHelperService.apiName, 'runners/races', init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async banAccount(userId: number): Promise<ApiResult> {
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers
    };

    try {
      const result = await this.overlayService.call(API.patch(this.apiHelperService.apiName, `runners/${userId}`, init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async patchUserRaces(userRaceId: number, isCheater: boolean): Promise<ApiResult> {
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      body: {is_cheater: isCheater} as PatchUserRaceInput
    };

    try {
      const result = await this.overlayService.call(API.patch(this.apiHelperService.apiName, `runners/races/${userRaceId}`, init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async patchRaceResult(raceResultId: number, runnetRaceResult: RunnetRaceResult): Promise<ApiResult> {
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      body: runnetRaceResult
    };

    try {
      const result = await this.overlayService.call(API.patch(this.apiHelperService.apiName, `runners/races/results/${raceResultId}`, init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async patchActivity(activityId: number, isCheater: boolean): Promise<ApiResult> {
    const headers = await this.apiHelperService.getBasicApiHeader();
    const init = {
      headers,
      body: {is_cheater: isCheater} as PatchActivityInput
    };

    try {
      const result = await this.overlayService.call(API.patch(this.apiHelperService.apiName, `activities/${activityId}`, init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }

  async postNotification(notificationType: NotificationType, userId: number, message: string, targetId?: number): Promise<ApiResult> {
    if (!this.authService.userAuth?.isAllowSendPushForDebug()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return this.apiHelperService.getErrorResultByResponse(undefined);
    }

    const headers = await this.apiHelperService.getBasicApiHeader();
    const input: PostNotificationInput = {
      notification_type: notificationType,
      user_id: userId,
      message
    };
    const init = {
      headers,
      body: input
    };
    if (notificationType === NotificationType.NOTIFY_COUPON) {
      input.coupon_id = targetId;
    } else if (notificationType === NotificationType.NOTIFY_INFORMATION) {
      input.information_id = targetId;
    } else if (notificationType === NotificationType.NOTIFY_RANKING ||
      notificationType === NotificationType.NOTIFY_RACE_IMPORT ||
      notificationType === NotificationType.NOTIFY_EVENT_IMPORT) {
      input.race_id = targetId;
    }
    try {
      const result = await this.overlayService.call(API.post(this.apiHelperService.apiName, `runners/notification`, init));
      return this.apiHelperService.getSuccessResultByResponse(result);
    } catch (e: any) {
      return this.apiHelperService.getErrorResultByResponse(e);
    }
  }
}

export interface GetProfileInput {
  user_id?: string;
  runnet_id?: string;
}

export interface PatchUserRaceInput {
  is_cheater: boolean;
}

export interface PatchActivityInput {
  is_cheater: boolean;
}

export interface PostNotificationInput {
  notification_type: NotificationType;
  user_id: number;
  message: string;
  race_id?: number;
  coupon_id?: number;
  information_id?: number;
}

export enum NotificationType {
  NOTIFY_COUPON,
  NOTIFY_INFORMATION,
  NOTIFY_RANKING,
  NOTIFY_GARMIN_IMPORT,
  NOTIFY_NEORUN_IMPORT,
  NOTIFY_SILENTLY,
  NOTIFY_RACE_IMPORT,
  NOTIFY_EVENT_IMPORT,
}

export enum PrivacyStatus {
  PUBLIC = 0,
  FRIENDS = 1,
  PRIVATE = 2,
}

export interface ApiOutputUser {
  user_id: number;
  is_runnet_user: boolean;
  runnet_user_index: number | undefined;
  nickname: string | undefined;
  birthdate: string | null | undefined;
  gender: number | null | undefined;
  pref_name: string | null | undefined;
  weight: number;
  height: number;
  profile: string | undefined;
  picture_small_url: string | null;
  show_birthdate: boolean;
  show_gender: boolean;
  activity_privacy_status: PrivacyStatus | undefined;
  show_ranking: boolean;
  entries_privacy_status: PrivacyStatus | undefined;
  activity_map_privacy_status: PrivacyStatus | undefined;
  show_pref: boolean;
  linked_to_garmin: boolean;
  garmin_last_authenticated_at: number | undefined;
  linked_to_polar: boolean;
  polar_last_authenticated_at: number | undefined;
  oldest_data: number;
  newest_data: number;
  user_status: UserStatus;
  device_list: Device[];
}

export enum UserStatus {
  Usual = 0,
  ForceVeryStrict = 1,
  Ban = 2,
}

export enum DeviceType {
  unknown = 0, ios = 1, android = 2
}

export interface Device {
  device_uuid: string;
  device_type: DeviceType;
  security_token_expires_at: number;
  created_at: number;
  updated_at: number;
}
