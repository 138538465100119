import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  // tocの初期化タイミング調整のため必要
  selectedIndex = 0;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const helpIndex: string = this.route.snapshot.queryParams.helpIndex;
    if (!!helpIndex) {
      this.selectedIndex = Number(helpIndex);
    }
  }

}
