import {Component, OnInit} from '@angular/core';
import {
  ApiOutputUser,
  Device,
  DeviceType,
  PrivacyStatus,
  RunnersApiService,
  UserStatus
} from '../../../core/services/runners-api.service';
import {UtilService} from '../../../core/services/util.service';
import {MatTableDataSource} from '@angular/material/table';
import {Activity} from '../../../core/model/resources/activity';
import {EntryRace} from './entry/entry.component';
import {AuthService} from '../../../core/services/auth.service';
import {NotificationDialogComponent} from './notification-dialog/notification-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BatchApiService} from '../../../core/services/batch-api.service';
import {MessageDialogComponent} from '../../partials/message-dialog/message-dialog.component';

@Component({
  selector: 'app-runner',
  templateUrl: './runner.component.html',
  styleUrls: ['./runner.component.scss']
})
export class RunnerComponent implements OnInit {

  UserStatus = UserStatus;
  searchUserId?: number;
  searchRunnetId?: number;
  user?: ApiOutputUser;
  activityDataSource: MatTableDataSource<Activity> = new MatTableDataSource();
  raceDataSource: MatTableDataSource<EntryRace> = new MatTableDataSource();
  deviceDataSource: MatTableDataSource<Device> = new MatTableDataSource();
  displayedColumns = ['uuid', 'deviceType', 'createdAt', 'updatedAt', 'expiresAt'];

  constructor(
    private runnersApiService: RunnersApiService,
    private batchApiService: BatchApiService,
    public authService: AuthService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  async searchByTattaId(): Promise<void> {
    if (this.searchUserId === undefined || this.searchUserId === null) {
      return;
    }

    this.searchRunnetId = undefined;
    const res = await this.runnersApiService.getFromUserId(this.searchUserId);
    if (res.success) {
      this.user = res.data;
      this.activityDataSource.data = [];
      this.raceDataSource.data = [];
      this.deviceDataSource.data = this.user!.device_list;
    } else {
      this.user = undefined;
    }
  }

  async searchByRunnetId(): Promise<void> {
    if (this.searchRunnetId === undefined || this.searchRunnetId === null) {
      return;
    }

    this.searchUserId = undefined;
    const res = await this.runnersApiService.getFromRunnetId(this.searchRunnetId);
    if (res.success) {
      this.user = res.data;
      this.activityDataSource.data = [];
      this.raceDataSource.data = [];
      this.deviceDataSource.data = this.user!.device_list;
    } else {
      this.user = undefined;
    }
  }

  async showNotificationDialog(): Promise<void> {
    await NotificationDialogComponent.open(this.dialog, this.user!.user_id);
  }

  async invokeRunnetSyncForEachUser(): Promise<void> {
    if (!this.user?.is_runnet_user) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'RUNNET未連携のユーザーには実行できません。');
      return;
    }

    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'RUNNETとの再同期を行います（エントリー履歴、大会結果含む）。\nこの処理はRUNNET側でアカウントの統合を行った際にのみ利用します。\n\nよろしいですか？');
    if (!isOK) {
      return;
    }

    await this.batchApiService.invokeRunnetSyncForEachUser({userId: this.user.user_id, isSyncAll: true}, true);
    await MessageDialogComponent.createSuccessDialog(this.dialog, '起動しました。しばらくお待ちください。');
  }

  async invokeReCalcStatistics(): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '統計情報の再計算を行います。よろしいですか？');
    if (!isOK) {
      return;
    }

    await this.batchApiService.invokeReCalcStatistics({userId: this.user!.user_id});
    await MessageDialogComponent.createSuccessDialog(this.dialog, '起動しました。しばらくお待ちください。');
  }

  async invokeCheckCheatStrictly(): Promise<void> {
    const isOK = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, '過去の全てのアクティビティまで遡り、厳密な不正判定を実施します。\n' +
      'また、今後全てのアクティビティは厳密に不正判定が行われるようになります。\n\n' +
      'この処理はやり直しができません。明らかに不正を行っている悪質なユーザーのみ対象として実行してください。よろしいですか？');
    if (!isOK) {
      return;
    }

    await this.batchApiService.invokeCheckCheatStrictly({userId: this.user!.user_id}, true);
    await MessageDialogComponent.createSuccessDialog(this.dialog, '起動しました。しばらくお待ちください。');
  }

  async banAccount(): Promise<void> {
    const isOK = await MessageDialogComponent.createNegativeConfirmDialog(this.dialog, 'このアカウントを停止します。\n' +
      '以降はこのユーザーはTATTAを利用できなくなります。\n\n' +
      'この処理はやり直しができません。「チートチェック起動」では対処できない悪質なユーザーのみを対象とし、実施の際には法的トラブルに発展しないよう、必ず上長の同意を得てください。');
    if (!isOK) {
      return;
    }

    await this.runnersApiService.banAccount(this.user!.user_id);
    this.user!.user_status = UserStatus.Ban;
    await MessageDialogComponent.createSuccessDialog(this.dialog, 'アカウントを停止しました。');
  }

  /**
   *
   * @param birthDate e.g. 1980-01-01
   */
  calcAge(birthDate: string | null | undefined): string {
    if (!birthDate) {
      return '未設定';
    }
    return UtilService.calcAge(birthDate);
  }

  getGenderStr(gender: number | null | undefined): string {
    if (!gender) {
      return '未設定';
    } else if (gender === 1) {
      return '男性';
    } else {
      return '女性';
    }
  }

  getPublicStrFromBool(val: boolean): string {
    return val ? '公開' : '非公開';
  }

  getPublicStrFromPrivacyStatus(val: PrivacyStatus | undefined): string {
    switch (val) {
      case PrivacyStatus.PUBLIC:
        return '公開';
      case PrivacyStatus.FRIENDS:
        return '友だちのみ公開';
      case PrivacyStatus.PRIVATE:
        return '非公開';
      default:
        return '設定エラー';
    }
  }

  getDeviceType(type: DeviceType): string {
    switch (type) {
      case DeviceType.android:
        return 'Android';
      case DeviceType.ios:
        return 'iOS';
      case DeviceType.unknown:
      default:
        return 'Unknown';
    }
  }

}
