import {Component, Inject, OnInit, Optional} from '@angular/core';
import {PostRelatedAccountInput, TeamApiService} from '../../../../../../core/services/team-api.service';
import {FormControl, Validators} from '@angular/forms';
import {TeamMemberRole, TeamRelatedAccount, TeamType} from '../../../../../../core/model/resources/team';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MessageDialogComponent} from '../../../../../partials/message-dialog/message-dialog.component';
import {UsersApiService} from '../../../../../../core/services/users-api.service';
import {User} from '../../../../../../core/model/user';

@Component({
  selector: 'app-add-related-account-dialog',
  templateUrl: './add-related-account-dialog.component.html',
  styleUrls: ['./add-related-account-dialog.component.scss']
})
export class AddRelatedAccountDialogComponent implements OnInit {

  TeamMemberRole = TeamMemberRole;

  selectedRole: TeamMemberRole.admin | TeamMemberRole.ambassador | null = null;

  emailFormControl: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.minLength(1), Validators.maxLength(254), Validators.email],
    nonNullable: true,
  });
  nameFormControl: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.email, Validators.minLength(1), Validators.maxLength(254)],
    nonNullable: true,
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: {
      teamId: number,
      relatedAccounts: TeamRelatedAccount[],
      paidAccountEmail: string,
      teamType: TeamType
    },
    private teamApiService: TeamApiService,
    private usersApiService: UsersApiService,
    private dialogRef: MatDialogRef<AddRelatedAccountDialogComponent>,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  allowAmbassador(): boolean {
    return this.data.teamType === TeamType.official || this.data.teamType === TeamType.officialRaceCommunity;
  }

  async addUser(): Promise<void> {
    if (this.nameFormControl!.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'アカウント名が入力されていません。');
      return;
    }
    if (this.nameFormControl.hasError('maxLength')) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'アカウント名は254文字以内で入力してください。');
      return;
    }
    if (this.emailFormControl.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'e-mailが入力されていません。');
      return;
    }
    if (this.emailFormControl.hasError('maxLength')) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'e-mailは254文字以内で入力してください。');
      return;
    }
    if (this.emailFormControl.hasError('email')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '正しい形式でe-mailを入力してください。');
      return;
    }
    if (this.emailFormControl.value === this.data.paidAccountEmail) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'e-mailに契約者メールアドレスと同じ値は指定できません。');
      return;
    }
    if (this.data.relatedAccounts.find(account => account.email === this.emailFormControl.value)) {
      MessageDialogComponent.createErrorDialog(this.dialog, '既に登録されているe-mailです。');
      return;
    }
    if (!this.selectedRole) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限を選択してください。');
      return;
    }
    const tattaAdminUsers: User[] = (await this.usersApiService.get()).data;
    if (tattaAdminUsers.find(tattaAdminUser => tattaAdminUser.email === this.emailFormControl.value)) {
      MessageDialogComponent.createErrorDialog(this.dialog, 'TATTA Adminユーザーとして登録されているe-mailです。');
      return;
    }
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, `指定したアカウントへ、このチームへのアクセス権限を追加します。\n他チームへのアクセス権限が無い場合、アカウントが新規作成され、宛先にアカウント情報が記載されたメールが送信されます。\n入力ミスがないか十分に確認してください。\n\nよろしいですか？`);
    if (!isOK) {
      return;
    }

    const input: PostRelatedAccountInput = {
      account: {
        email: this.emailFormControl.value,
        name: this.nameFormControl.value,
        role: this.selectedRole,
      }
    };

    const res = await this.teamApiService.postRelatedAccount(this.data.teamId, input);
    if (!res) {
      MessageDialogComponent.createErrorDialog(this.dialog, '予期せぬエラーが発生しました。');
      return;
    }
    this.dialogRef.close(true);
  }
}
