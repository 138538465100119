import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

export const QUERY_PARAM_TEAM_PAGE = 'page';

export enum TeamPage {
  teamManage,
  officialTeamManage,
  teamReport,
  feedReport,
  courseReport,
}

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  TeamPage = TeamPage;
  selectedIndex: number = 0;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const page: string | null = this.route.snapshot.queryParamMap.get(QUERY_PARAM_TEAM_PAGE);
    if (page !== null) {
      const pageNum: number = Number(page);
      if (!isNaN(pageNum)) {
        this.selectedIndex = pageNum;
      }
    }
  }

  selectedTabChanged(index: number): void {
    this.selectedIndex = index;
  }

}
