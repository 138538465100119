<mat-tab-group (selectedIndexChange)="selectedTabChanged($event)">
  <mat-tab label="未処理">
    <div class="header-action-container">
      <button mat-raised-button (click)="refreshUnansweredReports()">更新</button>
    </div>
    <app-feed-report-table [reports]="unansweredReports"
                           (changedReport)="changedReport($event)"></app-feed-report-table>
  </mat-tab>
  <mat-tab label="無視">
    <div class="header-action-container">
      <button mat-raised-button (click)="refreshIgnoredReports()">更新</button>
    </div>
    <app-feed-report-table [reports]="ignoredReports"></app-feed-report-table>
  </mat-tab>
  <mat-tab label="違反認定">
    <div class="header-action-container">
      <button mat-raised-button (click)="refreshApprovedReports()">更新</button>
    </div>
    <app-feed-report-table [reports]="approvedReports"></app-feed-report-table>
  </mat-tab>
</mat-tab-group>
