<h2 mat-dialog-title>PUSH通知</h2>

<mat-dialog-content class="mat-typography">
  <span
    class="warning">※ 本機能は開発者専用のデバッグ用機能です。ユーザー個別にPUSH通知を行うことは基本的にありません。扱いには十分注意してください。</span>

  <mat-form-field class="item">
    <mat-label>通知種別</mat-label>
    <mat-select [(ngModel)]="notificationType">
      <mat-option [value]="NotificationType.NOTIFY_COUPON">クーポン通知</mat-option>
      <mat-option [value]="NotificationType.NOTIFY_INFORMATION">お知らせ通知</mat-option>
      <mat-option [value]="NotificationType.NOTIFY_RANKING">ランキング集計結果通知</mat-option>
      <mat-option [value]="NotificationType.NOTIFY_GARMIN_IMPORT">Garmin取り込み通知</mat-option>
      <mat-option [value]="NotificationType.NOTIFY_RACE_IMPORT">大会取り込み通知</mat-option>
      <mat-option [value]="NotificationType.NOTIFY_EVENT_IMPORT">イベント取り込み通知</mat-option>
      <mat-option [value]="NotificationType.NOTIFY_SILENTLY">サイレント通知</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="item">
    <mat-label>本文</mat-label>
    <textarea matInput maxlength="100" [formControl]="textControl"></textarea>
    <mat-error *ngIf="textControl.hasError('required')">
      本文を入力してください。
    </mat-error>
  </mat-form-field>

  <mat-form-field
    *ngIf="notificationType === NotificationType.NOTIFY_COUPON ||
       notificationType === NotificationType.NOTIFY_INFORMATION ||
       notificationType === NotificationType.NOTIFY_RANKING ||
       notificationType === NotificationType.NOTIFY_RACE_IMPORT ||
       notificationType === NotificationType.NOTIFY_EVENT_IMPORT"
    class="item">
    <mat-label>{{ getIdText() }}</mat-label>
    <input matInput type="number" [formControl]="idControl">
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions class="operation-container">
  <button mat-button [mat-dialog-close]="true">閉じる</button>
  <button mat-raised-button color="primary" (click)="send()">送信する</button>
</mat-dialog-actions>

