<div *ngIf="ad" class="ad-container">
  <div class="ad-header">
    <h3 class="item">{{ ad.order }}</h3>
    <button mat-button color="warn" class="btn" (click)="delete()">削除<i
      class="material-icons">delete</i></button>
  </div>
  <div class="item">
    <div>
      <div *ngIf="ad.image_url">
        <a [href]="ad.image_url" target="_blank"><img src="{{ad.image_url}}" width="300" alt="バナー"/></a>
      </div>
      <input type="file" (change)="uploadBanner($event)" placeholder="Upload banner file" accept=".png">
    </div>
  </div>

  <mat-form-field class="item">
    <mat-label>遷移先URL</mat-label>
    <input matInput required type="text" maxlength="2048"
           [formControl]="redirectUrl" (change)="onRedirectUrlChanged()">
    <mat-hint align="end">{{ redirectUrl.value?.length || 0 }}/2048</mat-hint>
    <mat-error *ngIf="redirectUrl.hasError('required')">
      遷移先URLを入力してください。
    </mat-error>
    <mat-error *ngIf="redirectUrl.hasError('maxlength')">
      入力した文字列が長すぎます。
    </mat-error>
  </mat-form-field>

  <mat-form-field class="item">
    <mat-label>Analyticsラベル</mat-label>
    <input matInput required type="text" maxlength="20"
           [formControl]="analyticsLabel" (change)="onAnalyticsLabelChanged()">
    <mat-hint align="end">{{ analyticsLabel.value?.length || 0 }}/20</mat-hint>
    <mat-error *ngIf="analyticsLabel.hasError('required')">
      Analyticsラベルを入力してください。
    </mat-error>
    <mat-error *ngIf="analyticsLabel.hasError('maxlength')">
      入力した文字列が長すぎます。
    </mat-error>
  </mat-form-field>

</div>
