import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TeamApiService} from '../../../../../../core/services/team-api.service';
import {OfficialTeam, TeamPaidPlan} from '../../../../../../core/model/resources/team';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {getRaceRankingMethodName, RaceRankingMethod} from '../../../../../../core/model/resources/race';
import {MessageDialogComponent} from '../../../../../partials/message-dialog/message-dialog.component';
import {Moment} from 'moment';

@Component({
  selector: 'app-add-license-dialog',
  templateUrl: './add-license-dialog.component.html',
  styleUrls: ['./add-license-dialog.component.scss']
})
export class AddLicenseDialogComponent implements OnInit {

  minCapacity = 100;
  capacity: FormControl<number | null> = new FormControl<number | null>(100, {
    validators: [Validators.required, Validators.max(999999)],
    nonNullable: false,
  });
  additionalEvents?: { name: string, value: RaceRankingMethod, completed: boolean, immutable: boolean }[];
  contractRange = new FormGroup<{
    startedAt: FormControl<Moment | null>,
    expiredAt: FormControl<Moment | null>
  }>({
    startedAt: new FormControl<Moment | null>(null),
    expiredAt: new FormControl<Moment | null>(null)
  });
  price: FormControl<number | null> = new FormControl<number | null>(0, {
    validators: [Validators.required, Validators.min(0), Validators.max(999999999)],
    nonNullable: false,
  });

  static async open(dialog: MatDialog, team: OfficialTeam): Promise<TeamPaidPlan | null> {
    return await dialog.open(AddLicenseDialogComponent, {
      width: '60vw',
      data: {
        team
      }
    }).afterClosed().toPromise();
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { team: OfficialTeam },
    private teamApiService: TeamApiService,
    private dialogRef: MatDialogRef<AddLicenseDialogComponent>,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    // 定員は下げられないため、新規契約でも前回の値を引き継いだ値を初期値とする
    this.minCapacity = this.data.team.paidPlan.additionalCapacity + 100;
    this.capacity.setValidators(Validators.min(this.minCapacity));
    this.capacity.setValue(this.minCapacity);
    // additionalEventsも基本的には変更されない想定のため以前の契約を初期としておく
    this.additionalEvents = [
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByDistance),
        value: RaceRankingMethod.RankingByDistance,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByTime),
        value: RaceRankingMethod.RankingByTime,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByArea),
        value: RaceRankingMethod.RankingByArea,
        completed: true,
        immutable: true
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByDistanceChallenge),
        value: RaceRankingMethod.RankingByDistanceChallenge,
        completed: !!this.data.team.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingByDistanceChallenge),
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByCalorieChallenge),
        value: RaceRankingMethod.RankingByCalorieChallenge,
        completed: !!this.data.team.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingByCalorieChallenge),
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingByGroupDistance),
        value: RaceRankingMethod.RankingByGroupDistance,
        completed: !!this.data.team.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingByGroupDistance),
        immutable: false
      },
      {
        name: getRaceRankingMethodName(RaceRankingMethod.RankingBySeriesEvent),
        value: RaceRankingMethod.RankingBySeriesEvent,
        completed: !!this.data.team.paidPlan.additionalEvents.find(additionalEvent =>
          additionalEvent === RaceRankingMethod.RankingBySeriesEvent),
        immutable: false
      }
    ];
  }

  async addLicense(): Promise<void> {
    if (!this.validateLicense()) {
      return;
    }
    const paidPlan: TeamPaidPlan = {
      additionalCapacity: this.capacity.value! - 100,
      additionalEvents: this.additionalEvents!.filter(additionalEvent => !additionalEvent.immutable && additionalEvent.completed)
        .map(additionalEvent => additionalEvent.value),
      startedAt: this.contractRange.value.startedAt!.unix() * 1000,
      expiredAt: this.contractRange.value.expiredAt!.unix() * 1000 + 1000 * 60 * 60 * 24 - 1,
      price: this.price.value!
    };
    await this.teamApiService.postLicense(this.data.team.id, {paidPlan});

    this.dialogRef.close(paidPlan);
  }

  validateLicense(): boolean {
    if (this.capacity.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '定員数を入力してください。');
      return false;
    }
    if (this.capacity.hasError('min') || this.capacity.hasError('max')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '定員数が不正です。');
      return false;
    }
    if (!this.contractRange.controls.startedAt.value ||
      this.contractRange.controls.startedAt.hasError('matStartDateInvalid')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約期間が不正です。');
      return false;
    }
    if (!this.contractRange.controls.expiredAt.value ||
      this.contractRange.controls.expiredAt.hasError('matEndDateInvalid')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約期間が不正です。');
      return false;
    }
    if (this.contractRange.controls.startedAt.value.unix() * 1000 > new Date().getTime()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約開始日に未来の日付は指定できません。');
      return false;
    }
    if (this.price.hasError('required')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約金額を入力してください。');
      return false;
    }
    if (this.price.hasError('min') || this.price.hasError('max')) {
      MessageDialogComponent.createErrorDialog(this.dialog, '契約金額が不正です。');
      return false;
    }
    return true;
  }

}
