import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EntryRace} from '../entry.component';
import {RunnersApiService} from '../../../../../core/services/runners-api.service';
import {
  RaceResult,
  RaceResultRank,
  RunnetRaceResult
} from '../../../../../core/model/resources/race-result';
import {UtilService} from '../../../../../core/services/util.service';
import {MessageDialogComponent} from '../../../../partials/message-dialog/message-dialog.component';

@Component({
  selector: 'app-edit-race-result-dialog',
  templateUrl: './edit-race-result-dialog.component.html',
  styleUrls: ['./edit-race-result-dialog.component.scss']
})
export class EditRaceResultDialogComponent implements OnInit {

  raceResults: RaceResult[];
  getHMS = UtilService.getHMS;

  static async createDialog(dialog: MatDialog, entryRace: EntryRace): Promise<boolean> {
    return await dialog.open(EditRaceResultDialogComponent, {
      width: '600px',
      maxWidth: '600px',
      data: {
        entryRace
      }
    }).afterClosed().toPromise();
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { entryRace: EntryRace },
    private runnerApiService: RunnersApiService,
    private dialogRef: MatDialogRef<EditRaceResultDialogComponent>,
    private dialog: MatDialog,
  ) {
    this.raceResults = data.entryRace.entry.raceResults!;
    console.log(this.raceResults);
  }

  ngOnInit(): void {
  }

  getRankStr(raceResultRank: RaceResultRank): string {
    if (raceResultRank.population === undefined) {
      return String(raceResultRank.rank);
    }
    return `${raceResultRank.rank} / ${raceResultRank.population}`;
  }

  async editResult(raceResultId: number, runnetRaceResult: RunnetRaceResult): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'この記録を公式記録として扱います。\n本機能は同姓同名の方の記録を誤って取り込んでしまった場合に利用するものです。\n\n本当によろしいですか？');
    if (!isOK) {
      return;
    }

    const res = await this.runnerApiService.patchRaceResult(raceResultId, runnetRaceResult);
    if (res.success) {
      MessageDialogComponent.createSuccessDialog(this.dialog, `変更しました。`);
      this.dialogRef.close(true);
    } else {
      MessageDialogComponent.createErrorDialog(this.dialog, res.error!);
    }
  }

}
