import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../core/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isOpenSideNav = true;
  helpIndex = 0;
  title = environment.title;
  baseColor = environment.baseColor;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    const isOpenSideNav: string = this.route.snapshot.queryParams.isOpenSideNav;
    if (isOpenSideNav === 'false') {
      this.isOpenSideNav = false;
    }
    const helpIndex: string = this.route.snapshot.queryParams.helpIndex;
    if (!!helpIndex) {
      this.helpIndex = Number(helpIndex);
    }
  }

  toggleSideNav(): void {
    this.isOpenSideNav = !this.isOpenSideNav;
  }

  openSideNav(): void {
    if (!this.isOpenSideNav) {
      this.toggleSideNav();
    }
  }

  closeSideNav(): void {
    if (this.isOpenSideNav) {
      this.toggleSideNav();
    }
  }

  async returnHome(): Promise<void> {
    this.openSideNav();
    await this.router.navigate(['/dashboard']);
  }

  async openHelp(): Promise<void> {
    this.closeSideNav();
    await this.router.navigate([`/help`], {queryParams: {helpIndex: this.helpIndex}});
  }

  async onLogOutClicked(): Promise<void> {
    const result = await this.authService.logout();
    if (result.isSuccess) {
      await this.router.navigateByUrl('/auth/login');
    }
  }
}
