<div class="common-dialog">
  <h2 mat-dialog-title>大会結果</h2>

  <mat-dialog-content class="mat-typography">
    <mat-card appearance="outlined" *ngFor="let raceResult of raceResults" class="card-container">
      <div class="official-label-container">
        <span *ngIf="!raceResult.isManual" class="official-label">公式</span>
      </div>
      <mat-card-header>
        <mat-card-title>{{ raceResult.distanceName }}</mat-card-title>
        <mat-card-subtitle>{{ raceResult.distance / 1000 }}km</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="card-content">
        <ul>
          <li>記録: {{ getHMS(raceResult.time, true) }}</li>
          <li *ngFor="let rank of raceResult.raceResultRanks">{{ rank.categoryName }}: {{ getRankStr(rank) }}</li>
        </ul>

        <div
          *ngIf="!raceResult.isManual && raceResult.runnetCompetitionResult && raceResult.runnetCompetitionResult.raceResults.length >= 2">
          <h4>RUNNET生データ</h4>
          <mat-card appearance="outlined"
                    *ngFor="let runnetRaceResult of raceResult.runnetCompetitionResult.raceResults"
                    class="card-container">
            <mat-card-header>
              <mat-card-title>{{ runnetRaceResult.competitionItem }}</mat-card-title>
              <mat-card-subtitle>{{ runnetRaceResult.competitionDistance }}km</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="card-content">
              <ul>
                <li>ナンバー: {{ runnetRaceResult.competitionNumberCard }}</li>
                <li>ネット: {{ runnetRaceResult.competitionTimeNet }}</li>
                <li>グロス: {{ runnetRaceResult.competitionTimeGross }}</li>
                <li>総合順位: {{ runnetRaceResult.competitionSynthOrder }}</li>
                <li>順位: {{ runnetRaceResult.competitionItemOrder }}</li>
              </ul>
            </mat-card-content>
            <mat-card-actions *ngIf="!raceResult.isManual" align="end">
              <button mat-button (click)="editResult(raceResult.id, runnetRaceResult)">この記録に変更する</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-card-content>

    </mat-card>
  </mat-dialog-content>
</div>
