import {Component, OnInit} from '@angular/core';
import {TeamReport, ViolationReportStatus} from '../../../../core/model/resources/violation-report';
import {TeamApiService} from '../../../../core/services/team-api.service';

@Component({
  selector: 'app-team-report',
  templateUrl: './team-report.component.html',
  styleUrls: ['./team-report.component.scss']
})
export class TeamReportComponent implements OnInit {

  unansweredReports: TeamReport[] = [];
  approvedReports: TeamReport[] = [];
  ignoredReports: TeamReport[] = [];

  constructor(
    private teamApiService: TeamApiService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.refreshUnansweredReports();
  }

  async selectedTabChanged(index: number): Promise<void> {
    if (index === 1) {
      await this.refreshIgnoredReports();
    } else if (index === 2) {
      await this.refreshApprovedReports();
    }
  }

  async refreshUnansweredReports(): Promise<void> {
    this.unansweredReports = await this.teamApiService.getTeamReports(ViolationReportStatus.unanswered);
  }

  async refreshApprovedReports(): Promise<void> {
    this.approvedReports = await this.teamApiService.getTeamReports(ViolationReportStatus.approved);
  }

  async refreshIgnoredReports(): Promise<void> {
    this.ignoredReports = await this.teamApiService.getTeamReports(ViolationReportStatus.ignored);
  }

  async changedReport(changedReport: TeamReport): Promise<void> {
    this.unansweredReports = this.unansweredReports.filter(report => report.teamId !== changedReport.teamId);
  }

}
