<div class="container" fxLayout="column" fxLayoutGap="16px">
  <a class="back" (click)="back()">戻る</a>
  <h2>{{ getTitle() }}</h2>

  <div *ngIf="isPromote" class="header-action-container">
    <div class="search-container">
      <mat-form-field class="search-item">
        <mat-label>TeamIDで検索</mat-label>
        <input matInput type="number" [(ngModel)]="teamId" (change)="searchByTeamId()">
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="!isPromote || (isPromote && isSearchedTeam)">

    <div class="subcontainer">
      <h4 class="subtitle">基本情報</h4>
      <mat-form-field>
        <mat-label>契約者名</mat-label>
        <input matInput maxlength="254" [formControl]="paidAccountName">
        <mat-hint align="end">{{ paidAccountName.value?.length || 0 }}/254</mat-hint>
        <mat-error *ngIf="paidAccountName.hasError('required')">
          契約者名を入力してください。
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>契約者メールアドレス</mat-label>
        <input matInput type="email" maxlength="254"
               [formControl]="paidAccountEmail">
        <mat-hint align="end">{{ paidAccountEmail.value?.length || 0 }}/254</mat-hint>
        <mat-error *ngIf="paidAccountEmail.hasError('required')">
          契約者メールアドレスを入力してください。
        </mat-error>
        <mat-error *ngIf="paidAccountEmail.hasError('email')">
          契約者メールアドレスの形式が不正です。
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>チームタイプ</mat-label>
        <mat-select [(ngModel)]="teamType">
          <mat-option [value]="TeamType.official">{{ getTeamTypeName(TeamType.official) }}</mat-option>
          <mat-option [value]="TeamType.officialPrivate">{{ getTeamTypeName(TeamType.officialPrivate) }}</mat-option>
          <mat-option
            [value]="TeamType.officialRaceCommunity">{{ getTeamTypeName(TeamType.officialRaceCommunity) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="team-type-info">
        <p *ngIf="teamType === TeamType.official">参加方法は <span
          class="notice">{{ getTeamAclName(TeamAcl.everyone) }}</span> になります。</p>
        <p *ngIf="teamType === TeamType.officialPrivate">参加方法は <span
          class="notice">{{ getTeamAclName(TeamAcl.invitedOnly) }}</span> になります。</p>
        <p *ngIf="teamType === TeamType.officialRaceCommunity">参加方法は <span
          class="notice">{{ getTeamAclName(TeamAcl.everyone) }}</span> になります。</p>
      </div>

      <ng-container *ngIf="!isPromote">
        <mat-form-field>
          <mat-label>参加資格</mat-label>
          <mat-select [(ngModel)]="requiredServices" (ngModelChange)="changedRequiredServices()">
            <mat-option [value]="SelectableRequiredServices.Social">ソーシャルログイン</mat-option>
            <mat-option [value]="SelectableRequiredServices.RUNNET">RUNNET連携</mat-option>
            <mat-option [value]="SelectableRequiredServices.OneASICS">OneASICS連携</mat-option>
            <mat-option [value]="SelectableRequiredServices.SocialOrRUNNET">ソーシャルログイン or RUNNET連携
            </mat-option>
            <mat-option [value]="SelectableRequiredServices.SocialOrOneASICS">ソーシャルログイン or OneASICS連携
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <section>
        <label class="subheader">利用可能なチーム内イベント参加資格</label>
        <div *ngIf="additionalEvents" class="checkbox-container">
          <mat-checkbox *ngFor="let service of eventRequiredServices" [(ngModel)]="service.completed"
                        [disabled]="service.immutable">
            {{ service.name }}
          </mat-checkbox>
        </div>
      </section>

      <h4 class="subtitle">エントリー自動連携</h4>
      <pre *ngIf="teamType !== TeamType.officialRaceCommunity">
        [指定したランテス番号に属する全ての大会/指定した大会/指定したモシコムイベント]にエントリーしたTATTAユーザーについて、自動的にこのチームへ招待する機能です。
        過去にエントリーした分も遡及されます。
        設定直後に過去分の遡及が行われるため、ダブルチェックを行い、ミスの無いよう十分に注意してください。
      </pre>
      <pre *ngIf="teamType === TeamType.officialRaceCommunity">
        [指定したランテス番号に属する全ての大会/指定した大会/指定したモシコムイベント]にエントリーしたTATTAユーザーについて、自動的にこのチームへフォローさせる機能です。
        過去にエントリーした分も遡及されます。
        設定直後に過去分の遡及が行われるため、ダブルチェックを行い、ミスの無いよう十分に注意してください。
      </pre>
      <pre
        *ngIf="teamType === TeamType.officialRaceCommunity && requiredServices === SelectableRequiredServices.OneASICS">
        参加資格に[OneASICS連携]が設定されています。
        ここで連携対象となるユーザーはOneASICS連携済みでなくとも自動的にフォローされてしまうため、RUNNET/e-moshicom側でOneASICS連携エントリーを採用している大会のみ設定してください。
      </pre>
      <mat-form-field>
        <mat-label>ランテス番号で検索</mat-label>
        <input matInput type="search" maxlength="30" [(ngModel)]="queryRuntesNum"
               (change)="searchByRuntesNum()">
      </mat-form-field>

      <div *ngIf="runtesNums.length !== 0">
        <label class="subheader">ランテス番号</label>
        <ul>
          <li *ngFor="let runtesNum of runtesNums;" class="connected-nums">{{ runtesNum }}
            <button mat-button color="accent" (click)="removeRuntesNum(runtesNum)">×
            </button>
          </li>
        </ul>
      </div>

      <mat-form-field>
        <mat-label>RUNNET大会IDで検索</mat-label>
        <input matInput type="search" maxlength="10" [(ngModel)]="queryRunnetRaceId"
               (change)="searchByRunnetRaceId()">
      </mat-form-field>

      <div *ngIf="runnetRaceIds.length !== 0">
        <label class="subheader">RUNNET大会ID</label>
        <ul>
          <li *ngFor="let runnetRaceId of runnetRaceIds;" class="connected-nums">{{ runnetRaceId }}
            <button mat-button color="accent" (click)="removeRunnetRaceId(runnetRaceId)">×
            </button>
          </li>
        </ul>
      </div>

      <mat-form-field>
        <mat-label>e-moshicom イベントIDで検索</mat-label>
        <input matInput type="search" maxlength="30"
               [(ngModel)]="queryMoshicomId"
               (change)="searchByMoshicomId()">
      </mat-form-field>

      <div *ngIf="moshicomIds.length !== 0">
        <label class="subheader">e-moshicom イベントID</label>
        <ul>
          <li *ngFor="let moshicomId of moshicomIds;" class="connected-nums">{{ moshicomId }}
            <button mat-button color="accent" (click)="removeMoshicomId(moshicomId)">×
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="subcontainer">
      <h4 class="subtitle">ライセンス</h4>
      <mat-form-field>
        <mat-label>定員数</mat-label>
        <input matInput type="number" min="100" max="999999" [formControl]="capacity">
        <mat-error *ngIf="capacity.hasError('required')">
          定員数を入力してください。
        </mat-error>
        <mat-error *ngIf="capacity.hasError('min')">
          100以上の数値を入れてください。
        </mat-error>
        <mat-error *ngIf="capacity.hasError('max')">
          999999以下の数値を入れてください。
        </mat-error>
      </mat-form-field>

      <section>
        <label class="subheader">利用可能なチーム内イベント種別</label>
        <div *ngIf="additionalEvents" class="checkbox-container">
          <mat-checkbox *ngFor="let additionalEvent of additionalEvents" [(ngModel)]="additionalEvent.completed"
                        [disabled]="additionalEvent.immutable">
            {{ additionalEvent.name }}
          </mat-checkbox>
        </div>
      </section>

      <mat-form-field appearance="fill" class="contract-term">
        <mat-label>契約期間</mat-label>
        <mat-date-range-input [formGroup]="contractRange" [rangePicker]="contractRangePicker">
          <input matStartDate formControlName="startedAt" placeholder="Start date">
          <input matEndDate formControlName="expiredAt" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="contractRangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #contractRangePicker></mat-date-range-picker>
        <mat-error *ngIf="contractRange.controls.startedAt.hasError('matStartDateInvalid')">Invalid start date
        </mat-error>
        <mat-error *ngIf="contractRange.controls.expiredAt.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>契約金額（税別）</mat-label>
        <input matInput type="number" min="0" max="999999999" [formControl]="price">
        <mat-error *ngIf="price.hasError('required')">
          契約金額を入力してください。
        </mat-error>
        <mat-error *ngIf="price.hasError('min')">
          100以上の数値を入れてください。
        </mat-error>
        <mat-error *ngIf="price.hasError('max')">
          999999999以下の数値を入れてください。
        </mat-error>
      </mat-form-field>
    </div>

    <div class="subcontainer">
      <h4 class="subtitle">チーム情報</h4>

      <div class="image-container" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start">
        <label>アイコン画像 (512KBまで)</label>
        <img class="icon-preview" [src]="iconUrl">
        <input #iconFileInput hidden="true" type="file" (change)="onIconChanged()"
               onclick="this.value=null"
               accept=".png,.jpg"/>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start">
          <button mat-flat-button color="primary" [disabled]="isPromote" (click)="iconFileInput.click()">選択
          </button>
          <button mat-flat-button color="warn" [disabled]="isPromote" (click)="onDeleteIconClicked()">削除
          </button>
        </div>

        <label>背景画像 (3MBまで)</label>
        <img class="image-preview" [src]="imageUrl">
        <input #imageFileInput hidden="true" type="file" (change)="onImageChanged()"
               onclick="this.value=null"
               accept=".png,.jpg"/>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start">
          <button mat-flat-button color="primary" [disabled]="isPromote" (click)="imageFileInput.click()">選択
          </button>
          <button mat-flat-button color="warn" [disabled]="isPromote" (click)="onDeleteImageClicked()">削除
          </button>
        </div>
      </div>

      <mat-form-field>
        <mat-label>チーム名</mat-label>
        <input matInput maxlength="30" [formControl]="name" [readonly]="isPromote">
        <mat-hint align="end">{{ name.value?.length || 0 }}/30</mat-hint>
        <mat-error *ngIf="name.hasError('required')">
          チーム名を入力してください。
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>詳細</mat-label>
        <textarea matInput maxlength="1000" [formControl]="description" rows="5"
                  [readonly]="isPromote"></textarea>
        <mat-hint align="end">{{ description.value?.length || 0 }}/1000</mat-hint>
        <mat-error *ngIf="description.hasError('required')">
          詳細を入力してください。
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>主な活動エリア（例：関東近郊）</mat-label>
        <input matInput maxlength="30" [formControl]="area"
               [readonly]="isPromote">
        <mat-hint align="end">{{ area.value?.length || 0 }}/30</mat-hint>
      </mat-form-field>

      <section>
        <label id="activity-type-label">アクティビティ種別</label>
        <mat-radio-group aria-labelledby="activity-type-label" class="radio-group" [(ngModel)]="activityType"
                         [disabled]="isPromote">
          <mat-radio-button class="radio-button" [value]="ActivityType.RUNNING">RUNNING</mat-radio-button>
          <mat-radio-button class="radio-button" [value]="ActivityType.TRAIL_RUNNING">TRAIL_RUNNING</mat-radio-button>
          <mat-radio-button class="radio-button" [value]="ActivityType.CYCLING">CYCLING</mat-radio-button>
          <mat-radio-button class="radio-button" [value]="ActivityType.WALKING">WALKING</mat-radio-button>
          <mat-radio-button class="radio-button" [value]="ActivityType.HIKING">HIKING</mat-radio-button>
        </mat-radio-group>
      </section>

      <section>
        <label id="category-label">チーム種別</label>
        <mat-radio-group aria-labelledby="category-label" class="radio-group" [(ngModel)]="category"
                         [disabled]="isPromote">
          <mat-radio-button class="radio-button"
                            [value]="TeamCategory.circle">{{ getTeamCategoryName(TeamCategory.circle) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamCategory.student">{{ getTeamCategoryName(TeamCategory.student) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamCategory.service">{{ getTeamCategoryName(TeamCategory.service) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamCategory.company">{{ getTeamCategoryName(TeamCategory.company) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamCategory.association">{{ getTeamCategoryName(TeamCategory.association) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamCategory.municipality">{{ getTeamCategoryName(TeamCategory.municipality) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamCategory.other">{{ getTeamCategoryName(TeamCategory.other) }}
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <section>
        <label id="acl-post-feed-label">掲示板への話題の投稿</label>
        <mat-radio-group aria-labelledby="acl-post-feed-label" class="radio-group" [(ngModel)]="aclPostFeed"
                         [disabled]="isPromote">
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.member">{{ getTeamPostAclName(TeamPostAcl.member) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.adminOnly">{{ getTeamPostAclName(TeamPostAcl.adminOnly) }}
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <section>
        <label id="acl-create-intra-event-label">チーム内イベント作成の権限</label>
        <mat-radio-group aria-labelledby="acl-create-intra-event-label" class="radio-group"
                         [(ngModel)]="aclCreateIntraEvent" [disabled]="isPromote">
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.member">{{ getTeamPostAclName(TeamPostAcl.member) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.adminOnly">{{ getTeamPostAclName(TeamPostAcl.adminOnly) }}
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <section>
        <label id="acl-post-team-course-label">ホームコースの登録</label>
        <mat-radio-group aria-labelledby="acl-post-team-course-label" class="radio-group"
                         [(ngModel)]="aclPostTeamCourse"
                         [disabled]="isPromote">
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.member">{{ getTeamPostAclName(TeamPostAcl.member) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.adminOnly">{{ getTeamPostAclName(TeamPostAcl.adminOnly) }}
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <!-- TODO 活動実績機能追加後にコメントアウト
      <section>
        <label id="acl-post-team-history-label">活動実績への投稿権限</label>
        <mat-radio-group aria-labelledby="acl-post-team-history-label" class="radio-group"
                         [(ngModel)]="aclPostTeamHistory" [disabled]="isPromote">
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.member">{{getTeamPostAclName(TeamPostAcl.member)}}</mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.adminOnly">{{getTeamPostAclName(TeamPostAcl.adminOnly)}}</mat-radio-button>
        </mat-radio-group>
      </section>
      -->

      <section>
        <label id="acl-invite-member-label">チームへの招待</label>
        <mat-radio-group aria-labelledby="acl-invite-member-label" class="radio-group" [(ngModel)]="aclInviteMember"
                         [disabled]="isPromote">
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.member">{{ getTeamPostAclName(TeamPostAcl.member) }}
          </mat-radio-button>
          <mat-radio-button class="radio-button"
                            [value]="TeamPostAcl.adminOnly">{{ getTeamPostAclName(TeamPostAcl.adminOnly) }}
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <mat-form-field>
        <mat-label>Twitter（例: &#64;RUNNET.JP）</mat-label>
        <input matInput maxlength="16" [formControl]="twitterLink"
               [readonly]="isPromote">
        <mat-hint align="end">{{ twitterLink.value?.length || 0 }}/16</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Instagram（例: &#64;runnetchannel）</mat-label>
        <input matInput maxlength="30"
               [formControl]="instagramLink" [readonly]="isPromote">
        <mat-hint align="end">{{ instagramLink.value?.length || 0 }}/30</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>FacebookページのURL（例: https://www.facebook.com/RUNNET.JP）</mat-label>
        <input matInput maxlength="2048"
               [formControl]="facebookLink" [readonly]="isPromote">
        <mat-hint align="end">{{ facebookLink.value?.length || 0 }}/2048</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>YouTube（例: https://www.youtube.com/&#64;runnetchannel2927）</mat-label>
        <input matInput maxlength="2048"
               [formControl]="youtubeLink" [readonly]="isPromote">
        <mat-hint align="end">{{ youtubeLink.value?.length || 0 }}/2048</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>ホームページのURL（例: https://runnet.jp）</mat-label>
        <input matInput maxlength="2048"
               [formControl]="webLink" [readonly]="isPromote">
        <mat-hint align="end">{{ webLink.value?.length || 0 }}/2048</mat-hint>
      </mat-form-field>
    </div>

    <div class="form-action-container">
      <button mat-raised-button color="primary" *ngIf="!isPromote" (click)="create()">登録する</button>
      <button mat-raised-button color="primary" *ngIf="isPromote" (click)="promote()">昇格する
      </button>
    </div>

  </ng-container>
</div>

