import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RunnersApiService} from '../../../../core/services/runners-api.service';
import {ActivityApiService} from '../../../../core/services/activity-api.service';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {
  Activity,
  CheatType,
  getActivitySourceName,
  getActivityTypeName,
  getCheatTypeName
} from '../../../../core/model/resources/activity';
import {MatSort} from '@angular/material/sort';
import {MessageDialogComponent} from '../../../partials/message-dialog/message-dialog.component';
import {UtilService} from '../../../../core/services/util.service';
import {ActivityDetailDialogComponent} from './activity-detail-dialog/activity-detail-dialog.component';
import {AuthService} from '../../../../core/services/auth.service';
import moment from 'moment';
import {Moment} from 'moment';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  @Input() userId?: number;
  @Input() dataSource?: MatTableDataSource<Activity>;
  UtilService = UtilService;
  getCheatTypeName = getCheatTypeName;
  getActivityTypeName = getActivityTypeName;
  getActivitySourceName = getActivitySourceName;
  beginningAtRange = new FormGroup<{
    from: FormControl<Moment | null>,
    to: FormControl<Moment | null>
  }>({
    from: new FormControl<Moment | null>(null),
    to: new FormControl<Moment | null>(null)
  });
  activityDisplayedColumns = ['activity_type', 'time', 'distance', 'pace', 'climb_up', 'climb_down', 'activity_source', 'registered_manual',
    'beginning_at', 'end_of_at', 'created_at', 'updated_at', 'deleted_at', 'is_cheat', 'cheat_type', 'operation'];
  @ViewChild(MatSort, {static: false}) sort: MatSort | null = null;

  constructor(
    private runnersApiService: RunnersApiService,
    private activityApiService: ActivityApiService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (this.userId === undefined) {
      throw new Error('userId is required');
    }
    if (this.dataSource === undefined) {
      throw new Error('dataSource is required');
    }

    const now = new Date();
    const oneDayAgo = new Date(now.getTime() - 1000 * 60 * 60 * 24);
    this.beginningAtRange.controls.from.setValue(moment(oneDayAgo));
    this.beginningAtRange.controls.to.setValue(moment(now));
  }

  async searchActivity(): Promise<void> {
    let from;
    let to;

    if (!this.beginningAtRange.controls.from.value ||
      this.beginningAtRange.controls.from.hasError('matStartDateInvalid')) {
      return;
    }
    if (!this.beginningAtRange.controls.to.value ||
      this.beginningAtRange.controls.to.hasError('matEndDateInvalid')) {
      return;
    }
    from = this.beginningAtRange.value.from!.unix() * 1000;
    to = this.beginningAtRange.value.to!.unix() * 1000 + 1000 * 60 * 60 * 24 - 1;
    if (to - from > 1000 * 60 * 60 * 24 * 31) {
      MessageDialogComponent.createErrorDialog(this.dialog, '31日より長い期間を指定することはできません。');
      return;
    }

    const res = await this.activityApiService.getActivities({user_id: this.userId!, from, to});
    if (res.success) {
      this.dataSource!.data = res.data;
    } else {
      this.dataSource!.data = [];
    }
    this.dataSource!.sort = this.sort;
    this.dataSource!.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'activity_type':
          return item.activity_type;
        case 'time':
          return item.time;
        case 'distance':
          return item.distance;
        case 'pace':
          return item.pace;
        case 'climb_up':
          return item.climb_up;
        case 'climb_down':
          return item.climb_down;
        case 'activity_source':
          return item.activity_source;
        case 'registered_manual':
          return item.registered_manual;
        case 'beginning_at':
          return item.beginning_at;
        case 'end_of_at':
          return item.end_of_at;
        case 'created_at':
          return item.created_at;
        case 'updated_at':
          return item.updated_at;
        case 'deleted_at':
          return item.deleted_at;
        case 'is_cheat':
          return item.is_cheater;
        case 'cheat_type':
          return item.cheat_type;
      }
      return (item as any)[property];
    };
  }

  getTotalNetTime(): string {
    const hms = UtilService.getHMS(this.dataSource?.data.map(data => data.time).reduce((prev, curr) => prev + curr, 0));
    return hms !== '0' ? hms : '0:00:00';
  }

  getTotalDistance(): string {
    const total = this.dataSource?.data.map(data => data.distance).reduce((prev, curr) => prev + curr, 0);
    return `${UtilService.round(total || 0, 2)} km`;
  }

  getTotalClimbUp(): string {
    const total = this.dataSource?.data.map(data => data.climb_up).reduce((prev, curr) => prev + curr, 0);
    return `${total || 0} m`;
  }

  getTotalClimbDown(): string {
    const total = this.dataSource?.data.map(data => data.climb_down).reduce((prev, curr) => prev + curr, 0);
    return `${total || 0} m`;
  }


  async showDetailDialog(activity: Activity): Promise<void> {
    ActivityDetailDialogComponent.open(this.dialog, activity);
  }

  async showCheatSettingDialog(activity: Activity): Promise<void> {
    if (!this.authService.getUserAuth().isAllowManageRunner()) {
      MessageDialogComponent.createErrorDialog(this.dialog, '権限がありません。');
      return;
    }

    if (!activity.is_cheater) {
      const isOk = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'このアクティビティをランキングから除外します。\n' +
        '既に集計が完了している場合は、必要に応じて即時集計を実行してください。\n' +
        'よろしいですか？');
      if (!isOk) {
        return;
      }
      const res = await this.runnersApiService.patchActivity(activity.id, true);
      if (res.success) {
        activity.is_cheater = true;
        activity.cheat_type = CheatType.JUDGED_BY_ADMIN;
      }
    } else {
      const isOk = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, 'チーター設定を解除します。\n' +
        '既に集計が完了している場合は、必要に応じて即時集計を実行してください。\n' +
        'よろしいですか？');
      if (!isOk) {
        return;
      }
      const res = await this.runnersApiService.patchActivity(activity.id, false);
      if (res.success) {
        activity.is_cheater = false;
        activity.cheat_type = CheatType.JUDGED_BY_ADMIN;
      }
    }
  }
}
