import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-help-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {

  @Input() id?: string;
  @Input() title?: string;
  @Input() isSub?: boolean;

  constructor() {
  }

}
