import {Component, ElementRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

interface Link {
  id: string;
  type: string;
  active: boolean;
  name: string;
  top: number;
}


@Component({
  selector: 'app-toc',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss']
})
export class TocComponent implements OnInit, OnDestroy {

  @Input() title?: string;
  @Input() links: Link[] = [];
  @Input() container?: string;
  @Input() headerSelectors?: string;
  @Input() helpIndex?: number;

  rootUrl = this.router.url.split('#')[0];
  private scrollContainer: any;
  private destroyed = new Subject();
  private urlFragment = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.router.events.pipe(takeUntil(this.destroyed)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const rootUrl = router.url.split('#')[0];
        if (rootUrl !== this.rootUrl) {
          this.links = this.createLinks();
          this.rootUrl = rootUrl;
        }
      }
    });

    this.route.fragment.pipe(takeUntil(this.destroyed)).subscribe(fragment => {
      this.urlFragment = fragment ?? '';

      const target = document.getElementById(this.urlFragment);
      if (target) {
        target.scrollIntoView();
      }
    });
  }

  ngOnInit(): void {
    Promise.resolve().then(() => {
      this.scrollContainer = this.container ?
        this.document.querySelectorAll(this.container)[0] : window;

      if (this.scrollContainer) {
        fromEvent(this.scrollContainer, 'scroll').pipe(
          takeUntil(this.destroyed),
          debounceTime(10))
          .subscribe(() => this.onScroll());
      }
      this.updateScrollPosition();
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
  }

  updateScrollPosition(): void {
    this.links = this.createLinks();
    const target = document.getElementById(this.urlFragment);
    if (target) {
      target.scrollIntoView();
    }
  }

  private getScrollOffset(): number | void {
    const {top} = this.element.nativeElement.getBoundingClientRect();
    if (typeof this.scrollContainer.scrollTop !== 'undefined') {
      return this.scrollContainer.scrollTop + top;
    } else if (typeof this.scrollContainer.scrollY !== 'undefined') {
      return this.scrollContainer.scrollY + top;
    }
  }

  private createLinks(): Link[] {
    const links: Array<Link> = [];
    const headers =
      Array.from(this.document.querySelectorAll(this.headerSelectors ?? '')) as HTMLElement[];
    for (const header of headers) {
      // innerHTMLでは正常に取得できないケースがあるためtitle属性を利用する
      const name = header.title.trim().replace(/^link/, '');
      const {top} = header.getBoundingClientRect();
      links.push({
        name,
        type: header.tagName.toLowerCase(),
        top,
        id: header.id,
        active: false
      });
    }
    return links;
  }

  private onScroll(): void {
    for (let i = 0; i < this.links.length; i++) {
      this.links[i].active = this.isLinkActive(this.links[i], this.links[i + 1]);
    }
  }

  private isLinkActive(currentLink: any, nextLink: any): boolean {
    const scrollOffset = this.getScrollOffset();
    return scrollOffset >= currentLink.top && !(nextLink && nextLink.top < scrollOffset);
  }

  navigate(fragment: string): void {
    this.router.navigate([], {
      fragment,
      queryParams: {
        helpIndex: this.helpIndex
      }
    });
  }
}
