import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FeedItemReport,
  ViolationReportType,
  getViolationReportTypeName,
  TeamAdminUserId
} from '../../../../../core/model/resources/violation-report';
import {FeedApiService} from '../../../../../core/services/feed-api.service';
import {MatDialog} from '@angular/material/dialog';
import {FeedReportDialogComponent} from './feed-report-dialog/feed-report-dialog.component';

@Component({
  selector: 'app-feed-report-table',
  templateUrl: './feed-report-table.component.html',
  styleUrls: ['./feed-report-table.component.scss']
})
export class FeedReportTableComponent implements OnInit {

  displayedColumns = ['teamId', 'reporter', 'reportType', 'reason', 'createdAt'];
  TeamAdminUserId = TeamAdminUserId;

  @Input() reports?: FeedItemReport[];
  @Output() changedReport = new EventEmitter<FeedItemReport>();

  constructor(
    private feedApiService: FeedApiService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (this.reports === undefined) {
      throw new Error('reports is required');
    }
  }

  async showDialog(report: FeedItemReport): Promise<void> {
    const status = await FeedReportDialogComponent.createDialog(this.dialog, report);
    if (!status) {
      return;
    }
    await this.feedApiService.patchFeedReport(report.uuid, status);
    this.changedReport.emit(report);
  }

  getViolationReportTypeName(type: ViolationReportType): string {
    return getViolationReportTypeName(type);
  }
}
