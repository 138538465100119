import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  getTeamAclName,
  getTeamCategoryName,
  getTeamPostAclName,
  getTeamStatusName,
  getTeamTypeName,
  OfficialTeam,
  TeamType,
} from '../../../../../core/model/resources/team';
import {environment} from '../../../../../../environments/environment';
import {ContextService} from '../../../../../core/services/context.service';
import {Router} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SearchType, TeamApiService} from '../../../../../core/services/team-api.service';
import {getActivityTypeName} from '../../../../../core/model/resources/activity';
import {RaceRankingMethod} from '../../../../../core/model/resources/race';
import {MessageDialogComponent} from '../../../../partials/message-dialog/message-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import iconv from 'iconv-lite';

export const OFFICIAL_TEAM_LOAD_LIMIT = 10;

@Component({
  selector: 'app-team-table',
  templateUrl: './team-table.component.html',
  styleUrls: ['./team-table.component.scss']
})
export class TeamTableComponent implements OnInit {

  OFFICIAL_TEAM_LOAD_LIMIT = OFFICIAL_TEAM_LOAD_LIMIT;

  @Input() teamType?: TeamType.official | TeamType.officialPrivate | TeamType.officialRaceCommunity;
  getTeamStatusName = getTeamStatusName;

  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  displayedColumns = ['teamId', 'name', 'accountName', 'accountEmail', 'startedAt', 'expiredAt', 'membership',
    'capacity', 'status', 'edit', 'license'];

  dataSource: MatTableDataSource<OfficialTeam> = new MatTableDataSource();
  isLoadAll = false;

  searchTeamId?: number;
  searchName?: string;
  searchAccount?: string;
  searchEmail?: string;
  searchMode = false;

  constructor(
    private teamApiService: TeamApiService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    if (this.teamType === undefined) {
      throw new Error('teamType is required');
    }

    if (this.sort === undefined) {
      throw new Error('sort is not set');
    }

    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'teamId':
          return item.id;
        case 'name':
          return item.name;
        case 'accountName':
          return item.paidAccount.name;
        case 'accountEmail':
          return item.paidAccount.email;
        case 'startedAt':
          return item.paidPlan.startedAt;
        case 'expiredAt':
          return item.paidPlan.expiredAt;
        case 'status':
          return item.status;
        case 'membership':
          return item.membership;
        case 'capacity':
          return item.capacity;
      }
      return (item as any)[property];
    };
  }

  loginToTeamAdmin(team: OfficialTeam): void {
    window.open(`${environment.teamAdminUrl}teams/${team.id}`, '_blank');
  }

  async manageLicense(team: OfficialTeam): Promise<void> {
    this.contextService.selectedTeamForLicenceManagement = team;
    await this.router.navigate(['team', team.id, 'license']);
  }

  getRowClassName(team: OfficialTeam): string {
    if (team.paidPlan.expiredAt < new Date().getTime()) {
      return 'expired';
    }
    return 'valid';
  }

  async searchById(): Promise<void> {
    if (this.searchTeamId) {
      // searchOfficialTeamsByIdでエラーが発生した場合は関数内部でエラーダイアログ表示を行うので、
      // その後コンポーネント側としては何も取得できなかった状態の表示を行う
      this.dataSource.data = (await this.teamApiService.searchOfficialTeamsById(this.teamType!, this.searchTeamId)) ?? [];
      this.searchMode = true;
      this.searchName = undefined;
      this.searchAccount = undefined;
      this.searchEmail = undefined;
    } else {
      this.dataSource.data = [];
      this.searchMode = false;
    }
  }

  async searchByName(): Promise<void> {
    if (this.searchName) {
      // searchOfficialTeamsByWordでエラーが発生した場合は関数内部でエラーダイアログ表示を行うので、
      // その後コンポーネント側としては何も取得できなかった状態の表示を行う
      this.dataSource.data = (await this.teamApiService.searchOfficialTeamsByWord(this.teamType!, SearchType.searchByName, this.searchName)) ?? [];
      this.searchMode = true;
      this.searchTeamId = undefined;
      this.searchAccount = undefined;
      this.searchEmail = undefined;
    } else {
      this.dataSource.data = [];
      this.searchMode = false;
    }
  }

  async searchByAccount(): Promise<void> {
    if (this.searchAccount) {
      // searchOfficialTeamsByWordでエラーが発生した場合は関数内部でエラーダイアログ表示を行うので、
      // その後コンポーネント側としては何も取得できなかった状態の表示を行う
      this.dataSource.data = (await this.teamApiService.searchOfficialTeamsByWord(this.teamType!, SearchType.searchByAccount, this.searchAccount)) ?? [];
      this.searchMode = true;
      this.searchTeamId = undefined;
      this.searchName = undefined;
      this.searchEmail = undefined;
    } else {
      this.dataSource.data = [];
      this.searchMode = false;
    }
  }

  async searchByEmail(): Promise<void> {
    if (this.searchEmail) {
      // searchOfficialTeamsByWordでエラーが発生した場合は関数内部でエラーダイアログ表示を行うので、
      // その後コンポーネント側としては何も取得できなかった状態の表示を行う
      this.dataSource.data = (await this.teamApiService.searchOfficialTeamsByWord(this.teamType!, SearchType.searchByEmail, this.searchEmail)) ?? [];
      this.searchMode = true;
      this.searchTeamId = undefined;
      this.searchName = undefined;
      this.searchAccount = undefined;
    } else {
      this.dataSource.data = [];
      this.searchMode = false;
    }
  }

  async fetchNext(): Promise<boolean> {
    if (this.isLoadAll) {
      return false;
    }
    const last = this.dataSource.data.length === 0 ? undefined : this.dataSource.data[this.dataSource.data.length - 1];
    const next: OfficialTeam[] | null = await this.teamApiService.getOfficialTeams(
      this.teamType!, OFFICIAL_TEAM_LOAD_LIMIT, last?.id, last?.name
    );

    if (next === null) {
      return false;
    }

    this.dataSource.data = this.dataSource.data.concat(next);
    const hasNext = next.length === OFFICIAL_TEAM_LOAD_LIMIT;
    if (!hasNext) {
      this.isLoadAll = true;
    }
    return hasNext;
  }

  async fetchAll(): Promise<void> {
    while (await this.fetchNext()) {
    }
    this.isLoadAll = true;
  }

  async exportToCSV(): Promise<void> {
    const isOK = await MessageDialogComponent.createPositiveConfirmDialog(this.dialog, '現在表示中のデータを出力します（セキュアな情報は含まれません）。\n\nよろしいですか？');
    if (!isOK) {
      return;
    }
    // 契約者メアド、契約金額は出力しない
    const header = '"チームID","チーム名","アクティビティ種別","チームタイプ","チーム種別","ステータス","参加方法",' +
      '"掲示板への話題の投稿","チーム内イベント作成の権限","ホームコースの登録","チームへの招待","主な活動エリア","更新日時","詳細","ホームページのURL",' +
      '"Twitter","FacebookページのURL","Instagram","YouTube","メンバー数","定員数","全イベント数","月間走行距離","契約者名","契約日時(from)",' +
      '"契約日時(to)","距離ランキング（団体戦）","距離達成チャレンジ","カロリー消費チャレンジ","シリーズイベント"\n';
    const body = this.dataSource.data.map((item: OfficialTeam) => {
      return `"${item.id}","${item.name}","${getActivityTypeName(item.activityType)}","${getTeamTypeName(item.type)}",` +
        `"${getTeamCategoryName(item.category)}","${getTeamStatusName(item.status)}","${getTeamAclName(item.aclTeam)}",` +
        `"${getTeamPostAclName(item.aclPostFeed)}","${getTeamPostAclName(item.aclCreateIntraEvent)}","${getTeamPostAclName(item.aclPostTeamCourse)}",` +
        `"${getTeamPostAclName(item.aclInviteMember)}","${item.area || ''}",` +
        `"${new Date(item.updatedAt * 1000).toLocaleString()}","${item.description}",` +
        `"${item.webLink || ''}","${item.twitterLink || ''}","${item.facebookLink || ''}","${item.instagramLink || ''}","${item.youtubeLink || ''}",` +
        `"${item.membership}","${item.capacity}","${item.eventsTotal}","${item.monthlyTotalDistance}","${item.paidAccount.name}",` +
        `"${new Date(item.paidPlan.startedAt).toLocaleString()}","${new Date(item.paidPlan.expiredAt).toLocaleString()}",` +
        `"${item.paidPlan.additionalEvents.includes(RaceRankingMethod.RankingByGroupDistance)}",` +
        `"${item.paidPlan.additionalEvents.includes(RaceRankingMethod.RankingByDistanceChallenge)}",` +
        `"${item.paidPlan.additionalEvents.includes(RaceRankingMethod.RankingByCalorieChallenge)}",` +
        `"${item.paidPlan.additionalEvents.includes(RaceRankingMethod.RankingBySeriesEvent)}"`;
    }).join('\n');
    const csv = iconv.encode(header + body, 'windows-31j');

    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(new Blob([csv], {type: 'text/csv'}));
    a.href = url;
    a.download = 'official_team_export.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.parentNode!.removeChild(a);
  }
}
